/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./MiniApprovalRoutingTemplate.vue?vue&type=template&id=c0e6b4f2&scoped=true"
import script from "./MiniApprovalRoutingTemplate.ts?vue&type=script&lang=ts&external"
export * from "./MiniApprovalRoutingTemplate.ts?vue&type=script&lang=ts&external"
import style0 from "./MiniApprovalRoutingTemplate.vue?vue&type=style&index=0&id=c0e6b4f2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c0e6b4f2",
  null
  
)

export default component.exports
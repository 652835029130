import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop, Vue, Watch } from 'vue-property-decorator';
import ReadOnlyMixin from '~/nasa_ui/mixins/ReadOnlyMixin';
import { EntityType } from '~/nasa_ui/types';
import { getContract } from '~/nasa_ui/utils';
import { transformContractResponses } from '~/nasa_ui/utils/helpers/transforms/contracts';
let MiniContract = class MiniContract extends Mixins(Vue, ReadOnlyMixin) {
    selectedEntity = null;
    isFetching = false;
    disabled;
    entity;
    inlineClear;
    number;
    title;
    get manageLink() {
        return this.selectedEntity
            ? `/${EntityType.CONTRACT}/manage/${encodeURIComponent(this.selectedEntity.number)}`
            : '';
    }
    get transformedSelectedEntity() {
        if (!this.selectedEntity) {
            return null;
        }
        return transformContractResponses([this.selectedEntity])[0];
    }
    onClickOfInlineClear() {
        return this.number;
    }
    async onNumberChange() {
        try {
            const resp = await getContract(this.number);
            this.selectedEntity = resp.data;
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    onEntityChange() {
        if (!this.entity) {
            return;
        }
        this.number = this.entity.number;
    }
};
__decorate([
    Prop({ default: false, type: Boolean }),
    __metadata("design:type", Boolean)
], MiniContract.prototype, "disabled", void 0);
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", Object)
], MiniContract.prototype, "entity", void 0);
__decorate([
    Prop({ default: false, type: Boolean }),
    __metadata("design:type", Boolean)
], MiniContract.prototype, "inlineClear", void 0);
__decorate([
    Prop({
        type: String,
        required: true
    }),
    __metadata("design:type", String)
], MiniContract.prototype, "number", void 0);
__decorate([
    Prop({ type: String }),
    __metadata("design:type", String)
], MiniContract.prototype, "title", void 0);
__decorate([
    Emit('inlineClear'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], MiniContract.prototype, "onClickOfInlineClear", null);
__decorate([
    Watch('number', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], MiniContract.prototype, "onNumberChange", null);
__decorate([
    Watch('entity', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], MiniContract.prototype, "onEntityChange", null);
MiniContract = __decorate([
    Component
], MiniContract);
export default MiniContract;

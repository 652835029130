var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": _vm.computedColor,
      "hide-title-section": "",
      "modal-width": "95vw"
    },
    on: {
      "modal-close": _vm.emitModalClose
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_vm.selectedEntity ? _c('div', [_vm.isRoot ? _c('Alert', {
          scopedSlots: _vm._u([{
            key: "icon",
            fn: function fn() {
              return [_c('c-icon-fa', {
                attrs: {
                  "icon": _vm.$icons.root
                }
              })];
            },
            proxy: true
          }], null, false, 1866325887)
        }, [_c('span', [_vm._v(" This is known as a "), _c('u', [_vm._v("\"Root node\"")]), _vm._v(". ")]), _c('ul', [_c('li', [_vm._v("Root nodes do not have a parent node.")]), _c('li', [_vm._v("Root nodes have a sequence of "), _c('span', {
          staticClass: "monospace_font"
        }, [_vm._v("0")]), _vm._v(".")]), _vm.isGenericAssemblyTemplate ? _c('li', [_vm._v(" Assign Hardware at this level to create “AsDesigned” template linkage. ")]) : _vm._e()])]) : _vm._e(), _c('v-fade-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [_vm.parentAssemblyTemplateId ? _c('div', {
          staticClass: "mb-4"
        }, [_c('MiniAssemblyTemplate', {
          staticClass: "parent_mini",
          attrs: {
            "id": _vm.parentAssemblyTemplateId,
            "title": "Attached to (parent)",
            "show-children-initially": false
          }
        }), _c('v-divider', {
          staticClass: "my-2"
        })], 1) : _vm._e()]), _c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs6": "",
            "pr-3": ""
          }
        }, [_c('AppText', {
          attrs: {
            "label": "Name",
            "clearable": "",
            "tooltip": "This will be the display of the node and serve as it's alias",
            "tooltip-position": "top"
          },
          model: {
            value: _vm.formData.name,
            callback: function callback($$v) {
              _vm.$set(_vm.formData, "name", $$v);
            },
            expression: "formData.name"
          }
        })], 1), !_vm.isPbsAssemblyTemplate ? _c('v-flex', {
          attrs: {
            "xs2": "",
            "pr-3": ""
          }
        }, [_c('AppSelectEnum', {
          attrs: {
            "enum": _vm.Side,
            "display": _vm.SideDisplay,
            "sort": false,
            "chips": false,
            "messages": _vm.installedOnMessage,
            "label": "Installed on",
            "required": ""
          },
          model: {
            value: _vm.formData.installedOn,
            callback: function callback($$v) {
              _vm.$set(_vm.formData, "installedOn", $$v);
            },
            expression: "formData.installedOn"
          }
        })], 1) : _vm._e(), _c('v-flex', {
          attrs: {
            "xs3": ""
          }
        }, [_c('AppText', {
          attrs: {
            "label": "PBS ID",
            "clearable": "",
            "messages": "Unique identifier"
          },
          model: {
            value: _vm.formData.pbsItemId,
            callback: function callback($$v) {
              _vm.$set(_vm.formData, "pbsItemId", $$v);
            },
            expression: "formData.pbsItemId"
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs2": "",
            "pr-3": ""
          }
        }, [_c('AppNumber', {
          attrs: {
            "error-messages": _vm.sequenceNumberErrorMessage,
            "messages": _vm.sequenceNumberMessage,
            "min": 0,
            "required": !_vm.isSequenceValid,
            "rules": [_vm.isSequenceZeroOrMore],
            "label": "Sequence",
            "tooltip-position": "right",
            "tooltip": "This is how you order this node with respect to it's siblings"
          },
          model: {
            value: _vm.formData.sequence,
            callback: function callback($$v) {
              _vm.$set(_vm.formData, "sequence", $$v);
            },
            expression: "formData.sequence"
          }
        })], 1), !_vm.isPbsAssemblyTemplate ? _c('v-flex', {
          attrs: {
            "xs2": "",
            "pr-1": ""
          }
        }, [_c('AppNumber', {
          attrs: {
            "label": "Min qty",
            "max": _vm.formData.maxQuantity,
            "pre-computed-rules": [_vm.minIsLessThanMax]
          },
          model: {
            value: _vm.formData.minQuantity,
            callback: function callback($$v) {
              _vm.$set(_vm.formData, "minQuantity", $$v);
            },
            expression: "formData.minQuantity"
          }
        })], 1) : _vm._e(), !_vm.isPbsAssemblyTemplate ? _c('v-flex', {
          attrs: {
            "xs2": "",
            "pr-3": ""
          }
        }, [_c('AppNumber', {
          attrs: {
            "label": "Max qty",
            "pre-computed-rules": [_vm.minIsLessThanMax]
          },
          model: {
            value: _vm.formData.maxQuantity,
            callback: function callback($$v) {
              _vm.$set(_vm.formData, "maxQuantity", $$v);
            },
            expression: "formData.maxQuantity"
          }
        })], 1) : _vm._e(), _c('v-flex', {
          attrs: {
            "xs6": ""
          }
        }, [_vm.selectOptionsItemListings ? _c('AppSelect', {
          attrs: {
            "select-options": _vm.selectOptionsItemListings,
            "label": "Assign an Item listing"
          },
          scopedSlots: _vm._u([{
            key: "select-option",
            fn: function fn(_ref) {
              var option = _ref.option;
              return [_c('div', {
                staticClass: "c-flex row align-center my-2 half-gap"
              }, [_c('span', {
                staticClass: "monospace_font",
                staticStyle: {
                  "width": "150px"
                }
              }, [_vm._v(" " + _vm._s(option.details._name) + " ")]), option.details._description ? _c('small', [_vm._v(" " + _vm._s(option.details._description) + " ")]) : _vm._e()])];
            }
          }], null, false, 2411056912),
          model: {
            value: _vm.formData.autoHardwareListId,
            callback: function callback($$v) {
              _vm.$set(_vm.formData, "autoHardwareListId", $$v);
            },
            expression: "formData.autoHardwareListId"
          }
        }) : _vm._e()], 1)], 1)], 1)], 1), _c('v-slide-x-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [!_vm.showPartReferenceSelector ? _c('div', {
          staticClass: "my-2"
        }, [_c('ButtonCreate', {
          staticClass: "mb-2",
          attrs: {
            "small": ""
          },
          on: {
            "click": function click($event) {
              _vm.showPartReferenceSelector = true;
            }
          }
        }, [_vm._v("Add part")]), _c('AppTableActions', {
          attrs: {
            "color": _vm.EntityType.ITEM_INSTANCE,
            "headers": _vm.PartReferenceTableHeaders,
            "icon": _vm.$icons.part_reference,
            "items": _vm.partReferenceTableItems,
            "title": "Parts"
          },
          scopedSlots: _vm._u([{
            key: "actions",
            fn: function fn(_ref2) {
              var item = _ref2.item;
              return [_c('ButtonMiniActionRemoveFromList', {
                on: {
                  "click": function click($event) {
                    return _vm.onRemovalOfPartReference(item);
                  }
                }
              })];
            }
          }], null, false, 1038714857)
        })], 1) : _vm._e()]), _c('v-slide-x-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [_vm.showPartReferenceSelector ? _c('div', {
          staticClass: "my-2"
        }, [_c('SelectorPartReference', {
          on: {
            "edited-part-reference-selected": _vm.onPartReferenceEdited,
            "new-part-reference-selected": _vm.onNewPartReferenceSelected,
            "part-reference-selected": _vm.onPartReferenceSelected
          }
        }), _c('div', {
          staticClass: "c-flex align-items-center mt-2"
        }, [_c('ButtonCreate', {
          attrs: {
            "small": ""
          },
          on: {
            "click": _vm.onClickOfAddPartReference
          }
        }, [_vm._v("Add part")]), _c('ButtonCancel', {
          attrs: {
            "small": ""
          },
          on: {
            "click": function click($event) {
              _vm.showPartReferenceSelector = false;
            }
          }
        }, [_vm._v("Cancel")])], 1)], 1) : _vm._e()])], 1) : _c('div', [_c('c-progress')], 1)];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('ButtonSave', {
          staticClass: "mr-2",
          attrs: {
            "disabled": _vm.shouldDisableEditButton,
            "is-saving": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfEditAssemblyTemplate
          }
        }), _c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        })];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };
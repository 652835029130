function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "app_item_drawing_display"
  }, [_c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.isFetching ? _c('div', {
    staticClass: "isFetching"
  }, [_c('c-progress')], 1) : _vm._e()]), !_vm.isFetching && _vm.transformedSelectedEntity ? _c('div', [!_vm.isMinimized && _vm.transformedSelectedEntity ? _c('div', {
    class: {
      minimized: _vm.isMinimized
    }
  }, [_c('div', {
    staticClass: "item_drawing_display_grid"
  }, [_c('div', {
    staticClass: "column-one"
  }, [_c('c-list-group', [_c('c-list-group-item', {
    attrs: {
      "label-width": "100px",
      "value-width": "300px",
      "height": _vm.HEIGHT_OF_ITEM
    }
  }, [_c('div', {
    staticClass: "text-xs-right",
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v("Drawing number")]), _c('div', {
    attrs: {
      "slot": "value"
    },
    slot: "value"
  }, [_vm._v(" " + _vm._s(_vm.computedDrawingNumber) + " ")])])], 1), _c('c-list-group', [_c('c-list-group-item', {
    attrs: {
      "label-width": "100px",
      "value-width": "300px",
      "height": "100px"
    }
  }, [_c('div', {
    staticClass: "text-xs-right",
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v(" Sizes"), _c('br'), _vm._v(" ("), _vm.transformedSelectedEntity.sizes ? _c('small', [_vm._v(_vm._s(_vm.transformedSelectedEntity.sizes.length))]) : _vm._e(), _vm._v(") ")]), _vm.transformedSelectedEntity.sizes && _vm.transformedSelectedEntity.sizes.length ? _c('div', {
    attrs: {
      "slot": "value"
    },
    slot: "value"
  }, [_c('ul', {
    staticClass: "pa-0 list-style-none",
    staticStyle: {
      "max-height": "100px",
      "overflow-y": "auto"
    }
  }, _vm._l(_vm.transformedSelectedEntity._sizes, function (size) {
    return _c('li', {
      key: size,
      staticClass: "pl-0 font-weight-bold monospace_font"
    }, [_vm._v(" " + _vm._s(size) + " ")]);
  }), 0)]) : _c('div', {
    staticClass: "text-xs-left",
    attrs: {
      "slot": "value"
    },
    slot: "value"
  }, [_vm._v("—")])]), _c('c-list-group-item', {
    attrs: {
      "label-width": "100px",
      "value-width": "300px",
      "height": "100px"
    }
  }, [_c('div', {
    staticClass: "text-xs-right",
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v(" Aliases"), _c('br'), _vm._v(" ("), _vm.transformedSelectedEntity.aliases ? _c('small', [_vm._v(_vm._s(_vm.transformedSelectedEntity.aliases.length))]) : _vm._e(), _vm._v(") ")]), _vm.transformedSelectedEntity.aliases && _vm.transformedSelectedEntity.aliases.length ? _c('div', {
    attrs: {
      "slot": "value"
    },
    slot: "value"
  }, [_c('ul', {
    staticClass: "pa-0 list-style-none",
    staticStyle: {
      "max-height": "80px",
      "overflow-y": "auto"
    }
  }, _vm._l(_vm.transformedSelectedEntity._aliases, function (alias) {
    return _c('li', {
      key: alias,
      staticClass: "pl-0 font-weight-bold monospace_font"
    }, [_vm._v(" " + _vm._s(alias) + " ")]);
  }), 0)]) : _c('div', {
    staticClass: "text-xs-left",
    attrs: {
      "slot": "value"
    },
    slot: "value"
  }, [_vm._v("—")])])], 1)], 1), _c('div', {
    staticClass: "column-two"
  }, [_c('c-list-group', {
    attrs: {
      "tooltip": "This drawing number's distinct Inventory classes",
      "tooltip-position": "bottom left"
    }
  }, [_c('c-list-group-item', {
    attrs: {
      "label-width": "100px",
      "value-width": "300px",
      "height": "100px"
    }
  }, [_c('div', {
    staticClass: "text-xs-right",
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v("Computed"), _c('br'), _vm._v("Item Classes")]), _vm.transformedSelectedEntity.itemClasses && _vm.transformedSelectedEntity.itemClasses.length ? _c('div', {
    attrs: {
      "slot": "value"
    },
    slot: "value"
  }, [_c('ul', {
    staticClass: "pa-0 list-style-none",
    staticStyle: {
      "max-height": "100px",
      "overflow-y": "auto"
    }
  }, _vm._l(_vm.transformedSelectedEntity._itemClasses, function (itemClass) {
    return _c('li', {
      key: itemClass,
      staticClass: "pl-0 font-weight-bold monospace_font"
    }, [_vm._v(" " + _vm._s(itemClass) + " ")]);
  }), 0)]) : _c('div', {
    attrs: {
      "slot": "value"
    },
    slot: "value"
  }, [_vm._v("—")])])], 1)], 1), _c('div', {
    staticClass: "column-three"
  }, [_c('c-list-group', {
    attrs: {
      "width": "200px"
    }
  }, [_c('c-list-group-item', {
    attrs: {
      "label-width": "100px",
      "value-width": "100px",
      "height": _vm.HEIGHT_OF_ITEM
    }
  }, [_c('div', {
    staticClass: "text-xs-right",
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v("PoC")]), _c('div', {
    attrs: {
      "slot": "value"
    },
    slot: "value"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._pointOfContact) + " ")])]), _c('c-list-group-item', {
    attrs: {
      "label-width": "100px",
      "value-width": "100px",
      "height": _vm.HEIGHT_OF_ITEM
    }
  }, [_c('div', {
    staticClass: "text-xs-right",
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v("MTBF")]), _c('div', {
    attrs: {
      "slot": "value"
    },
    slot: "value"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._meanTimeBetweenFailure) + " ")])]), _c('c-list-group-item', {
    attrs: {
      "label-width": "100px",
      "value-width": "100px",
      "height": _vm.HEIGHT_OF_ITEM
    }
  }, [_c('div', {
    staticClass: "text-xs-right",
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v("MTTR")]), _c('div', {
    attrs: {
      "slot": "value"
    },
    slot: "value"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._meanTimeToRepair) + " ")])])], 1), _c('c-list-group', {
    attrs: {
      "width": "200px"
    }
  })], 1), _c('div', {
    staticClass: "column-four"
  }, [_c('c-list-group', {
    attrs: {
      "width": "200px"
    }
  }, [_c('c-list-group-item', {
    attrs: {
      "label-width": "100px",
      "value-width": "100px",
      "height": _vm.HEIGHT_OF_ITEM
    }
  }, [_c('div', {
    staticClass: "text-xs-right",
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v("EEE identifier")]), _c('div', {
    attrs: {
      "slot": "value"
    },
    slot: "value"
  }, [_c('AppBooleanDisplay', {
    attrs: {
      "value": _vm.transformedSelectedEntity.eeeIdentifier,
      "icon-size": "16px"
    }
  })], 1)]), _c('c-list-group-item', {
    attrs: {
      "label-width": "100px",
      "value-width": "100px",
      "height": _vm.HEIGHT_OF_ITEM
    }
  }, [_c('div', {
    staticClass: "text-xs-right",
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v("Initialization")]), _c('div', {
    attrs: {
      "slot": "value"
    },
    slot: "value"
  }, [_c('c-icon-fa', {
    attrs: {
      "icon-size": "16px",
      "icon": _vm.transformedSelectedEntity._initializationStatusIconOnly,
      "icon-color": _vm.transformedSelectedEntity._initializationStatusIconColor,
      "tooltip": _vm.transformedSelectedEntity._initializationStatusIconTitle
    }
  })], 1)])], 1)], 1), _c('div', {
    staticClass: "column-five"
  }, [_c('c-list-group', {
    attrs: {
      "width": "200px"
    }
  }, [_c('c-list-group-item', {
    attrs: {
      "label-width": "100px",
      "value-width": "100px",
      "height": _vm.HEIGHT_OF_ITEM
    }
  }, [_c('div', {
    staticClass: "text-xs-right",
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v("Avail qty")]), _c('div', {
    attrs: {
      "slot": "value"
    },
    slot: "value"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity.availableQuantity || '-') + " ")])]), _c('c-list-group-item', {
    attrs: {
      "label-width": "100px",
      "value-width": "100px",
      "height": _vm.HEIGHT_OF_ITEM
    }
  }, [_c('div', {
    staticClass: "text-xs-right",
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v("Inst qty")]), _c('div', {
    attrs: {
      "slot": "value"
    },
    slot: "value"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity.installedQuantity || '-') + " ")])]), _c('c-list-group-item', {
    attrs: {
      "label-width": "100px",
      "value-width": "100px",
      "height": _vm.HEIGHT_OF_ITEM
    }
  }, [_c('div', {
    staticClass: "text-xs-right",
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v("Managed qty")]), _c('div', {
    attrs: {
      "slot": "value"
    },
    slot: "value"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity.managedQuantity || '-') + " ")])]), _c('c-list-group-item', {
    attrs: {
      "label-width": "100px",
      "value-width": "100px",
      "height": _vm.HEIGHT_OF_ITEM
    }
  }, [_c('div', {
    staticClass: "text-xs-right",
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v("Unmanaged qty")]), _c('div', {
    attrs: {
      "slot": "value"
    },
    slot: "value"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity.unmanagedQuantity || '-') + " ")])])], 1)], 1)]), _c('div', {
    staticClass: "c-grid"
  }, [_c('div', {
    staticClass: "col-full"
  }, [_c('c-list-group', {
    attrs: {
      "width": "100%"
    }
  }, [_c('c-list-group-item', {
    attrs: {
      "label-width": "100px",
      "height": _vm.HEIGHT_OF_ITEM,
      "value-width": "700px"
    }
  }, [_c('div', {
    staticClass: "text-xs-right",
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v("Description")]), _c('div', {
    attrs: {
      "slot": "value"
    },
    slot: "value"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._description || '-') + " ")])])], 1)], 1)]), _vm.isArchived ? _c('div', {
    staticClass: "c-grid mt-2"
  }, [_c('div', {
    staticClass: "col-full"
  }, [_c('AppPageDescription', {
    attrs: {
      "color": "error"
    }
  }, [_c('strong', {
    staticClass: "error--text lighten-2"
  }, [_vm._v("Archived")])])], 1)]) : _vm._e()]) : _vm._e(), _vm.isMinimized && _vm.computedDrawingNumber ? _c('div', [_c('MiniItemDrawing', {
    attrs: {
      "drawingNumber": _vm.computedDrawingNumber,
      "show-manage-link": false
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "c-flex no-gap mt-2"
  }, [_c('router-link', {
    staticClass: "adh_button_link",
    attrs: {
      "tooltip": "View Item drawing manage page",
      "tooltip-position": "right",
      "to": _vm.transformedSelectedEntity._urlManage
    }
  }, [_c('c-icon-fa', {
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.ITEM_DRAWING],
      "icon-color": "#fff",
      "icon-size": "16px"
    }
  })], 1), _vm.hasItemMasters && _vm.transformedItemMasters.length > 1 ? _c('c-menu', {
    staticClass: "ml-2",
    attrs: {
      "align-left": "",
      "border-color": "var(--v-item_masters-base)",
      "border-size": "2px"
    }
  }, [_c('ButtonGeneric', {
    attrs: {
      "slot": "activator",
      "small": "",
      "icon-color": "white",
      "color": "var(--v-item_masters-base)"
    },
    slot: "activator"
  }, [_c('c-icon-fa', {
    staticClass: "pr-2",
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.ITEM_MASTER],
      "icon-size": "16px",
      "icon-color": "#fff"
    }
  }), _vm._v(" Item masters ")], 1), _vm._l(_vm.transformedItemMasters, function (im, index) {
    return _c('div', {
      key: index
    }, [im._urlManage ? _c('c-menu-item', {
      attrs: {
        "href": im._urlManage,
        "icon": _vm.$icons.MANAGE_PAGE,
        "icon-size": "16px",
        "icon-color": "var(--v-item_masters-base)"
      }
    }, [_c('SingleLineDisplayPart', _vm._b({
      attrs: {
        "drawing-number": null
      }
    }, 'SingleLineDisplayPart', _objectSpread({}, im), false))], 1) : _vm._e()], 1);
  })], 2) : _vm._e(), _vm.hasItemMasters && _vm.transformedItemMasters.length === 1 ? _c('router-link', {
    staticClass: "adh_button_link ml-2",
    attrs: {
      "tooltip": "View Item master manage page",
      "tooltip-position": "right",
      "to": _vm.transformedItemMasters[0]._urlManage
    }
  }, [_c('c-icon-fa', {
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.ITEM_MASTER],
      "icon-color": "#fff",
      "icon-size": "16px"
    }
  })], 1) : _vm._e(), _vm.hasItemInstances && _vm.transformedItemInstances.length > 1 ? _c('c-menu', {
    staticClass: "ml-2",
    attrs: {
      "align-left": "",
      "border-color": "var(--v-item_instances-base)",
      "border-size": "2px"
    }
  }, [_c('ButtonGeneric', {
    attrs: {
      "slot": "activator",
      "small": "",
      "icon-color": "white",
      "color": "var(--v-item_instances-base)"
    },
    slot: "activator"
  }, [_c('c-icon-fa', {
    staticClass: "pr-2",
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.ITEM_INSTANCE],
      "icon-size": "16px",
      "icon-color": "#fff"
    }
  }), _vm._v(" Item instances ")], 1), _vm._l(_vm.transformedItemInstances, function (ii, index) {
    return _c('div', {
      key: index
    }, [ii._urlManage ? _c('c-menu-item', {
      attrs: {
        "href": ii._urlManage,
        "icon": _vm.$icons.MANAGE_PAGE,
        "icon-size": "16px",
        "icon-color": "var(--v-item_instances-base)"
      }
    }, [_c('SingleLineDisplayPart', _vm._b({
      attrs: {
        "drawing-number": null
      }
    }, 'SingleLineDisplayPart', _objectSpread({}, ii), false))], 1) : _vm._e()], 1);
  })], 2) : _vm._e(), _vm.hasItemInstances && _vm.transformedItemInstances.length === 1 ? _c('router-link', {
    staticClass: "adh_button_link ml-2",
    attrs: {
      "tooltip": "View Item instance manage page",
      "tooltip-position": "right",
      "to": _vm.transformedItemInstances[0]._urlManage
    }
  }, [_c('c-icon-fa', {
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.ITEM_INSTANCE],
      "icon-color": "#fff",
      "icon-size": "16px"
    }
  })], 1) : _vm._e(), _vm.hasLinks ? _c('c-menu', {
    staticClass: "ml-2",
    attrs: {
      "align-left": "",
      "border-color": "var(--v-comments-base)",
      "border-size": "2px"
    }
  }, [_c('ButtonGeneric', {
    attrs: {
      "slot": "activator",
      "small": "",
      "icon-color": "white",
      "color": "var(--v-comments-base)"
    },
    slot: "activator"
  }, [_c('c-icon-fa', {
    staticClass: "pr-2",
    attrs: {
      "icon": _vm.$icons.link,
      "icon-size": "16px",
      "icon-color": "#fff"
    }
  }), _vm._v(" Links ")], 1), _vm._l(_vm.transformedLinks, function (link, index) {
    return _c('div', {
      key: index
    }, [link.currentVersionUrl ? _c('c-menu-item', {
      attrs: {
        "href": link.currentVersionUrl,
        "icon": _vm.$icons.link,
        "icon-size": "16px",
        "icon-color": "var(--v-comments-base)"
      }
    }, [_vm._v(" " + _vm._s(link.currentVersionUrl)), _c('br'), link.currentRevision ? _c('small', {
      staticClass: "d-block"
    }, [_vm._v(" current rev: "), _c('span', {
      staticClass: "monospace_font"
    }, [_vm._v(" " + _vm._s(link._currentRevision) + " ")])]) : _vm._e(), link.referenceNumber ? _c('small', {
      staticClass: "d-block"
    }, [_vm._v(" ref num: "), _c('span', {
      staticClass: "monospace_font"
    }, [_vm._v(" " + _vm._s(link._referenceNumber) + " ")])]) : _vm._e(), link.subType ? _c('small', {
      staticClass: "d-block"
    }, [_vm._v("type: " + _vm._s(link._subType))]) : _vm._e()]) : _vm._e(), link.workingVersionUrl ? _c('v-divider') : _vm._e(), link.workingVersionUrl ? _c('c-menu-item', {
      attrs: {
        "href": link.workingVersionUrl,
        "icon": _vm.$icons.link,
        "icon-size": "16px",
        "icon-color": "var(--v-comments-base)"
      }
    }, [_vm._v(" " + _vm._s(link.workingVersionUrl)), _c('br'), link.currentRevision ? _c('small', {
      staticClass: "d-block"
    }, [_vm._v(" current rev: "), _c('span', {
      staticClass: "monospace_font"
    }, [_vm._v(" " + _vm._s(link._currentRevision) + " ")])]) : _vm._e(), link.referenceNumber ? _c('small', {
      staticClass: "d-block"
    }, [_vm._v(" ref num: "), _c('span', {
      staticClass: "monospace_font"
    }, [_vm._v(" " + _vm._s(link._referenceNumber) + " ")])]) : _vm._e(), link.subType ? _c('small', {
      staticClass: "d-block"
    }, [_vm._v("type: " + _vm._s(link._subType))]) : _vm._e()]) : _vm._e()], 1);
  })], 2) : _vm._e(), _vm.computedDrawingNumber && _vm.changelogHref ? _c('router-link', {
    staticClass: "adh_button_link ml-2",
    attrs: {
      "tooltip": "View the changelog for ".concat(_vm.computedDrawingNumber),
      "tooltip-position": "right",
      "to": _vm.changelogHref
    }
  }, [_c('c-icon-fa', {
    attrs: {
      "icon": _vm.$icons.history,
      "icon-color": "#fff",
      "icon-size": "16px"
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "d-flex align-center ml-2"
  }, [!_vm.isMinimized ? _c('c-button-icon', {
    attrs: {
      "icon-size": "16px",
      "icon": "fa-regular fa-arrow-down-left-and-arrow-up-right-to-center",
      "tooltip": "Minimize card",
      "tooltip-position": "right"
    },
    on: {
      "c-click": _vm.onClickOfMinimize
    }
  }) : _vm.isMinimized ? _c('c-button-icon', {
    attrs: {
      "icon-size": "16px",
      "icon": "fa-regular fa-arrow-up-right-and-arrow-down-left-from-center",
      "tooltip": "Maximize card",
      "tooltip-position": "right"
    },
    on: {
      "c-click": _vm.onClickOfMaximize
    }
  }) : _vm._e()], 1)], 1)]) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "container pa-1 mt-2"
  }, [_c('SearchInputContainer', {
    attrs: {
      "autofocus": _vm.autofocus,
      "disable-search": _vm.disableSearch,
      "entity-type": _vm.entityType,
      "hint": _vm.hint,
      "is-loading": _vm.isLoading,
      "placeholder": _vm.computedPlaceholder
    },
    on: {
      "searchButtonClicked": _vm.onClickOfSearchButton,
      "enterPressed": _vm.onClickOfSearchButton
    },
    model: {
      value: _vm.searchInput,
      callback: function callback($$v) {
        _vm.searchInput = $$v;
      },
      expression: "searchInput"
    }
  }), _c('SearchFilterDisplay', {
    attrs: {
      "active": _vm.shouldShowFilters,
      "color": _vm.entityType,
      "entity-type": _vm.entityType,
      "chipsFromFilters": _vm.chipsFromFilters,
      "show-filter-button": _vm.showFilterButton
    },
    on: {
      "appChipCloseClicked": _vm.onClickOfRemoveFilter,
      "clearAllFilters": _vm.onClearAllFilters,
      "filterButtonClicked": _vm.onClickOfFilterIcon
    }
  }), _c('SearchFilterContainer', {
    attrs: {
      "color": _vm.entityType,
      "show": _vm.shouldShowFilters
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs6": "",
      "pr-3": ""
    }
  }, [_c('AppSelectEnum', {
    attrs: {
      "chips": false,
      "disabled": _vm.isFilterLocked('itemDrawingMasterSearchScope'),
      "display": _vm.SearchItemDrawingItemMasterScopeDisplay,
      "enum": _vm.SearchItemDrawingItemMasterScope,
      "label": "Search Scope",
      "sort": ""
    },
    model: {
      value: _vm.searchFilters.searchScope,
      callback: function callback($$v) {
        _vm.$set(_vm.searchFilters, "searchScope", $$v);
      },
      expression: "searchFilters.searchScope"
    }
  })], 1)], 1)], 1), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.isLoading ? _c('c-progress') : _vm._e()], 1), _c('v-slide-y-transition', {
    attrs: {
      "tag": "table",
      "hide-on-leave": ""
    }
  }, [_vm.shouldShowTable ? _c('AppTable', {
    key: "searchResults",
    attrs: {
      "color": _vm.entityType,
      "headers": _vm.tableHeaders,
      "items": _vm.searchResults,
      "rows-per-page-items": _vm.defaultHardwareTablePagination,
      "dense-rows": "",
      "disable-initial-sort": "",
      "selectable-rows": "",
      "show-count": "",
      "hide-icon": "",
      "title": "Hardware results"
    },
    on: {
      "rowClicked": _vm.onClickOfSearchResult
    },
    scopedSlots: _vm._u([{
      key: "_entityType",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [item._entityType === _vm.EntityType.ITEM_DRAWING ? _c('c-icon-fa', {
          attrs: {
            "icon": _vm.$icons[_vm.EntityType.ITEM_DRAWING],
            "icon-color": "var(--v-item_instances-base)"
          }
        }) : _vm._e(), item._entityType === _vm.EntityType.ITEM_MASTER ? _c('c-icon-fa', {
          attrs: {
            "icon": _vm.$icons[_vm.EntityType.ITEM_MASTER],
            "icon-color": "var(--v-item_instances-base)"
          }
        }) : _vm._e()];
      }
    }, {
      key: "_asBuiltNumber",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item._entityType === _vm.EntityType.ITEM_DRAWING ? _c('span', {
          staticClass: "not_applicable monospace_font",
          attrs: {
            "tooltip": "This is not applicable."
          }
        }, [_vm._v("N/A")]) : _c('span', [_vm._v(_vm._s(item._asBuiltNumber))])];
      }
    }, {
      key: "_side",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [item._entityType === _vm.EntityType.ITEM_DRAWING ? _c('span', {
          staticClass: "not_applicable monospace_font",
          attrs: {
            "tooltip": "This is not applicable."
          }
        }, [_vm._v("N/A")]) : _c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(_vm._s(item._side))])];
      }
    }], null, false, 2748010199)
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
import Vue from 'vue';
// Apollo configuration
import VueApollo from 'vue-apollo';
import { NavigationFailureType } from 'vue-router';
import draggable from 'vuedraggable';
import { stripHtmlFromString } from '~/nasa_ui/utils';
import { storeAuthRedirect } from '~/nasa_ui/utils/authRedirect';
import { AxiosPlugin, ChartsPlugin, DatePlugin, DisplayPlugin, HumanizePlugin, LodashPlugin, SafeHtml, Vuetify, VuetifyTheme, WebPlugin } from '~/nasa_ui/vue_plugins';
import { default as installWebComponents } from '~/web_components/install_components';
import ApplicationConfig from './application.config';
// Application
import CosmicApp from './CosmicApp.vue';
import apolloProvider from './nasa_ui/apollo.config';
import { initializeCurrentUser } from './nasa_ui/mixins/CurrentUserMixin';
import { checkSetActiveSupportContextViaQuerystring, checkUserForPermissionRequirements, checkUserForSupportContexts, checkUserRoleRequirements, refreshCurrentUser } from './nasa_ui/router/utils';
import { store } from './nasa_ui/store';
import silenceNavigationalErrors from './nasa_ui/utils/silenceNavigationalErrors';
import routerWithLogging from './router';
Vue.config.productionTip = false;
Vue.config.ignoredElements = [/c-*/];
Vue.component('draggable', draggable);
// Plug-ins
Vue.use(ApplicationConfig);
Vue.use(ChartsPlugin);
Vue.use(DatePlugin);
Vue.use(DisplayPlugin);
Vue.use(HumanizePlugin);
Vue.use(LodashPlugin);
Vue.use(VueApollo);
Vue.use(SafeHtml);
Vue.use(Vuetify, VuetifyTheme);
Vue.use(WebPlugin);
storeAuthRedirect();
// Router middlewares
checkSetActiveSupportContextViaQuerystring(routerWithLogging.router);
refreshCurrentUser(routerWithLogging.router);
checkUserForSupportContexts(routerWithLogging.router);
checkUserRoleRequirements(routerWithLogging.router);
checkUserForPermissionRequirements(routerWithLogging.router);
silenceNavigationalErrors([NavigationFailureType.duplicated, NavigationFailureType.cancelled]);
Promise.all(installWebComponents([
    'Button',
    'ButtonIcon',
    'Card',
    'Checkbox',
    'IconFa',
    'IconFaStacked',
    'IconNasaMeatball',
    'IconNasaWorm',
    'IconNotCosmic',
    'ListGroup',
    'ListGroupItem',
    'Menu',
    'MenuItem',
    'Modal',
    'Menu',
    'MenuItem',
    'Number',
    'Popover',
    'Progress',
    'Radio',
    'RadioGroup',
    'Select',
    'Spinner',
    'Text'
]))
    .then(initializeCurrentUser)
    .then((currentUser) => {
    store.currentUser = currentUser;
    // wait for current user before setting up $http axios instance
    Vue.use(AxiosPlugin);
    new Vue({
        apolloProvider,
        router: routerWithLogging.router,
        render: (h) => h(CosmicApp),
        watch: {
            $route: {
                immediate: true,
                handler(to) {
                    document.title = stripHtmlFromString(to.meta.displayName) || 'COSMIC';
                }
            }
        }
    }).$mount('#app');
});

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_vm.myTopSitesSortedGrouped.length ? _c('v-flex', {
    attrs: {
      "xs4": ""
    }
  }, [_c('AppPageDescription', {
    attrs: {
      "remove-margin-left": "",
      "color": "primary"
    }
  }, [_vm._v(" My most visited ")]), _c('ul', {
    staticClass: "list-style-none pl-0 cosmic_top_page_list"
  }, [_c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.isLoadingMyTopSites ? _c('c-progress') : _vm._e()], 1), _vm._l(_vm.myTopSitesSortedGrouped, function (page, index) {
    return _c('li', {
      key: index,
      staticClass: "my-2",
      attrs: {
        "tooltip": page.description
      }
    }, [_c('a', {
      staticClass: "delinkify text-truncate pa-1",
      attrs: {
        "href": page.toUrl
      },
      on: {
        "click": function click($event) {
          return _vm.onClick($event, page.toUrl);
        }
      }
    }, [_vm._v(" " + _vm._s(page.humanizedToUrlName) + " ")])]);
  })], 2)], 1) : _vm._e(), _c('v-flex', {
    attrs: {
      "xs4": "",
      "offset-xs4": ""
    }
  }, [_c('AppPageDescription', {
    attrs: {
      "remove-margin-left": "",
      "color": "primary"
    }
  }, [_c('div', {
    staticClass: "cosmic_top_sites_header"
  }, [_c('img', {
    attrs: {
      "alt": _vm.currentSystemName,
      "src": _vm.cosmicFaviconSrc,
      "height": "26"
    }
  }), _c('span', {
    staticClass: "pl-2"
  }, [_vm._v(" most visited ")])])]), _c('ul', {
    staticClass: "list-style-none pl-0 cosmic_top_page_list"
  }, [_c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.isLoadingCommunityTopSites ? _c('c-progress') : _vm._e()], 1), _vm._l(_vm.communityTopSitesSortedGrouped, function (page, index) {
    return _c('li', {
      key: index,
      staticClass: "my-2",
      attrs: {
        "tooltip": page.description
      }
    }, [_c('a', {
      staticClass: "delinkify text-truncate pa-1",
      attrs: {
        "href": page.toUrl
      },
      on: {
        "click": function click($event) {
          return _vm.onClick($event, page.toUrl);
        }
      }
    }, [_vm._v(" " + _vm._s(page.humanizedToUrlName) + " ")])]);
  })], 2)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_vm.title ? _c('AppHeader', {
    attrs: {
      "size": "mini"
    }
  }, [_vm._v(_vm._s(_vm.title))]) : _vm._e(), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.transformedSelectedEntity ? _c('div', {
    staticClass: "entity-mini contracts"
  }, [_c('c-icon-fa', {
    staticClass: "entity-mini-icon",
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.CONTRACT],
      "icon-size": "32px",
      "icon-color": "white"
    }
  }), _c('div', {
    staticClass: "entity-mini-name text-truncate white--text"
  }, [_c('div', {
    staticClass: "mini-name text-truncate display-1"
  }, [_vm._v(_vm._s(_vm.transformedSelectedEntity._name))])]), _c('div', {
    staticClass: "vertical-display-items white--text"
  }, [_c('div', {
    staticClass: "vertical-display-item text-truncate"
  }, [_c('div', {
    staticClass: "text-capitalize mb-1 text-truncate"
  }, [_vm._v("Number")]), _c('div', {
    staticClass: "text-truncate vertical-display-item-value monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity.number) + " ")])]), _c('div', {
    staticClass: "vertical-display-item text-truncate"
  }, [_c('div', {
    staticClass: "text-capitalize mb-1 text-truncate"
  }, [_vm._v("Start")]), _c('div', {
    staticClass: "text-truncate vertical-display-item-value monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._startDate) + " ")])]), _c('div', {
    staticClass: "vertical-display-item text-truncate"
  }, [_c('div', {
    staticClass: "text-capitalize mb-1 text-truncate"
  }, [_vm._v("End")]), _c('div', {
    staticClass: "text-truncate vertical-display-item-value monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._endDate) + " ")])])]), _c('div', {
    staticClass: "mini_actions"
  }, [_vm.inlineClear && !_vm.isReadOnly ? _c('c-icon-fa', {
    staticClass: "inline_clear_icon",
    attrs: {
      "icon-color": "white",
      "tooltip": "Clear selection",
      "icon": _vm.$icons.clear
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.onClickOfInlineClear.apply(null, arguments);
      }
    }
  }) : _vm._e()], 1)], 1) : _vm._e()]), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.isFetching ? _c('c-progress') : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.localEntity ? _c('div', {
    staticClass: "entity-wrapper mini_assembly",
    on: {
      "dblclick": function dblclick($event) {
        $event.stopPropagation();
        _vm.showModalAssemblyEdit = true;
      }
    }
  }, [_vm.hasComputedTitleOrSlot ? _c('AppHeader', {
    attrs: {
      "size": "mini",
      "uppercase": false
    }
  }, [_vm._t("title", function () {
    return [_vm._v(_vm._s(_vm.computedTitle))];
  })], 2) : _vm._e(), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.hasLocalEntity ? _c('div', {
    staticClass: "entity-mini no-entity-icon",
    class: _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, _vm.color, _vm.color), "hasChildren", _vm.hasChildren), "highlight", _vm.shouldHighlightNode), "highlightBackground", _vm.shouldHighlightConnection), "hoverEffects", _vm.isAManageView || _vm.isIplView), "isRoot", _vm.isRoot), "showingChildren", _vm.shouldShowChildContainer)
  }, [_vm.shouldShowSequence && !_vm.isRoot ? _c('div', {
    staticClass: "mini_chips"
  }, [_c('v-chip', {
    staticClass: "mini_chip seq_chip monospace_font",
    attrs: {
      "color": "white",
      "text-color": "assemblies darken-2"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.localEntity.sequence || 0))]), _vm.assemblyTemplateId ? _c('AppBooleanDisplay', {
    staticClass: "matches_template_icon",
    attrs: {
      "value": _vm.isMatch
    }
  }) : _vm._e()], 1)], 1) : _vm._e(), _vm.isRoot ? _c('div', {
    staticClass: "mini_chips"
  }, [_c('c-icon-fa', {
    attrs: {
      "icon": _vm.$icons.root,
      "icon-color": "white"
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "entity-mini-name text-truncate white--text"
  }, [_c('div', {
    staticClass: "mini-name text-truncate display-1 monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.name) + " ")]), _c('small', {
    staticClass: "caption font-weight-bold",
    attrs: {
      "title": _vm.descriptionDisplay
    }
  }, [_vm._v(_vm._s(_vm.descriptionDisplay))])]), _c('div', {
    staticClass: "mini_chips"
  }, [_vm.openChangeSets.length > 0 || _vm.numberOfOpenParentChangeSets.length > 0 ? _c('c-icon-fa', {
    staticClass: "entity-mini-icon mr-2 mt-1",
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.CHANGE_SET]
    }
  }) : _vm._e(), _c('div', {
    attrs: {
      "title": _vm.descriptionTitle
    }
  }, [_c('v-chip', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "mini_chip text-truncate",
    attrs: {
      "color": "white",
      "text-color": "assemblies darken-2"
    }
  }, [_c('span', [_c('strong', [_vm._v(_vm._s(_vm.descriptionDisplay))])])])], 1), _c('v-chip', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "mini_chip",
    attrs: {
      "color": "white",
      "text-color": "assemblies darken-2"
    }
  }, [_c('span', {
    staticClass: "pr-1"
  }, [_vm._v("Side:")]), _c('span', [_c('strong', [_vm._v(_vm._s(_vm.sideDisplay))])])]), _c('v-chip', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.shouldShowInstalledOn,
      expression: "shouldShowInstalledOn"
    }],
    staticClass: "mini_chip",
    attrs: {
      "color": "white",
      "text-color": "assemblies darken-2"
    }
  }, [_c('span', {
    staticClass: "pr-1"
  }, [_vm._v("Installed on:")]), _c('span', [_c('strong', [_vm._v(_vm._s(_vm.installedOnDisplay))])])]), _c('v-chip', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "mini_chip",
    attrs: {
      "color": "white",
      "text-color": "assemblies darken-2"
    }
  }, [_c('span', {
    staticClass: "pr-1"
  }, [_vm._v("AsBuilt:")]), _c('span', {
    staticClass: "monospace_font"
  }, [_c('strong', [_vm._v(_vm._s(_vm.asBuiltDisplay))])])]), _c('v-chip', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.shouldShowSize,
      expression: "shouldShowSize"
    }],
    staticClass: "mini_chip",
    attrs: {
      "color": "white",
      "text-color": "assemblies darken-2"
    }
  }, [_c('span', {
    staticClass: "pr-1"
  }, [_vm._v("Size:")]), _c('span', [_c('strong', [_vm._v(_vm._s(_vm.sizeDisplay))])])]), _c('v-chip', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showSerial && _vm.serialDisplay !== '-',
      expression: "showSerial && serialDisplay !== '-'"
    }],
    staticClass: "mini_chip text-truncate",
    attrs: {
      "color": "white",
      "text-color": "assemblies darken-2"
    }
  }, [_c('span', {
    staticClass: "pr-1"
  }, [_vm._v("Serial:")]), _c('span', {
    staticClass: "monospace_font"
  }, [_c('strong', [_vm._v(_vm._s(_vm.serialDisplay))])])]), _c('v-chip', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showLot && _vm.lotDisplay !== '-',
      expression: "showLot && lotDisplay !== '-'"
    }],
    staticClass: "mini_chip text-truncate",
    attrs: {
      "color": "white",
      "text-color": "assemblies darken-2"
    }
  }, [_c('span', {
    staticClass: "pr-1"
  }, [_vm._v("Lot:")]), _c('span', {
    staticClass: "monospace_font"
  }, [_c('strong', [_vm._v(_vm._s(_vm.lotDisplay))])])]), _c('v-chip', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isRoot,
      expression: "isRoot"
    }],
    staticClass: "mini_chip text-truncate",
    attrs: {
      "color": "white",
      "text-color": "assemblies darken-2"
    }
  }, [_c('span', {
    staticClass: "pr-1"
  }, [_vm._v("Class:")]), _c('span', {
    staticClass: "monospace_font"
  }, [_c('strong', [_vm._v(_vm._s(_vm.classDisplay))])])]), _c('v-chip', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showQty,
      expression: "showQty"
    }],
    staticClass: "mini_chip text-truncate",
    attrs: {
      "color": "white",
      "text-color": "assemblies darken-2"
    }
  }, [_c('span', {
    staticClass: "pr-1"
  }, [_vm._v("Qty:")]), _c('span', {
    staticClass: "monospace_font"
  }, [_c('strong', [_vm._v(_vm._s(_vm.quantityDisplay))])])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showChildWorkingAssemblies,
      expression: "showChildWorkingAssemblies"
    }]
  }, [_vm.shouldShowChildWorkingAssembliesDisplay ? _c('v-chip', {
    staticClass: "mini_chip text-truncate",
    class: {
      highlight: _vm.shouldHighlightConnection
    },
    attrs: {
      "color": "white",
      "text-color": "assemblies darken-2"
    }
  }, [_c('c-icon-fa', {
    staticClass: "pr-2",
    attrs: {
      "icon": _vm.$icons.inventories,
      "font-size": "12px"
    }
  }), _c('strong', [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.myChildWorkingAssemblies.length) + " ")]), _c('span', [_vm._v(" " + _vm._s(_vm.myChildWorkingAssemblies.length === 1 ? 'child' : 'children') + " ")])])], 1) : _vm._e()], 1)], 1), _c('div', {
    staticClass: "mini_actions_v2"
  }, [_vm.showCollapseIcon && _vm.hasChildren ? _c('ButtonMiniAction', {
    attrs: {
      "disabled": !_vm.hasChildren,
      "icon": _vm.chevronIcon,
      "tooltip": !_vm.hasChildren ? 'No children' : _vm.localShowChildren ? 'Hide children' : 'Show children',
      "icon-size": "18",
      "label": "Show/hide children",
      "tooltip-position": "left"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.onClickOfShowChildren.apply(null, arguments);
      }
    }
  }) : _vm._e(), _vm.inlineClear && !_vm.readOnly ? _c('ButtonMiniAction', {
    staticClass: "pa-1",
    attrs: {
      "icon": _vm.$icons.clear,
      "label": "Clear selected assembly",
      "tooltip": "Clear selected assembly",
      "tooltip-position": "left"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.onClickOfInlineClear.apply(null, arguments);
      }
    }
  }) : _vm._e(), _c('div', [_c('v-menu', {
    attrs: {
      "bottom": "",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('ButtonMiniActionEllipsis', _vm._g({
          attrs: {
            "icon-color": "white",
            "tooltip-position": "left"
          }
        }, on))];
      }
    }], null, false, 3905435028)
  }, [_c('v-list', {
    staticClass: "py-0 mini_assembly__more_actions_list"
  }, [_c('div', {
    staticClass: "mini_assembly__more_actions_list_group"
  }, [_vm.shouldShowInstallChildIcon ? _c('v-list-tile', {
    attrs: {
      "tooltip": "Install a piece of hardware as a direct child.",
      "tooltip-position": "left"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.onClickOfCreateAssembly.apply(null, arguments);
      }
    }
  }, [_c('v-list-tile-action', [_c('c-icon-fa', {
    attrs: {
      "icon": _vm.$icons.install,
      "icon-color": "var(--v-success-base)"
    }
  })], 1), _c('v-list-tile-title', [_vm._v("Install onto Assembly")])], 1) : _vm._e(), _vm.shouldShowRemoveChildIcon ? _c('v-list-tile', {
    attrs: {
      "tooltip": "Uninstall this piece of hardware.",
      "tooltip-position": "left"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.onClickOfRemoveAssembly.apply(null, arguments);
      }
    }
  }, [_c('v-list-tile-action', [_c('c-icon-fa', {
    attrs: {
      "icon": _vm.$icons.uninstall,
      "icon-color": "var(--v-secondary-base)"
    }
  })], 1), _c('v-list-tile-title', [_vm._v("Uninstall from Assembly")])], 1) : _vm._e()], 1), _c('v-divider'), _c('div', {
    staticClass: "mini_assembly__more_actions_list_group"
  }, [_vm.computedLinkManageAssembly && !_vm.assemblyMatchesRoute ? _c('v-list-tile', {
    attrs: {
      "to": _vm.computedLinkManageAssembly
    }
  }, [_c('v-list-tile-action', [_c('c-icon-fa', {
    attrs: {
      "icon": _vm.$icons.MANAGE_PAGE,
      "icon-color": "var(--v-primary-base)"
    }
  })], 1), _c('v-list-tile-title', [_vm._v("Manage Assembly")])], 1) : _vm._e(), _vm.allowEdit ? _c('v-list-tile', {
    attrs: {
      "tooltip": "Allows you to edit sequence and which side this is installed on (if applicable)",
      "tooltip-position": "left"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.onDoubleClickOfMiniAssembly.apply(null, arguments);
      }
    }
  }, [_c('v-list-tile-action', [_c('c-icon-fa', {
    attrs: {
      "icon": _vm.editIcon,
      "icon-color": "var(--v-primary-base)"
    }
  })], 1), _c('v-list-tile-title', [_vm._v("Edit details")])], 1) : _vm._e()], 1), _c('v-divider'), _c('div', {
    staticClass: "mini_assembly__more_actions_list_group"
  }, [_c('v-list-tile', {
    attrs: {
      "tooltip": "Toggles a detailed view of the hardware installed.",
      "tooltip-position": "left"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.toggleShowAhd.apply(null, arguments);
      }
    }
  }, [_c('v-list-tile-action', [_c('c-icon-fa', {
    attrs: {
      "icon": _vm.$icons.info_strong,
      "icon-color": "var(--v-info-base)"
    }
  })], 1), _vm.showAhd ? _c('v-list-tile-title', [_vm._v("Hide hardware data")]) : _c('v-list-tile-title', [_vm._v("Show hardware data")])], 1)], 1), _c('v-divider'), _c('div', {
    staticClass: "mini_assembly__more_actions_list_group primary lighten-2"
  }, [_vm.computedLinkReportAssemblyTransactions ? _c('v-list-tile', {
    attrs: {
      "to": _vm.computedLinkReportAssemblyTransactions,
      "tooltip": "Lists all INSTALL | REMOVAL assembly transactions performed on the selected Item Instances.",
      "tooltip-position": "left"
    }
  }, [_c('v-list-tile-action', [_c('c-icon-fa', {
    attrs: {
      "icon": _vm.$icons.application_reporting,
      "icon-color": "var(--v-primary-base)"
    }
  })], 1), _c('v-list-tile-title', [_vm._v("Assembly transactions report")])], 1) : _vm._e(), _vm.computedLinkReportHardwareActivity ? _c('v-list-tile', {
    attrs: {
      "to": _vm.computedLinkReportHardwareActivity,
      "tooltip": "Lists all activites related to the selected Item Instance.",
      "tooltip-position": "left"
    }
  }, [_c('v-list-tile-action', [_c('c-icon-fa', {
    attrs: {
      "icon": _vm.$icons.application_reporting,
      "icon-color": "var(--v-primary-base)"
    }
  })], 1), _c('v-list-tile-title', [_vm._v("Hardware activity report")])], 1) : _vm._e(), _vm.computedLinkReportHardwareDates ? _c('v-list-tile', {
    attrs: {
      "to": _vm.computedLinkReportHardwareDates,
      "tooltip": "Lists all selected Item Instance's important dates.",
      "tooltip-position": "left"
    }
  }, [_c('v-list-tile-action', [_c('c-icon-fa', {
    attrs: {
      "icon": _vm.$icons.application_reporting,
      "icon-color": "var(--v-primary-base)"
    }
  })], 1), _c('v-list-tile-title', [_vm._v("Hardware dates report")])], 1) : _vm._e(), _vm.computedLinkReportInventoryTransactions ? _c('v-list-tile', {
    attrs: {
      "to": _vm.computedLinkReportInventoryTransactions,
      "tooltip": "Lists all Inventory transactions performed on the selected Item Instances.",
      "tooltip-position": "left"
    }
  }, [_c('v-list-tile-action', [_c('c-icon-fa', {
    attrs: {
      "icon": _vm.$icons.application_reporting,
      "icon-color": "var(--v-primary-base)"
    }
  })], 1), _c('v-list-tile-title', [_vm._v("Inventory transactions report")])], 1) : _vm._e(), _vm.computedLinkReportReadiness ? _c('v-list-tile', {
    attrs: {
      "to": _vm.computedLinkReportReadiness,
      "tooltip": "Complete Hardware readiness review reports for Hardware Lists or single Assemblies ( TRR / URR Report).",
      "tooltip-position": "left"
    }
  }, [_c('v-list-tile-action', [_c('c-icon-fa', {
    attrs: {
      "icon": _vm.$icons.application_reporting,
      "icon-color": "var(--v-primary-base)"
    }
  })], 1), _c('v-list-tile-title', [_vm._v("Readiness report")])], 1) : _vm._e()], 1)], 1)], 1)], 1)], 1)]) : _vm._e()]), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [!_vm.computedId ? _c('c-progress') : _vm._e()], 1), _vm.showAhd && _vm.localEntity && _vm.localEntity.inventory ? _c('ModalAppHardwareDisplay', {
    attrs: {
      "id": _vm.localEntity.inventory.itemInstanceId,
      "inventoryId": _vm.localEntity.inventory.id
    },
    on: {
      "modal-close": function modalClose($event) {
        _vm.showAhd = false;
      }
    }
  }) : _vm._e(), _vm.showModalAssemblyEdit ? _c('ModalAssemblyEdit', {
    key: _vm.computedId,
    attrs: {
      "id": _vm.computedId,
      "number-of-change-sets-prop": _vm.numberOfChangeSetsProp
    },
    on: {
      "modal-close": _vm.onCloseOfModalAssemblyEdit,
      "refresh-parent": _vm.onRefreshParent,
      "refresh-self": _vm.onRefreshSelf
    }
  }) : _vm._e(), _vm.showModalAssemblyUninstall ? _c('ModalAssemblyUninstall', {
    key: _vm.computedId,
    attrs: {
      "id": _vm.computedId,
      "number-of-change-sets": _vm.numberOfChangeSetsProp,
      "parent-inventory": _vm.localEntity.inventory,
      "parents-child-working-assemblies": _vm.myChildWorkingAssemblies
    },
    on: {
      "modal-close": _vm.onCloseOfRemoveAssemblyModal,
      "refresh-self": _vm.onRefreshSelf
    }
  }) : _vm._e(), _vm.showModalAssemblyCreate ? _c('ModalAssemblyCreate', {
    key: _vm.computedId,
    attrs: {
      "id": _vm.computedId,
      "parent-inventory": _vm.localEntity.inventory,
      "parents-child-working-assemblies": _vm.myChildWorkingAssemblies
    },
    on: {
      "modal-close": _vm.onCloseOfModalAssemblyCreate,
      "refresh-self": _vm.onRefreshSelf
    }
  }) : _vm._e(), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.shouldShowChildContainer ? _c('div', {
    staticClass: "working_assembly_mini_child_container"
  }, _vm._l(_vm.myChildren, function (childWorkingAssemblyId) {
    return _c('MiniAssembly', {
      key: childWorkingAssemblyId,
      staticClass: "ml-3 mini_child",
      attrs: {
        "allow-child-creation": _vm.allowChildCreation,
        "allow-child-removal": _vm.allowChildRemoval,
        "allow-edit": _vm.allowEdit,
        "hide-children-id": _vm.hideChildrenId,
        "highlight-connection": _vm.highlightConnection,
        "highlight-id": _vm.highlightId,
        "id": childWorkingAssemblyId,
        "show-as-built": _vm.showAsBuilt,
        "show-child-working-assemblies": _vm.showChildWorkingAssemblies,
        "show-children": childWorkingAssemblyId !== _vm.hideChildrenId,
        "show-description": _vm.showDescription,
        "show-qty": _vm.showQty,
        "show-sequence": _vm.showSequence,
        "show-serial": _vm.showSerial
      },
      on: {
        "refresh-parent": _vm.onRefreshParent,
        "refresh-self": _vm.onRefreshSelf
      }
    });
  }), 1) : _vm._e()])], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };
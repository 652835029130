import { __decorate } from "tslib";
import gql from 'graphql-tag';
import { Component } from 'vue-property-decorator';
import BaseMini from '~/nasa_ui/base/BaseMini';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { EntityType } from '~/nasa_ui/types';
import { transformPhysicalInventoryAudit } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let MiniPhysicalInventoryAudit = class MiniPhysicalInventoryAudit extends BaseMini {
    localEntity = null;
    entityType = EntityType.PHYSICAL_INVENTORY_AUDIT;
    BASE_FETCH = this.nodeId
        ? gql `
        fragment MiniPhysicalInventoryAudit on PhysicalInventoryAudit {
          building
          closedByUserId
          closedDate
          createdByUserId
          createdDateTime
          id
          itemCount
          nodeId
          sampleSize
          status
          subType

          closedByUser {
            firstName
            nodeId
            id
            lastName
          }
        }

        query MiniPhysicalInventoryAuditFetch($nodeId: ID!) {
          physicalInventoryAudit(nodeId: $nodeId) {
            ...MiniPhysicalInventoryAudit
          }
        }
      `
        : gql `
        fragment MiniPhysicalInventoryAudit on PhysicalInventoryAudit {
          building
          closedByUserId
          closedDate
          createdByUserId
          createdDateTime
          id
          itemCount
          nodeId
          sampleSize
          status
          subType

          closedByUser {
            firstName
            nodeId
            id
            lastName
          }
        }

        query MiniPhysicalInventoryAuditByIdFetch($id: UUID!) {
          physicalInventoryAuditById(id: $id) {
            ...MiniPhysicalInventoryAudit
          }
        }
      `;
    get manageLink() {
        return this.localEntity ? `/hardware/${this.entityType}/manage/${this.localEntity.id}` : '';
    }
    get name() {
        if (!this.localEntity && !this.isFetching) {
            return 'Not found';
        }
        return this.localEntity
            ? transformPhysicalInventoryAudit([this.localEntity])[0]._name || DEFAULT_DASH
            : DEFAULT_DASH;
    }
    get verticalLabelDisplayItems() {
        if (!this.localEntity) {
            return [];
        }
        const items = [];
        const transformed = transformPhysicalInventoryAudit([this.localEntity])[0];
        items.push({
            name: 'Created',
            value: `${transformed._createdDateTime}`
        });
        if (this.localEntity.closedDate) {
            items.push({
                name: 'Closed',
                value: `${transformed._closedDate}`
            });
        }
        if (this.localEntity.sampleSize) {
            items.push({
                name: 'Sample size',
                value: transformed._sampleSize
            });
        }
        if (this.localEntity.itemCount) {
            items.push({
                name: 'Item count',
                value: transformed._itemCount
            });
        }
        return items;
    }
    onFetchUpdate(data) {
        if (data.physicalInventoryAudit || data.physicalInventoryAuditById) {
            this.localEntity = data.physicalInventoryAudit || data.physicalInventoryAuditById;
        }
    }
};
MiniPhysicalInventoryAudit = __decorate([
    Component
], MiniPhysicalInventoryAudit);
export default MiniPhysicalInventoryAudit;

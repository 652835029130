var _a, _b, _c, _d, _e;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { Side } from '~/db_types';
import BaseTreeMini from '~/nasa_ui/base/BaseTreeMini';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { CosmicLocationSlimFragment } from '~/nasa_ui/DAL/location/slim';
import { AlertType, EntityType, Maybe } from '~/nasa_ui/types';
import { IWorkingAssemblyNode } from '~/nasa_ui/utils/AssemblyComparisonUtility';
import { typeDisplay } from '~/nasa_ui/utils/helpers/displays';
let MiniAssembly = class MiniAssembly extends Mixins(BaseTreeMini) {
    clickTimer = null;
    entityType = EntityType.ASSEMBLY;
    localEntity = null;
    localShowChildren = false;
    showAhd = false;
    showModalAssemblyCreate = false;
    showModalAssemblyEdit = false;
    showModalAssemblyUninstall = false;
    assemblyTemplateComparisonMessage;
    assemblyTemplateId;
    isMatch;
    showSerial;
    showLot;
    showQty;
    showSide;
    showSize;
    showDescription;
    showSequence;
    hideChildrenId;
    showAsBuilt;
    showInstalledOn;
    allowChildCreation;
    allowChildRemoval;
    allowEdit;
    showChildWorkingAssemblies;
    showCollapseIcon;
    get assemblyTemplateComparisonMessageAlertType() {
        return this.isMatch ? AlertType.INFO : AlertType.WARNING;
    }
    get assemblyMatchesRoute() {
        return this.localEntity?.id === this.$route.params.id;
    }
    get computedItemInstanceId() {
        return this.localEntity?.inventory?.itemInstanceId;
    }
    get computedInventoryId() {
        return this.localEntity?.inventory?.id || null;
    }
    get computedLinkManageAssembly() {
        return this.computedId ? `/hardware/assemblies/manage/${this.computedId}` : null;
    }
    get computedLinkReportReadiness() {
        return this.computedInventoryId ? `/reporting/readiness/results?inventoryIds=${this.computedInventoryId}` : null;
    }
    get computedLinkReportAssemblyTransactions() {
        return this.computedItemInstanceId
            ? `/reporting/assembly_transactions/results?itemInstanceId=${this.computedItemInstanceId}`
            : null;
    }
    get computedLinkReportHardwareActivity() {
        return this.computedItemInstanceId
            ? `/reporting/hardware_activity/results?itemInstanceId=${this.computedItemInstanceId}`
            : null;
    }
    get computedLinkReportHardwareDates() {
        return this.computedItemInstanceId
            ? `/reporting/hardware_dates/results?itemInstanceId=${this.computedItemInstanceId}`
            : null;
    }
    get computedLinkReportInventoryTransactions() {
        return this.computedItemInstanceId
            ? `/reporting/inventory_transactions/results?itemInstanceId=${this.computedItemInstanceId}`
            : null;
    }
    get numberOfChangeSetsProp() {
        return {
            openChildChangeSets: this.openChangeSets,
            openParentChangeSets: this.numberOfOpenParentChangeSets
        };
    }
    get numberOfOpenParentChangeSets() {
        if (!this.localEntity?.inventory?.childOnChangeSets?.nodes) {
            return [];
        }
        return this.localEntity?.inventory?.childOnChangeSets?.nodes.map((node) => {
            return { _changeSetId: node?.id, _inventoryId: this.localEntity?.parent?.inventory?.id };
        });
    }
    get openChangeSets() {
        if (!this.localEntity?.inventory?.changeSets?.nodes) {
            return [];
        }
        return this.localEntity?.inventory?.changeSets?.nodes.map((node) => {
            return { _changeSetId: node?.id, _inventoryId: this.localEntity?.inventory?.id };
        });
    }
    // if we have an assembly template, show the badge
    get shouldShowAssemblyTemplateMatchBadge() {
        return Boolean(this.assemblyTemplateId);
    }
    get shouldShowSide() {
        return Boolean(this.showSide && this.localEntity?.side !== Side.NONE);
    }
    get shouldShowSize() {
        return Boolean(this.showSize && this.localEntity?.inventory?.size !== 'DEFAULT');
    }
    get shouldShowSequence() {
        return Boolean(this.showSequence);
    }
    get shouldShowInstalledOn() {
        return Boolean(this.showInstalledOn && this.installedOnDisplay !== DEFAULT_DASH);
    }
    get createIcon() {
        return this.$icons['create'];
    }
    get editIcon() {
        return this.$icons['edit'];
    }
    get detailIcon() {
        return this.$icons['eye'];
    }
    get descriptionTitle() {
        // allows for full desciption to show on hover of element
        return this.localEntity?.itemDrawing?.description;
    }
    get hasAnyActions() {
        return this.allowChildCreation;
    }
    get hasChildren() {
        return this.myChildren.length > 0;
    }
    get iconClass() {
        return this.$icons[EntityType.ASSEMBLY];
    }
    get iconForInventory() {
        return this.$icons[EntityType.INVENTORY];
    }
    get asBuiltDisplay() {
        return this.$asBuiltNumberDisplay(this.localEntity?.asBuiltNumber);
    }
    get classDisplay() {
        return typeDisplay(EntityType.INVENTORY, this.localEntity?.inventory?.subType);
    }
    get quantityDisplay() {
        return this.$quantityDisplay(this.localEntity?.inventory?.quantity);
    }
    get serialDisplay() {
        return this.$serialNumberDisplay(this.localEntity?.inventory?.serialNumber);
    }
    get lotDisplay() {
        return this.$lotNumberDisplay(this.localEntity?.inventory?.lotNumber);
    }
    get descriptionDisplay() {
        return this.localEntity?.itemDrawing?.description ?? DEFAULT_DASH;
    }
    get sideDisplay() {
        return this.$sideDisplay(this.localEntity?.side);
    }
    get installedOnDisplay() {
        return this.$sideDisplay(this.localEntity?.installedOn);
    }
    get sizeDisplay() {
        return this.$sizeDisplay(this.localEntity?.inventory?.size);
    }
    get myChildWorkingAssemblies() {
        return this.localEntity?.childWorkingAssemblies?.nodes || [];
    }
    get myChildren() {
        const children = this.localEntity?.childWorkingAssemblies?.nodes || [];
        return this.showChildren ? children.map((workingAssembly) => workingAssembly.id) : [];
    }
    get name() {
        return this.$itemNumberDisplay(this.localEntity);
    }
    get parentAssemblyId() {
        return this.localEntity?.parent?.id;
    }
    get shouldHighlightConnection() {
        if (!this.computedId) {
            return false;
        }
        // highlight logic for working assemblies
        if (this.highlightConnection) {
            const upcasedUserTypedSearch = this.highlightConnection.trim().toLocaleUpperCase();
            const _entity = this.localEntity;
            const matchesDrawingNumber = _entity?.drawingNumber.toLocaleUpperCase().includes(upcasedUserTypedSearch);
            const matchesDescription = _entity?.itemDrawing?.description.toLocaleUpperCase().includes(upcasedUserTypedSearch);
            const matchesAsBuiltNumber = _entity?.asBuiltNumber?.toLocaleUpperCase().includes(upcasedUserTypedSearch);
            const matchesSerialNumber = _entity?.inventory?.serialNumber
                ?.toLocaleUpperCase()
                .includes(upcasedUserTypedSearch);
            const matchesLotNumber = _entity?.inventory?.lotNumber?.toLocaleUpperCase().includes(upcasedUserTypedSearch);
            return (matchesDrawingNumber || matchesDescription || matchesAsBuiltNumber || matchesSerialNumber || matchesLotNumber);
        }
        return false;
    }
    get shouldShowInstallChildIcon() {
        return this.allowChildCreation;
    }
    get shouldShowRemoveChildIcon() {
        return this.allowChildRemoval && !this.isRoot;
    }
    get shouldShowEditChildIcon() {
        return this.allowEdit;
    }
    get shouldShowChildWorkingAssembliesDisplay() {
        return this.myChildWorkingAssemblies.length > 0;
    }
    async executeQuery(id) {
        if (id) {
            this.isFetching = true;
            const resp = await this.$apollo.query({
                query: gql `
          ${CosmicLocationSlimFragment}

          query AssemblyForMiniById($id: UUID!) {
            workingAssemblyById(id: $id) {
              id
              drawingNumber
              installedOn
              sequence
              side
              asBuiltNumber
              nodeId

              itemDrawing {
                description
                nodeId
              }

              inventory {
                assemblyInstallRequiresApproval
                assemblyRemoveRequiresApproval
                id
                itemInstanceId
                lotNumber
                nodeId
                projectCode
                quantity
                serialNumber
                size
                subType
                location {
                  ...CosmicLocationSlim
                }

                changeSets(
                  filter: {
                    subType: { in: [ITEM_INSTANCE_INVENTORY_ASSEMBLY_REMOVE, ITEM_INSTANCE_INVENTORY_ASSEMBLY_INSTALL] }
                    computedStatus: { equalTo: OPEN }
                  }
                ) {
                  nodes {
                    id
                    nodeId
                    subType
                  }
                }
                childOnChangeSets(
                  filter: {
                    subType: { in: [ITEM_INSTANCE_INVENTORY_ASSEMBLY_REMOVE, ITEM_INSTANCE_INVENTORY_ASSEMBLY_INSTALL] }
                    computedStatus: { equalTo: OPEN }
                  }
                ) {
                  nodes {
                    id
                    nodeId
                    subType
                  }
                }
              }

              childWorkingAssemblies(orderBy: SEQUENCE_ASC) {
                nodes {
                  id
                  nodeId
                  drawingNumber
                  sequence
                  side
                  asBuiltNumber
                }
              }

              parent {
                id
                nodeId

                inventory {
                  id
                  nodeId
                }
              }
            }
          }
        `,
                variables: {
                    id
                },
                fetchPolicy: 'cache-first'
            });
            this.isFetching = false;
            this.onFetchUpdate(resp.data);
        }
    }
    onFetchUpdate(data) {
        this.localEntity = data.workingAssemblyById;
    }
    onEntityChange(entity) {
        if (!entity) {
            return;
        }
        // massage shape to fit the mini
        const copy = { ...entity };
        copy.itemDrawing = { description: copy.inventory?.itemDrawingDescription ?? null };
        this.$set(this, 'localEntity', copy);
    }
    // Called from ui
    onClickOfCreateAssembly() {
        if (!this.allowChildCreation) {
            return;
        }
        this.showModalAssemblyCreate = true;
    }
    // Called from ui
    onClickOfRemoveAssembly() {
        if (!this.allowChildRemoval) {
            return;
        }
        this.showModalAssemblyUninstall = true;
    }
    // Called from ui
    onCloseOfModalAssemblyEdit() {
        this.showModalAssemblyEdit = false;
    }
    // Called from ui
    onCloseOfModalAssemblyCreate() {
        this.showModalAssemblyCreate = false;
    }
    // Called from ui
    onCloseOfRemoveAssemblyModal() {
        this.showModalAssemblyUninstall = false;
    }
    // Called from ui
    onDoubleClickOfMiniAssembly() {
        // Timer things for handling single/double click on the same element
        if (this.clickTimer) {
            clearTimeout(this.clickTimer);
        }
        if (!this.allowEdit) {
            return;
        }
        this.showModalAssemblyEdit = true;
    }
    // Called from ui as well as from $listen
    onRefreshSelf() {
        this.emitRefreshParentEvent();
        if (this.computedId) {
            this.executeQuery(this.computedId);
        }
    }
    // Called from ui
    onRefreshParent() {
        if (this.entity) {
            this.emitRefreshParentEvent();
            return;
        }
        if (this.computedId) {
            this.executeQuery(this.computedId);
        }
        this.$message(`reload_${this.parentAssemblyId}`);
    }
    toggleShowAhd() {
        this.showAhd = !this.showAhd;
    }
    updateBasedOnId(id) {
        if (id) {
            this.executeQuery(id);
        }
    }
    emitRefreshParentEvent() { }
    emitRefreshSelfEvent() { }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], MiniAssembly.prototype, "assemblyTemplateComparisonMessage", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_b = typeof Maybe !== "undefined" && Maybe) === "function" ? _b : Object)
], MiniAssembly.prototype, "assemblyTemplateId", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: null
    }),
    __metadata("design:type", typeof (_c = typeof Maybe !== "undefined" && Maybe) === "function" ? _c : Object)
], MiniAssembly.prototype, "isMatch", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true
    }),
    __metadata("design:type", Boolean)
], MiniAssembly.prototype, "showSerial", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true
    }),
    __metadata("design:type", Boolean)
], MiniAssembly.prototype, "showLot", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true
    }),
    __metadata("design:type", Boolean)
], MiniAssembly.prototype, "showQty", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true
    }),
    __metadata("design:type", Boolean)
], MiniAssembly.prototype, "showSide", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true
    }),
    __metadata("design:type", Boolean)
], MiniAssembly.prototype, "showSize", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true
    }),
    __metadata("design:type", Boolean)
], MiniAssembly.prototype, "showDescription", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true
    }),
    __metadata("design:type", Boolean)
], MiniAssembly.prototype, "showSequence", void 0);
__decorate([
    Prop({
        type: String,
        default: ''
    }),
    __metadata("design:type", Object)
], MiniAssembly.prototype, "hideChildrenId", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], MiniAssembly.prototype, "showAsBuilt", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true
    }),
    __metadata("design:type", Boolean)
], MiniAssembly.prototype, "showInstalledOn", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], MiniAssembly.prototype, "allowChildCreation", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], MiniAssembly.prototype, "allowChildRemoval", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], MiniAssembly.prototype, "allowEdit", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true
    }),
    __metadata("design:type", Boolean)
], MiniAssembly.prototype, "showChildWorkingAssemblies", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true
    }),
    __metadata("design:type", Boolean)
], MiniAssembly.prototype, "showCollapseIcon", void 0);
__decorate([
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Promise)
], MiniAssembly.prototype, "executeQuery", null);
__decorate([
    Watch('entity', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_d = typeof IWorkingAssemblyNode !== "undefined" && IWorkingAssemblyNode) === "function" ? _d : Object]),
    __metadata("design:returntype", void 0)
], MiniAssembly.prototype, "onEntityChange", null);
__decorate([
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_e = typeof Maybe !== "undefined" && Maybe) === "function" ? _e : Object]),
    __metadata("design:returntype", void 0)
], MiniAssembly.prototype, "updateBasedOnId", null);
__decorate([
    Emit('refresh-parent'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], MiniAssembly.prototype, "emitRefreshParentEvent", null);
__decorate([
    Emit('refresh-self'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], MiniAssembly.prototype, "emitRefreshSelfEvent", null);
MiniAssembly = __decorate([
    Component
], MiniAssembly);
export default MiniAssembly;

var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { Side } from '~/db_types/swagger_types';
import { EntityType } from '~/nasa_ui/types';
import { parseNodeId } from '~/nasa_ui/utils/helpers/parseNodeId';
let AppHardwareDisplay = class AppHardwareDisplay extends Vue {
    fetchedInventory = null;
    asBuiltNumber;
    drawingNumber;
    hideBorderBottom;
    id; // ItemInstanceId
    inventoryId;
    nodeId;
    side;
    title;
    get computedAsBuiltNumber() {
        if (this.asBuiltNumber) {
            return this.asBuiltNumber;
        }
        const parsedNodeId = this.parsedNodeId;
        if (parsedNodeId?.entityType !== EntityType.ITEM_MASTER) {
            return null;
        }
        return parsedNodeId?.asBuiltNumber ?? null;
    }
    get computedDrawingNumber() {
        if (this.drawingNumber) {
            return this.drawingNumber;
        }
        const parsedNodeId = this.parsedNodeId;
        if (parsedNodeId?.entityType === EntityType.ITEM_MASTER) {
            const parseNodeId = this.parsedNodeId;
            return parseNodeId?.drawingNumber;
        }
        if (parsedNodeId?.entityType !== EntityType.ITEM_DRAWING) {
            return null;
        }
        return parsedNodeId?.drawingNumber ?? null;
    }
    get computedItemInstanceId() {
        if (this.id) {
            return this.id;
        }
        const parsedNodeId = this.parsedNodeId;
        if (parsedNodeId?.entityType === EntityType.INVENTORY) {
            return this.fetchedInventory?.itemInstanceId ?? null;
        }
        if (parsedNodeId?.entityType !== EntityType.ITEM_INSTANCE) {
            return null;
        }
        return parsedNodeId?.id;
    }
    get computedInventoryId() {
        if (this.inventoryId) {
            return this.inventoryId;
        }
        const parsedNodeId = this.parsedNodeId;
        if (parsedNodeId?.entityType !== EntityType.INVENTORY) {
            return null;
        }
        return parsedNodeId?.id;
    }
    get computedSide() {
        if (this.side) {
            return this.side;
        }
        const parsedNodeId = this.parsedNodeId;
        if (parsedNodeId?.entityType !== EntityType.ITEM_MASTER) {
            return null;
        }
        return parsedNodeId?.side;
    }
    get computedTitle() {
        return this.title;
    }
    get parsedNodeId() {
        return this.nodeId ? parseNodeId(this.nodeId) : null;
    }
    get hasComputedTitleOrSlot() {
        return this.hasTitleSlot || Boolean(this.computedTitle);
    }
    get hasTitleSlot() {
        return this.hasSlot('title');
    }
    get isInventory() {
        return Boolean(this.computedInventoryId);
    }
    get isItemDrawing() {
        return Boolean(this.computedDrawingNumber && !this.isItemMaster);
    }
    get isItemInstance() {
        return Boolean(this.computedItemInstanceId && !this.isInventory);
    }
    get isItemMaster() {
        return Boolean(this.computedDrawingNumber && this.computedAsBuiltNumber && this.computedSide);
    }
    hasSlot(name = 'default') {
        return !!this.$slots[name] || !!this.$scopedSlots[name];
    }
    /*
      emit hardaware from display back out
      display might fetch and provide transformed entity
      to reports results page and exports
    */
    onHardwareDataChange() { }
    async onIsInventoryChange() {
        if (!this.isInventory || !this.computedInventoryId) {
            return;
        }
        const resp = await this.$http.get(`/inventory/${this.computedInventoryId}`);
        this.fetchedInventory = resp.data;
    }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], AppHardwareDisplay.prototype, "asBuiltNumber", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], AppHardwareDisplay.prototype, "drawingNumber", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], AppHardwareDisplay.prototype, "hideBorderBottom", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], AppHardwareDisplay.prototype, "id", void 0);
__decorate([
    Prop({
        type: String,
        required: false
    }),
    __metadata("design:type", String)
], AppHardwareDisplay.prototype, "inventoryId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], AppHardwareDisplay.prototype, "nodeId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_a = typeof Side !== "undefined" && Side) === "function" ? _a : Object)
], AppHardwareDisplay.prototype, "side", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], AppHardwareDisplay.prototype, "title", void 0);
__decorate([
    Emit('hardwareDataChange'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppHardwareDisplay.prototype, "onHardwareDataChange", null);
__decorate([
    Watch('isInventory', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], AppHardwareDisplay.prototype, "onIsInventoryChange", null);
AppHardwareDisplay = __decorate([
    Component
], AppHardwareDisplay);
export default AppHardwareDisplay;

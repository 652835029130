var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "mini_item_drawing"
  }, [_vm.title ? _c('AppHeader', {
    attrs: {
      "size": "mini"
    }
  }, [_vm._v(_vm._s(_vm.title))]) : _vm._e(), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.transformedSelectedEntity ? _c('div', {
    staticClass: "entity-mini item_drawings"
  }, [_c('c-icon-fa', {
    staticClass: "entity-mini-icon",
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.ITEM_DRAWING],
      "icon-size": "32px",
      "icon-color": "white"
    }
  }), _c('div', {
    staticClass: "entity-mini-name text-truncate white--text"
  }, [_c('div', {
    staticClass: "mini-name text-truncate monospace_font display-1"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._itemNumber) + " ")]), _c('small', {
    staticClass: "caption pl-1"
  }, [_vm._v(_vm._s(_vm.transformedSelectedEntity._description))])]), _c('div', {
    staticClass: "vertical-display-items white--text",
    class: {
      is_archived: _vm.transformedSelectedEntity.isArchived
    }
  }, [_vm._l(_vm.verticalLabelDisplayItems, function (verticalDisplayItem) {
    return _c('div', {
      key: verticalDisplayItem.name,
      staticClass: "vertical-display-item text-truncate",
      class: verticalDisplayItem.class,
      style: verticalDisplayItem.style,
      attrs: {
        "title": verticalDisplayItem.title || verticalDisplayItem.value || ''
      }
    }, [_c('div', {
      staticClass: "mb-1 text-truncate"
    }, [_vm._v(_vm._s(verticalDisplayItem.name))]), _c('div', {
      staticClass: "text-truncate vertical-display-item-value"
    }, [_vm._v(_vm._s(verticalDisplayItem.value || '-'))])]);
  }), _c('div', {
    staticClass: "vertical-display-item init_status white--text"
  }, [_c('div', {
    staticClass: "mb-1"
  }, [_vm._v("Init status")]), _c('c-icon-fa', {
    attrs: {
      "icon-size": "16px",
      "icon": _vm.transformedSelectedEntity._initializationStatusIconOnly,
      "icon-color": "#fff",
      "tooltip": _vm.transformedSelectedEntity._initializationStatusIconTitle
    }
  })], 1), _vm.transformedSelectedEntity.isArchived ? _c('div', {
    staticClass: "vertical-display-item text-xs-center"
  }, [_c('c-icon-fa', {
    attrs: {
      "tooltip": "This Item Drawing has been archived.",
      "icon-color": "white",
      "icon": _vm.$icons.archived
    }
  })], 1) : _vm._e()], 2), _c('div', {
    staticClass: "mini_actions"
  }, [_vm.transformedSelectedEntity && _vm.showInfoIcon ? _c('ButtonMiniActionInfo', {
    staticClass: "mr-2",
    attrs: {
      "icon-color": "white"
    },
    on: {
      "click": _vm.emitInfoClicked
    }
  }) : _vm._e(), _vm.showManageLink ? _c('ButtonMiniActionManage', {
    staticClass: "mr-2",
    attrs: {
      "href": _vm.transformedSelectedEntity._urlManage,
      "is-link": "",
      "icon-color": "white"
    }
  }) : _vm._e(), _vm.shouldShowInlineClearIcon ? _c('ButtonMiniAction', {
    attrs: {
      "icon": _vm.$icons.clear
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.onClickOfInlineClear.apply(null, arguments);
      }
    }
  }) : _vm._e()], 1)], 1) : _vm._e()]), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.isFetching ? _c('c-progress') : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
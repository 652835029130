var _a, _b;
import { __decorate, __metadata } from "tslib";
import { add } from 'date-fns';
import { DebounceAll } from 'lodash-decorators';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import NewBaseHardwareDisplay from '~/nasa_ui/base/NewBaseHardwareDisplay';
import { EntityType } from '~/nasa_ui/types';
import { sortObjectNumericallyBy } from '~/nasa_ui/utils/helpers/sortObjectNumericallyBy';
import { transformItemDrawingResponseWithRefs, transformItemInstanceResponses, transformItemMasterResponses } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let AppItemDrawingDisplay = class AppItemDrawingDisplay extends Mixins(NewBaseHardwareDisplay) {
    selectedEntity = null;
    HEIGHT_OF_ITEM = '27px';
    drawingNumber;
    entity;
    get computedDrawingNumber() {
        return this.drawingNumber || this.selectedEntity?.drawingNumber;
    }
    get changelogHref() {
        const from = this.$dateDisplay(add(new Date(), { years: -1 }));
        const to = this.$dateDisplay(new Date());
        if (!this.computedDrawingNumber) {
            return null;
        }
        return `/reporting/changelog/results?from=${from}&to=${to}&entityType=${EntityType.ITEM_DRAWING}&drawingNumber=${encodeURIComponent(this.computedDrawingNumber)}&ahd=true`;
    }
    get hasItemInstances() {
        return this.transformedItemInstances.length > 0;
    }
    get hasItemMasters() {
        return this.transformedItemMasters.length > 0;
    }
    get itemInstances() {
        return this.searchHardwareResults?.itemInstances.results || [];
    }
    get itemMasters() {
        return this.searchHardwareResults?.itemMasters.results || [];
    }
    get shouldShowItemDrawingDisplay() {
        return Boolean(this.selectedEntity && this.links && this.searchHardwareResults);
    }
    get transformedItemInstances() {
        return transformItemInstanceResponses(this.itemInstances).sort(sortObjectNumericallyBy('asBuiltNumber'));
    }
    get transformedItemMasters() {
        return transformItemMasterResponses(this.itemMasters).sort(sortObjectNumericallyBy('asBuiltNumber'));
    }
    get transformedSelectedEntity() {
        // if youre already transformed... return that
        if (this.selectedEntity && this.selectedEntity.hasOwnProperty('_itemClasses')) {
            return this.selectedEntity;
        }
        return this.selectedEntity ? transformItemDrawingResponseWithRefs([this.selectedEntity])[0] : null;
    }
    created() {
        this.$listen(`reload_${this.computedDrawingNumber}`, this.fetchTheShitYouNeed);
    }
    fetchTheShitYouNeed() {
        if (this.computedDrawingNumber) {
            this.fetchEntity(this.computedDrawingNumber);
        }
        this.fetchLinks();
        this.fetchHardware();
    }
    async fetchEntity(drawingNumber) {
        if (!drawingNumber) {
            return;
        }
        try {
            this.isFetching = true;
            const resp = await this.$http.get(`/hardware/item-drawings/${encodeURIComponent(drawingNumber)}`);
            this.selectedEntity = resp.data;
            this.isFetching = false;
        }
        catch (err) {
            this.$errorUtility({
                err
            });
            this.isFetching = false;
        }
    }
    async fetchLinks() {
        if (!this.computedDrawingNumber) {
            return;
        }
        try {
            const params = {
                drawingNumber: this.computedDrawingNumber,
                take: -1
            };
            const respLinks = await this.$http.get(`/links`, {
                params
            });
            this.links = respLinks.data.results || [];
        }
        catch (err) {
            this.$errorUtility({
                err
            });
        }
    }
    async fetchHardware() {
        if (!this.computedDrawingNumber) {
            return;
        }
        try {
            this.isFetchingHardware = true;
            const params = {
                drawingNumber: [this.computedDrawingNumber],
                includeDrawings: false,
                includeInventory: false,
                take: -1
            };
            const resp = await this.$http.get(`/hardware`, {
                params
            });
            this.searchHardwareResults = resp.data;
            this.isFetchingHardware = false;
        }
        catch (err) {
            console.log('err', err);
            this.isFetchingHardware = false;
            this.$errorUtility({
                err
            });
        }
    }
    onEntityChange() {
        if (this.drawingNumber && this.entity) {
            console.log('Shouldnt set both drawingNumber and entity');
            return;
        }
        if (!this.entity) {
            return;
        }
        this.selectedEntity = { ...this.entity };
    }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], AppItemDrawingDisplay.prototype, "drawingNumber", void 0);
__decorate([
    Prop({
        required: false,
        type: Object
    }),
    __metadata("design:type", Object)
], AppItemDrawingDisplay.prototype, "entity", void 0);
__decorate([
    Watch('drawingNumber', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", Promise)
], AppItemDrawingDisplay.prototype, "fetchEntity", null);
__decorate([
    DebounceAll(20),
    Watch('computedDrawingNumber', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], AppItemDrawingDisplay.prototype, "fetchLinks", null);
__decorate([
    DebounceAll(20),
    Watch('computedDrawingNumber', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], AppItemDrawingDisplay.prototype, "fetchHardware", null);
__decorate([
    Watch('entity', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppItemDrawingDisplay.prototype, "onEntityChange", null);
AppItemDrawingDisplay = __decorate([
    Component
], AppItemDrawingDisplay);
export default AppItemDrawingDisplay;

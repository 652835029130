var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { Side } from '~/db_types';
import BaseVue from '~/nasa_ui/base/BaseVue';
import { DB_DEFAULT } from '~/nasa_ui/constants';
import { SideDisplay } from '~/nasa_ui/types';
export const computeSingleLineDisplayPartString = (pr) => {
    return `${pr.drawingNumber}${pr.asBuiltNumber !== DB_DEFAULT ? pr.asBuiltNumber : ''}${pr.side !== Side.NONE ? pr.side : ''}${pr.size ? pr.size : ''}${pr.serialNumber ? pr.serialNumber : ''}${pr.lotNumber ? pr.lotNumber : ''}`;
};
let SingleLineDisplayPart = class SingleLineDisplayPart extends Mixins(BaseVue) {
    DB_DEFAULT = DB_DEFAULT;
    Side = Side;
    SideDisplay = SideDisplay;
    drawingNumber;
    asBuiltNumber;
    side;
    size;
    lotNumber;
    serialNumber;
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], SingleLineDisplayPart.prototype, "drawingNumber", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], SingleLineDisplayPart.prototype, "asBuiltNumber", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_a = typeof Side !== "undefined" && Side) === "function" ? _a : Object)
], SingleLineDisplayPart.prototype, "side", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], SingleLineDisplayPart.prototype, "size", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], SingleLineDisplayPart.prototype, "lotNumber", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], SingleLineDisplayPart.prototype, "serialNumber", void 0);
SingleLineDisplayPart = __decorate([
    Component
], SingleLineDisplayPart);
export default SingleLineDisplayPart;

import { RiskCategory, RiskClosureType, RiskMitigationTeam } from '~/db_types/swagger_types';
export const MitigationTeamDisplay = new Map([
    [RiskMitigationTeam.ENGINEERING, 'Engineering'],
    [RiskMitigationTeam.OPERATIONS, 'Operations'],
    [RiskMitigationTeam.PROJECT_MANAGEMENT, 'Project Management']
]);
export const RiskCategoryDisplay = new Map([
    [RiskCategory.MISSION_SUCCESS, 'Mission success'],
    [RiskCategory.PROGRAMMATIC, 'Programmatic'],
    [RiskCategory.PROGRAM_MANAGEMENT, 'Program management'],
    [RiskCategory.RISK_RECOVERY_COST, 'Risk recovery cost'],
    [RiskCategory.SAFETY, 'Safety'],
    [RiskCategory.SUPPORTABILITY, 'Supportability'],
    [RiskCategory.SUPPORTABILITY_SCHEDULE, 'Supportability schedule'],
    [RiskCategory.SUPPORTABILITY_COST, 'Supportability cost'],
    [RiskCategory.TECHNICAL, 'Technical']
]);
export const RiskClosureTypeDisplay = new Map([
    [RiskClosureType.ACCEPTED_INFORMED, 'Accepted (Informed)'],
    [RiskClosureType.ACCEPTED_TRANSFER, 'Accepted (Transfer)'],
    [RiskClosureType.CLOSED_CHANGE_IN_SCOPE, 'Closed (Change in scope)'],
    [RiskClosureType.CLOSED_EXPIRED, 'Closed (Expired)'],
    [RiskClosureType.CLOSED_MATERIALIZED, 'Closed (Materialized)'],
    [RiskClosureType.CLOSED_MITIGATED, 'Closed (Mitigated)'],
    [RiskClosureType.CLOSED_WATCH_LIST, 'Closed (Watch list)']
]);
export var WorkStatus;
(function (WorkStatus) {
    WorkStatus["ACTIVE"] = "ACTIVE";
    WorkStatus["WATCHED"] = "WATCHED";
})(WorkStatus || (WorkStatus = {}));
export const WorkStatusTypeDisplay = new Map([
    [WorkStatus.ACTIVE, 'Active'],
    [WorkStatus.WATCHED, 'Watched']
]);

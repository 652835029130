var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppTableExpandable', {
    attrs: {
      "headers": _vm.computedTableHeaders,
      "items": _vm.items,
      "rows-per-page-items": _vm.rowsPerPageItems,
      "color": "movement",
      "icon": "fad fa-shipping-fast fa-flip-horizontal",
      "item-key": "inventoryId",
      "show-search-input": "",
      "title": "In transit"
    },
    on: {
      "expandableRowClick": _vm.handleRowClick
    }
  }, [_c('template', {
    slot: "expanded-row"
  }, [_vm.selectedRow && _vm.selectedRowMatchesContext ? _c('AppHardwareDisplay', {
    staticClass: "mb-2",
    attrs: {
      "id": _vm.selectedRow.itemInstanceId
    }
  }) : _vm._e(), _vm.selectedRow && !_vm.selectedRowMatchesContext ? _c('AppHardwareDisplay', {
    staticClass: "mb-2",
    attrs: {
      "cross-context-inventory-id": _vm.selectedRow.inventoryId
    }
  }) : _vm._e(), _vm.link && _vm.selectedRow ? _c('ButtonGeneric', {
    staticClass: "ml-0",
    attrs: {
      "color": "movement",
      "href": _vm.link,
      "dark": ""
    }
  }, [_c('c-icon-fa', {
    staticClass: "pr-2",
    attrs: {
      "slot": "icon",
      "flip-horizontal": "",
      "icon": _vm.$icons.movement,
      "icon-color": "white"
    },
    slot: "icon"
  }), _vm.selectedRowMatchesContext ? _c('span', [_vm._v("Receive from " + _vm._s(_vm.selectedRow._issuedFromOrg))]) : _c('span', [_vm._v("Receive from " + _vm._s(_vm.selectedRow.issueDestinationOrganizationCode))])], 1) : _vm._e()], 1)], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };
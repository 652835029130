var _a, _b, _c, _d, _e, _f;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { ChangeSetStatus, Maybe } from '~/db_types';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import ReadOnlyMixin from '~/nasa_ui/mixins/ReadOnlyMixin';
import { ChangeSetStatusDisplay, CosmicDocumentStatusDisplay, CosmicDocumentStatusTooltipDisplay } from '~/nasa_ui/types';
import { CosmicIcons } from '~/nasa_ui/utils/ui/CosmicIcons';
/**
 * AVAILABLE PROPS:
 *  disabled - false - greys out fields and its appearance is washed out
 *  entity - object of things you want to display (not recommended)
 *  expandable - true - vuetify expansion panel expand
 *  force-open - false - expand the panel
 *  hide-actions - false - this hides the section of buttons at the bottom expanded panel
 *  inline-clear- false - allow the user to clear selection of this mini with an icon on the far right
 *  node-id - ID!
 *  readOnly - false - like disabled but not washed out
 *  show-manage-link - true - should we display the standard "Manage ____" button
 *  show-refresh|reload-icon - false - should we display the refresh icon
 *  title - string - display a title
 */
let BaseMini = class BaseMini extends Mixins(ReadOnlyMixin, BaseCosmic) {
    BASE_FETCH;
    entityType;
    inlineClearIcon = CosmicIcons['strong_close'];
    isFetching = false;
    localEntity = null;
    manageIcon = CosmicIcons['part_tool'];
    refreshIcon = CosmicIcons['refresh'];
    //#region Computed
    get color() {
        return this.entityType ? this.entityType : 'default';
    }
    get computedId() {
        return this.id || this.$get(this.localEntity, 'id');
    }
    get computedNodeId() {
        return this.nodeId || this.$get(this.localEntity, 'nodeId');
    }
    get computedStatusText() {
        return CosmicDocumentStatusDisplay.get(this.localEntity?.computedStatus);
    }
    get computedStatusTooltipText() {
        return CosmicDocumentStatusTooltipDisplay.get(this.localEntity?.computedStatus);
    }
    get computedTitle() {
        return this.title;
    }
    get iconClass() {
        const subTypeIcon = CosmicIcons[this.subType];
        const defaultIcon = this.entityType ? this.entityType.toString() : 'fa-question';
        if (!this.subType || subTypeIcon === undefined) {
            return CosmicIcons[defaultIcon];
        }
        return subTypeIcon;
    }
    get hasLocalEntity() {
        return this.localEntity !== null && this.localEntity !== undefined;
    }
    get hasTitleSlot() {
        return this.hasSlot('title');
    }
    get hasComputedTitleOrSlot() {
        return Boolean(this.hasTitleSlot || this.computedTitle);
    }
    // cDocs status
    get statusDisplay() {
        switch (this.localEntity?.initializationStatus) {
            case null:
                return {
                    text: 'Open',
                    tooltip: 'This Document has NOT been initialized and can be directly saved without revision control.'
                };
            case ChangeSetStatus.OPEN:
                return {
                    text: 'Pending approval',
                    tooltip: 'Document Initialization is pending. No changes can be made until Initialization is approved.'
                };
            case ChangeSetStatus.APPROVED:
                return {
                    text: ChangeSetStatusDisplay.get(ChangeSetStatus.APPROVED),
                    tooltip: 'Initialization status: Approved with revision control.'
                };
            case ChangeSetStatus.REJECTED:
                return {
                    text: ChangeSetStatusDisplay.get(ChangeSetStatus.REJECTED),
                    tooltip: 'This Document has been rejected. If you would like to re-open the Document, click Reopen For Edits located on the manage page.'
                };
            default:
                return {
                    text: DEFAULT_DASH,
                    tooltip: 'Unknown status'
                };
        }
    }
    get subType() {
        return this.localEntity?.subType || null;
    }
    get subNameText() {
        return null;
    }
    get manageLinkTitle() {
        return this.entityTypeDisplay ? `Manage metadata for ${this.entityTypeDisplay}` : '';
    }
    get manageLink() {
        return '';
    }
    get shouldShowManageLink() {
        return this.showManageLink && Boolean(this.computedNodeId || this.computedId);
    }
    get hasAnyActions() {
        return this.shouldShowManageLink || this.shouldShowInlineClearIcon;
    }
    get name() {
        throw new Error('Implement name getter');
    }
    get entityTypeDisplay() {
        if (!this.entityType) {
            return '';
        }
        return this.$typeDisplay(this.entityType, this.subType);
    }
    get shouldShowInlineClearIcon() {
        return this.inlineClear && !this.readOnly;
    }
    //#endregion
    //#region Props
    drawingNumberForMini;
    asBuiltNumberForMini;
    code;
    sideForMini;
    groupCodeForMini;
    disabled;
    entity;
    ignoreReload;
    inlineClear;
    id;
    nodeId;
    showEntityTypeIcon;
    showManageLink;
    title;
    //#endregion
    created() {
        if (!this.ignoreReload) {
            this.$listen('reload', this.refreshData);
            this.$listen('reload_mini', this.refreshData);
        }
    }
    /**
     * Executes the BASE_FETCH query.
     *
     * @param nodeIdDeprecated Passing an argument to this method is deprecated.
     * It will automatically choose a UUID or a nodeId depending on which is
     * available.
     * @returns
     */
    async executeQuery(nodeIdDeprecated) {
        if (nodeIdDeprecated) {
            console.warn('Passing a nodeId to executeQuery() is deprecated. The method will read from this.id or this.nodeId.');
        }
        const id = this.id || this.computedId;
        const nodeId = this.nodeId || this.computedNodeId;
        const drawingNumber = this.drawingNumberForMini;
        const asBuiltNumber = this.asBuiltNumberForMini;
        const side = this.sideForMini;
        const code = this.groupCodeForMini || this.code;
        if (!id && !nodeId && !drawingNumber && !code) {
            return;
        }
        this.isFetching = true;
        let variables = {};
        // we don't know whether a given query will want a UUID or a nodeId, so add
        // one or both—whatever’s available.
        if (nodeId) {
            variables = { nodeId };
        }
        if (id) {
            variables = { ...variables, id };
        }
        if (drawingNumber) {
            variables = { ...variables, drawingNumber };
        }
        if (asBuiltNumber) {
            variables = { ...variables, asBuiltNumber };
        }
        if (side) {
            variables = { ...variables, side };
        }
        if (code) {
            variables = { ...variables, code };
        }
        try {
            const { data } = await this.$apollo.query({
                query: this.BASE_FETCH,
                variables
            });
            this.isFetching = false;
            this.onFetchUpdate(data);
        }
        catch (error) {
            this.isFetching = false;
        }
    }
    /**
     * This is the function called that contains the data
     *
     * Intended to be overrode in the implementing class
     *
     * @param data data received from the update fnc of apollo
     */
    onFetchUpdate(data) { }
    refreshData() {
        // Make it look slow :)
        setTimeout(() => {
            this.executeQuery();
        }, 250);
    }
    onClickOfInlineClear() {
        return this.computedNodeId || this.computedId;
    }
    onTrigger(thingThatTriggeredIt) {
        if (!thingThatTriggeredIt) {
            return;
        }
        this.executeQuery();
    }
    onEntityChange(entity) {
        if (!entity) {
            return;
        }
        this.$set(this, 'localEntity', entity);
    }
    hasSlot(name = 'default') {
        return !!this.$slots[name] || !!this.$scopedSlots[name];
    }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], BaseMini.prototype, "drawingNumberForMini", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_b = typeof Maybe !== "undefined" && Maybe) === "function" ? _b : Object)
], BaseMini.prototype, "asBuiltNumberForMini", void 0);
__decorate([
    Prop({
        type: [String, Number]
    }),
    __metadata("design:type", Object)
], BaseMini.prototype, "code", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_e = typeof Maybe !== "undefined" && Maybe) === "function" ? _e : Object)
], BaseMini.prototype, "sideForMini", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_f = typeof Maybe !== "undefined" && Maybe) === "function" ? _f : Object)
], BaseMini.prototype, "groupCodeForMini", void 0);
__decorate([
    Prop({ default: false, type: Boolean }),
    __metadata("design:type", Boolean)
], BaseMini.prototype, "disabled", void 0);
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", Object)
], BaseMini.prototype, "entity", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], BaseMini.prototype, "ignoreReload", void 0);
__decorate([
    Prop({ default: false, type: Boolean }),
    __metadata("design:type", Boolean)
], BaseMini.prototype, "inlineClear", void 0);
__decorate([
    Prop({
        type: [String, Number]
    }),
    __metadata("design:type", Object)
], BaseMini.prototype, "id", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], BaseMini.prototype, "nodeId", void 0);
__decorate([
    Prop({ default: true, type: Boolean }),
    __metadata("design:type", Boolean)
], BaseMini.prototype, "showEntityTypeIcon", void 0);
__decorate([
    Prop({ default: true, type: Boolean }),
    __metadata("design:type", Boolean)
], BaseMini.prototype, "showManageLink", void 0);
__decorate([
    Prop({ type: String }),
    __metadata("design:type", String)
], BaseMini.prototype, "title", void 0);
__decorate([
    Emit('inlineClear'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], BaseMini.prototype, "onClickOfInlineClear", null);
__decorate([
    Watch('code', { immediate: true }),
    Watch('id', { immediate: true }),
    Watch('nodeId', { immediate: true }),
    Watch('numberForMini', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], BaseMini.prototype, "onTrigger", null);
__decorate([
    Watch('entity', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], BaseMini.prototype, "onEntityChange", null);
BaseMini = __decorate([
    Component
], BaseMini);
export default BaseMini;

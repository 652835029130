import Vue from 'vue';
import VueRouter from 'vue-router';
import { ActivitiesSharedRoutes, AssemblyTemplateSharedRoutes, ContactSharedRoutes, HardwareListSharedRoutes, HardwareListTemplateSharedRoutes, LocationSharedRoutes, NonnominalSharedRoutes, OrganizationSharedRoutes, UserSharedRoutes } from '~/nasa_ui/router';
import { AdminSharedRoutes } from '~/nasa_ui/router/admin';
import { ChangeSetSharedRoutes } from '~/nasa_ui/router/changeSets';
import { getBlurbAboutCreate, getBlurbAboutManage, getBlurbAboutSearch } from '~/nasa_ui/router/utils';
import { EntityType, EntityTypeSingularDisplay, UserPermission } from '~/nasa_ui/types';
import { markdownToHtml } from '~/nasa_ui/utils/helpers/markdownToHtml';
import { RouterLogUtility } from '~/utils/RouterLogUtility';
import HomeView from '~/views/HomeView/HomeView.vue';
Vue.use(VueRouter);
const DocumentRoutes = [
    {
        path: `/${EntityType.DOCUMENT}`,
        redirect: {
            name: 'TrackingDocumentOmniboxSearchView'
        },
        meta: {
            displayName: `Tracking ${EntityTypeSingularDisplay.get(EntityType.DOCUMENT)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.DOCUMENT)),
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.DOCUMENT}/create`,
        name: 'TrackingDocumentCreateView',
        component: () => import(
        /* webpackChunkName: "Document" */ '../nasa_ui/views/TrackingDocumentCreateView/TrackingDocumentCreateView.vue'),
        meta: {
            displayName: 'Tracking Documents create',
            description: getBlurbAboutCreate(EntityTypeSingularDisplay.get(EntityType.DOCUMENT)),
            permissions: [UserPermission.DOCUMENT_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.DOCUMENT}/manage/:id`,
        name: 'DocumentsManageView',
        component: () => import(/* webpackChunkName: "Document" */ '../views/DocumentsManageView/DocumentsManageView.vue'),
        meta: {
            displayName: 'Tracking Documents manage',
            description: getBlurbAboutManage(EntityTypeSingularDisplay.get(EntityType.DOCUMENT)),
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.DOCUMENT}/search`,
        name: 'TrackingDocumentOmniboxSearchView',
        component: () => import(
        /* webpackChunkName: "Document" */ '../nasa_ui/views/TrackingDocumentOmniboxSearchView/TrackingDocumentOmniboxSearchView.vue'),
        meta: {
            displayName: `Tracking ${EntityTypeSingularDisplay.get(EntityType.DOCUMENT)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.DOCUMENT)),
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    }
];
const EventRoutes = [
    {
        path: `/${EntityType.EVENT}`,
        redirect: {
            name: 'EventsSearchView'
        },
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.EVENT)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.EVENT)),
            permissions: [UserPermission.EVENT_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.EVENT}/create`,
        name: 'EventsCreateView',
        component: () => import(/* webpackChunkName: "Event" */ '../views/EventsCreateView/EventsCreateView.vue'),
        meta: {
            displayName: 'Events create',
            description: getBlurbAboutCreate(EntityTypeSingularDisplay.get(EntityType.EVENT)),
            permissions: [UserPermission.EVENT_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.EVENT}/manage/:id`,
        name: 'EventsManageView',
        component: () => import(/* webpackChunkName: "Event" */ '../views/EventsManageView/EventsManageView.vue'),
        meta: {
            displayName: 'Events manage',
            description: getBlurbAboutManage(EntityTypeSingularDisplay.get(EntityType.EVENT)),
            permissions: [UserPermission.EVENT_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.EVENT}/search`,
        name: 'EventsSearchView',
        component: () => import(/* webpackChunkName: "Event" */ '../views/EventsSearchView/EventsSearchView.vue'),
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.EVENT)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.EVENT)),
            permissions: [UserPermission.EVENT_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    }
];
const HardwareRoutes = [
    {
        path: `/hardware`,
        redirect: {
            name: 'HardwareSearchView'
        },
        meta: {
            displayName: 'Hardware search',
            description: `A search screen with generic search fields for searching for many different levels of Hardware.`,
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/hardware/search`,
        name: 'HardwareSearchView',
        component: () => import(/* webpackChunkName: "Hardware" */ '../views/HardwareSearchView/HardwareSearchView.vue'),
        meta: {
            displayName: 'Hardware search',
            description: `A search screen with generic search fields for searching for many different levels of Hardware.`,
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/hardware/results`,
        component: () => import(/* webpackChunkName: "Hardware" */ '../views/HardwareSearchResultsView/HardwareSearchResultsView.vue'),
        meta: {
            displayName: 'Hardware search results',
            description: `Executes a generic hardware search and displays the results broken down by hardware level.`,
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/hardware/${EntityType.ITEM_DRAWING}/create`,
        name: 'ItemDrawingsCreateView',
        component: () => import(/* webpackChunkName: "Hardware" */ '../views/ItemDrawingsCreateView/ItemDrawingsCreateView.vue'),
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.ITEM_DRAWING)} create`,
            description: getBlurbAboutCreate(EntityTypeSingularDisplay.get(EntityType.ITEM_DRAWING)),
            permissions: [UserPermission.ITEM_DRAWING_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/hardware/${EntityType.ITEM_DRAWING}/search`,
        name: 'ItemDrawingsSearchView',
        component: () => import(/* webpackChunkName: "Hardware" */ '../views/ItemDrawingsSearchView/ItemDrawingsSearchView.vue'),
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.ITEM_DRAWING)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.ITEM_DRAWING)),
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/hardware/${EntityType.ITEM_DRAWING}`,
        redirect: {
            name: 'ItemDrawingsSearchView'
        },
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.ITEM_DRAWING)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.ITEM_DRAWING)),
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/hardware/${EntityType.ITEM_DRAWING}/manage`,
        redirect: {
            name: 'ItemDrawingsSearchView'
        },
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.ITEM_DRAWING)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.ITEM_DRAWING)),
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/hardware/${EntityType.ITEM_DRAWING}/manage/:drawingNumber`,
        name: 'ItemDrawingsManageView',
        component: () => import(/* webpackChunkName: "Hardware" */ '../views/ItemDrawingsManageView/ItemDrawingsManageView.vue'),
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.ITEM_DRAWING)} manage`,
            description: getBlurbAboutManage(EntityTypeSingularDisplay.get(EntityType.ITEM_DRAWING)),
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    // Item Master
    {
        path: `/hardware/${EntityType.ITEM_MASTER}/create`,
        name: 'ItemMastersCreateView',
        component: () => import(/* webpackChunkName: "Hardware" */ '../views/ItemMastersCreateView/ItemMastersCreateView.vue'),
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.ITEM_MASTER)} create`,
            description: getBlurbAboutCreate(EntityTypeSingularDisplay.get(EntityType.ITEM_MASTER)),
            permissions: [UserPermission.ITEM_MASTER_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/hardware/${EntityType.ITEM_MASTER}/search`,
        name: 'ItemMastersSearchView',
        component: () => import(/* webpackChunkName: "Hardware" */ '../views/ItemMastersSearchView/ItemMastersSearchView.vue'),
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.ITEM_MASTER)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.ITEM_MASTER)),
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/hardware/${EntityType.ITEM_MASTER}`,
        redirect: {
            name: 'ItemMastersSearchView'
        },
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.ITEM_MASTER)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.ITEM_MASTER)),
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/hardware/${EntityType.ITEM_MASTER}/manage`,
        redirect: {
            name: 'ItemMastersSearchView'
        },
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.ITEM_MASTER)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.ITEM_MASTER)),
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/hardware/${EntityType.ITEM_MASTER}/manage/:drawingNumber/:asBuiltNumber/:side`,
        name: 'ItemMastersManageView',
        component: () => import(/* webpackChunkName: "Hardware" */ '../views/ItemMastersManageView/ItemMastersManageView.vue'),
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.ITEM_MASTER)} manage`,
            description: getBlurbAboutManage(EntityTypeSingularDisplay.get(EntityType.ITEM_MASTER)),
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/hardware/initial_receipt`,
        name: 'InitialReceiptCreateView',
        component: () => import(/* webpackChunkName: "Hardware" */ '../views/InitialReceiptCreateView/InitialReceiptCreateView.vue'),
        meta: {
            displayName: 'Initial receipt',
            description: 'Go here to receive Inventory.',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    // Item Instance/manage
    {
        path: `/hardware/${EntityType.ITEM_INSTANCE}/search`,
        name: 'ItemInstancesSearchView',
        component: () => import(/* webpackChunkName: "Hardware" */ '../views/ItemInstancesSearchView/ItemInstancesSearchView.vue'),
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.ITEM_INSTANCE)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.ITEM_INSTANCE)),
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/hardware/${EntityType.ITEM_INSTANCE}`,
        redirect: {
            name: 'ItemInstancesSearchView'
        },
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.ITEM_INSTANCE)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.ITEM_INSTANCE)),
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    // bardcode
    {
        path: `/hardware/${EntityType.ITEM_INSTANCE}/barcodes`,
        name: 'BarcodesScanningView',
        component: () => import(/* webpackChunkName: "Hardware" */ '../views/BarcodesScanningView/BarcodesScanningView.vue'),
        meta: {
            displayName: `Barcodes scanning`,
            description: 'Scan barcodes to easily find hardware.',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/hardware/${EntityType.ITEM_INSTANCE}/manage`,
        redirect: {
            name: 'ItemInstancesSearchView'
        },
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.ITEM_INSTANCE)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.ITEM_INSTANCE)),
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/hardware/${EntityType.ITEM_INSTANCE}/manage/:id`,
        name: 'ItemInstancesManageView',
        component: () => import(/* webpackChunkName: "Hardware" */ '../views/ItemInstancesManageView/ItemInstancesManageView.vue'),
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.ITEM_INSTANCE)} manage`,
            description: getBlurbAboutManage(EntityTypeSingularDisplay.get(EntityType.ITEM_INSTANCE)),
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/hardware/movement`,
        name: 'InventoryMovementOutView',
        component: () => import(/* webpackChunkName: "Hardware" */ '../views/InventoryMovementOutView/InventoryMovementOutView.vue'),
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.INVENTORY)} movement`,
            description: `This screen allows you to move Inventory around to COSMIC Locations.`,
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/hardware/movement_out`,
        redirect: {
            name: 'InventoryMovementOutView'
        },
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.INVENTORY)} movement out`,
            description: 'Allows you to move Inventory outside your context.',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/hardware/receive_inventory`,
        name: 'ReceiveInventoryView',
        component: () => import(/* webpackChunkName: "Hardware" */ '../views/ReceiveInventoryView/ReceiveInventoryView.vue'),
        meta: {
            displayName: `Receive ${EntityTypeSingularDisplay.get(EntityType.INVENTORY)}`,
            description: `Receive Inventory that was previously issued out.`,
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/hardware/return_inventory`,
        name: 'ReturnInventoryMultipleView',
        component: () => import(/* webpackChunkName: "Hardware" */ '../views/ReturnInventoryMultipleView/ReturnInventoryMultipleView.vue'),
        meta: {
            displayName: `Return ${EntityTypeSingularDisplay.get(EntityType.INVENTORY)}`,
            description: `Return Inventory in bulk that was previously issued to another context.`,
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/hardware/initial_receipt_condensed`,
        name: 'InitialReceiptCondensedCreateView',
        component: () => import(
        /* webpackChunkName: "Hardware" */ '../views/InitialReceiptCondensedCreateView/InitialReceiptCondensedCreateView.vue'),
        meta: {
            displayName: 'Initial receipt',
            description: `Initial receipt is used to add Inventory to a COSMIC Location.`,
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    // ASSEMBLIES
    {
        path: `/hardware/${EntityType.ASSEMBLY}/search`,
        name: 'AssemblySearchView',
        component: () => import(/* webpackChunkName: "Hardware" */ '../views/AssemblySearchView/AssemblySearchView.vue'),
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.ASSEMBLY)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.ASSEMBLY)),
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/hardware/${EntityType.ASSEMBLY}`,
        redirect: {
            name: 'AssemblySearchView'
        },
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.ASSEMBLY)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.ASSEMBLY)),
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/hardware/${EntityType.ASSEMBLY}/manage/:id`,
        name: 'AssemblyManageView',
        component: () => import(/* webpackChunkName: "Hardware" */ '../views/AssemblyManageView/AssemblyManageView.vue'),
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.ASSEMBLY)} manage`,
            description: getBlurbAboutManage(EntityTypeSingularDisplay.get(EntityType.ASSEMBLY)),
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    // Failures
    {
        path: `/hardware/failures/create`,
        name: 'FailuresCreateView',
        component: () => import(/* webpackChunkName: "Hardware" */ '../views/FailuresCreateView/FailuresCreateView.vue'),
        meta: {
            displayName: 'Failures create',
            description: getBlurbAboutCreate('On-Orbit Failure'),
            permissions: [UserPermission.ON_ORBIT_FAILURE_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        // NOTE: Takes a ChangeSet id
        path: `/hardware/failures/manage/:id`,
        name: 'FailureManageView',
        component: () => import(/* webpackChunkName: "Hardware" */ '../views/FailureManageView/FailureManageView.vue'),
        meta: {
            displayName: 'Failures manage',
            description: getBlurbAboutManage('On-Orbit Failure'),
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    // Physical Inv Audit
    {
        path: `/hardware/${EntityType.PHYSICAL_INVENTORY_AUDIT}/create`,
        name: 'PhysicalInventoryAuditsCreateView',
        component: () => import(
        /* webpackChunkName: "Hardware" */ '../views/PhysicalInventoryAuditsCreateView/PhysicalInventoryAuditsCreateView.vue'),
        meta: {
            displayName: 'Physical Inventory Audits create',
            description: getBlurbAboutCreate(EntityTypeSingularDisplay.get(EntityType.PHYSICAL_INVENTORY_AUDIT)),
            permissions: [UserPermission.PHYSICAL_INVENTORY_AUDIT_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/hardware/${EntityType.PHYSICAL_INVENTORY_AUDIT}/manage/:nodeId`,
        name: 'PhysicalInventoryAuditsManageView',
        component: () => import(
        /* webpackChunkName: "Hardware" */ '../views/PhysicalInventoryAuditsManageView/PhysicalInventoryAuditsManageView.vue'),
        meta: {
            displayName: 'Physical Inventory Audits manage',
            description: getBlurbAboutManage(EntityTypeSingularDisplay.get(EntityType.PHYSICAL_INVENTORY_AUDIT)),
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/hardware/${EntityType.PHYSICAL_INVENTORY_AUDIT}/search`,
        name: 'PhysicalInventoryAuditsSearchView',
        component: () => import(
        /* webpackChunkName: "Hardware" */ '../views/PhysicalInventoryAuditsSearchView/PhysicalInventoryAuditsSearchView.vue'),
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.PHYSICAL_INVENTORY_AUDIT)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.PHYSICAL_INVENTORY_AUDIT)),
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/hardware/${EntityType.PHYSICAL_INVENTORY_AUDIT}`,
        redirect: {
            name: 'PhysicalInventoryAuditsSearchView'
        },
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.PHYSICAL_INVENTORY_AUDIT)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.PHYSICAL_INVENTORY_AUDIT)),
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    }
];
export const ReportingRoutes = [
    {
        path: `/reporting`,
        name: 'ReportingSearchView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/ReportingSearchView/ReportingSearchView.vue'),
        meta: {
            displayName: 'Reporting search',
            description: 'Listing of every available COSMIC report.',
            doNotList: true,
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/archive_box_listing`,
        name: 'ArchiveBoxNumberListingReportView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/ArchiveBoxNumberListingReportView/ArchiveBoxNumberListingReportView.vue'),
        meta: {
            color: EntityType.DOCUMENT,
            description: 'Lists all Documents AND Document deviations that have the "archive box number" input filled out.',
            entityRelationships: [EntityType.DOCUMENT],
            displayName: 'Archive box listing',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/archive_box_listing/results`,
        name: 'ArchiveBoxNumberListingReportResultsView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/ArchiveBoxNumberListingReportView/ArchiveBoxNumberListingReportResultsView.vue'),
        meta: {
            color: EntityType.DOCUMENT,
            description: 'Lists all Documents AND Document deviations that have the "archive box number" input filled out.',
            doNotList: true,
            entityRelationships: [EntityType.DOCUMENT],
            displayName: 'Archive box listing',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/pressurized_time`,
        name: 'PressurizedTimeReportView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/PressurizedTimeReportView/PressurizedTimeReportView.vue'),
        meta: {
            color: EntityType.ACTIVITY,
            description: 'Limited Life report that displays all Pressurization Activities matching the provided criteria.',
            entityRelationships: [EntityType.ACTIVITY, EntityType.ITEM_INSTANCE],
            displayName: 'Pressurized time',
            wikiUrl: 'reporting/Pressurized-Time',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/pressurized_time/results`,
        name: 'PressurizedTimeReportResultsView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/PressurizedTimeReportView/PressurizedTimeReportResultsView.vue'),
        meta: {
            color: EntityType.ACTIVITY,
            description: 'Limited Life report that displays all Pressurization Activities matching the provided criteria.',
            doNotList: true,
            entityRelationships: [EntityType.ACTIVITY, EntityType.ITEM_INSTANCE],
            displayName: 'Pressurized time',
            wikiUrl: 'reporting/Pressurized-Time',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/documents_past_due_review`,
        name: 'DocumentsPastDueReportView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/DocumentsPastDueReportView/DocumentsPastDueReportView.vue'),
        meta: {
            color: EntityType.DOCUMENT,
            description: 'Displays all Documents that are pasted due for review.',
            entityRelationships: [EntityType.DOCUMENT],
            displayName: 'Documents past due for review',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/documents_past_due_review/results`,
        name: 'DocumentsPastDueReportResultsView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/DocumentsPastDueReportView/DocumentsPastDueReportResultsView.vue'),
        meta: {
            color: EntityType.DOCUMENT,
            description: 'Displays all Documents that are pasted due for review.',
            doNotList: true,
            entityRelationships: [EntityType.DOCUMENT],
            displayName: 'Documents past due for review',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/drawing_numbers_by_poc`,
        name: 'DrawingNumbersByPocReportView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/DrawingNumbersByPocReportView/DrawingNumbersByPocReportView.vue'),
        meta: {
            color: EntityType.ITEM_DRAWING,
            description: 'Displays all item drawings with a point of contact.',
            entityRelationships: [EntityType.ITEM_DRAWING],
            displayName: 'Drawing Numbers by Point of Contact',
            wikiUrl: 'reporting/Drawing-Numbers-By-PoC',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/drawing_numbers_by_poc/results`,
        name: 'DrawingNumbersByPocReportResultsView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/DrawingNumbersByPocReportView/DrawingNumbersByPocReportResultsView.vue'),
        meta: {
            color: EntityType.ITEM_DRAWING,
            description: 'Displays all item drawings with a point of contact.',
            doNotList: true,
            entityRelationships: [EntityType.ITEM_DRAWING],
            displayName: 'Drawing Numbers by Point of Contact',
            wikiUrl: 'reporting/Drawing-Numbers-By-PoC',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/equipment_control_number`,
        name: 'EquipmentControlNumberReportView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/EquipmentControlNumberReportView/EquipmentControlNumberReportView.vue'),
        meta: {
            color: EntityType.ITEM_INSTANCE,
            entityRelationships: [EntityType.ITEM_INSTANCE, EntityType.LOCATION],
            description: 'This report displays all hardware that contains an Equipment Control Number (ECN) organized by Bin Location.',
            displayName: 'Equipment Control Numbers',
            wikiUrl: 'reporting/Equipment-Control-Numbers',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/equipment_control_number/results`,
        name: 'EquipmentControlNumberReportResultsView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/EquipmentControlNumberReportView/EquipmentControlNumberReportResultsView.vue'),
        meta: {
            color: EntityType.ITEM_INSTANCE,
            entityRelationships: [EntityType.ITEM_INSTANCE, EntityType.LOCATION],
            description: 'This report displays all hardware that contains an Equipment Control Number (ECN) organized by Bin Location.',
            doNotList: true,
            displayName: 'Equipment Control Numbers',
            wikiUrl: 'reporting/Equipment-Control-Numbers',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/document_projected_closure`,
        name: 'DocumentProjectedClosureReportView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/DocumentProjectedClosureReportView/DocumentProjectedClosureReportView.vue'),
        meta: {
            color: EntityType.DOCUMENT,
            entityRelationships: [EntityType.CHANGE_SET, EntityType.DOCUMENT, EntityType.ITEM_INSTANCE],
            description: 'Lists all Documents per line item that expire within the selected date range.',
            doNotList: true,
            displayName: 'Document Projected Closures',
            wikiUrl: 'reporting/Document-Projected-Closures',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/document_projected_closure/results`,
        name: 'DocumentProjectedClosureReportResultsView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/DocumentProjectedClosureReportView/DocumentProjectedClosureReportResultsView.vue'),
        meta: {
            color: EntityType.DOCUMENT,
            entityRelationships: [EntityType.CHANGE_SET, EntityType.DOCUMENT, EntityType.ITEM_INSTANCE],
            description: 'Lists all Documents per line item that expire within the selected date range.',
            doNotList: true,
            displayName: 'Document Projected Closures',
            wikiUrl: 'reporting/Document-Projected-Closures',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/document_release_status`,
        name: 'DocumentReleaseStatusReportView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/DocumentReleaseStatusReportView/DocumentReleaseStatusReportView.vue'),
        meta: {
            color: EntityType.DOCUMENT,
            entityRelationships: [EntityType.DOCUMENT],
            description: 'Documents by release status based on review required interval and last review date.',
            displayName: 'Document Release Status',
            wikiUrl: 'reporting/Document-Release-Status',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/document_release_status/results`,
        name: 'DocumentReleaseStatusReportResultsView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/DocumentReleaseStatusReportView/DocumentReleaseStatusReportResultsView.vue'),
        meta: {
            color: EntityType.DOCUMENT,
            description: 'Documents by release status based on review required interval and last review date.',
            doNotList: true,
            entityRelationships: [EntityType.DOCUMENT],
            displayName: 'Document Release Status',
            wikiUrl: 'reporting/Document-Release-Status',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/40_hr`,
        name: 'FortyHRReportView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/FortyHRReportView/FortyHRReportView.vue'),
        meta: {
            color: EntityType.INVENTORY,
            entityRelationships: [EntityType.INVENTORY, EntityType.ITEM_INSTANCE],
            description: '40h inspection limits for any class Hardware.',
            displayName: '40h Inspection Limits',
            wikiUrl: 'reporting/40h-Inspection-Limits',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/40_hr/results`,
        name: 'FortyHRReportResultsView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/FortyHRReportView/FortyHRReportResultsView.vue'),
        meta: {
            color: EntityType.INVENTORY,
            description: '40h inspection limits for any class Hardware.',
            doNotList: true,
            entityRelationships: [EntityType.INVENTORY, EntityType.ITEM_INSTANCE],
            displayName: '40h Inspection Limits',
            wikiUrl: 'reporting/40h-Inspection-Limits',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/new_40_hr`,
        name: 'NewFortyHRReportView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/FortyHRReportView/NewFortyHRReportView.vue'),
        meta: {
            color: EntityType.INVENTORY,
            description: '40h inspection limits for any class Hardware.',
            entityRelationships: [EntityType.INVENTORY, EntityType.ITEM_INSTANCE],
            displayName: '40h Inspection Limits - New',
            wikiUrl: 'reporting/40h-Inspection-Limits',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/new_40_hr/results`,
        name: 'NewFortyHRReportResultsView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/FortyHRReportView/NewFortyHRReportResultsView.vue'),
        meta: {
            color: EntityType.INVENTORY,
            description: '40h inspection limits for any class Hardware.',
            doNotList: true,
            entityRelationships: [EntityType.INVENTORY, EntityType.ITEM_INSTANCE],
            displayName: '40h Inspection Limits - New',
            wikiUrl: 'reporting/40h-Inspection-Limits',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/active_project_codes`,
        name: 'ActiveProjectCodesReportView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/ActiveProjectCodesReportView/ActiveProjectCodesReportView.vue'),
        meta: {
            color: EntityType.INVENTORY,
            entityRelationships: [EntityType.INVENTORY, EntityType.ITEM_INSTANCE],
            description: 'Lists all inventories that have active project codes.',
            displayName: 'Active Project Codes',
            wikiUrl: 'reporting/Active-Project-Codes',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/active_project_codes/results`,
        name: 'ActiveProjectCodesReportResultsView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/ActiveProjectCodesReportView/ActiveProjectCodesReportResultsView.vue'),
        meta: {
            color: EntityType.INVENTORY,
            doNotList: true,
            entityRelationships: [EntityType.INVENTORY, EntityType.ITEM_INSTANCE],
            description: 'Lists all inventories that have active project codes.',
            displayName: 'Active Project Codes',
            wikiUrl: 'reporting/Active-Project-Codes',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/assembly_comparison`,
        name: 'AssemblyComparisonReportView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/AssemblyComparisonReportView/AssemblyComparisonReportView.vue'),
        meta: {
            color: EntityType.ITEM_INSTANCE,
            entityRelationships: [EntityType.ASSEMBLY, EntityType.ITEM_INSTANCE],
            description: 'Compares Assembly to Template, Assembly to other Assembly or Template to Template.',
            displayName: 'Assembly and Template Comparison',
            wikiUrl: 'reporting/Assembly-and-Template-Comparison',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/assembly_comparison/results`,
        name: 'AssemblyComparisonReportResultsView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/AssemblyComparisonReportView/AssemblyComparisonReportResultsView.vue'),
        meta: {
            color: EntityType.ITEM_INSTANCE,
            entityRelationships: [EntityType.ASSEMBLY, EntityType.ITEM_INSTANCE],
            description: 'Compares Assembly to Template, Assembly to other Assembly or Template to Template.',
            doNotList: true,
            displayName: 'Assembly and Template Comparison',
            wikiUrl: 'reporting/Assembly-and-Template-Comparison',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/assembly_transactions`,
        name: 'AssemblyTransactionReportView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/AssemblyTransactionReportView/AssemblyTransactionReportView.vue'),
        meta: {
            color: EntityType.ITEM_INSTANCE,
            entityRelationships: [EntityType.ASSEMBLY, EntityType.ITEM_INSTANCE],
            description: 'Lists all <b>INSTALL</b> | <b>REMOVAL</b> assembly transactions performed on the selected Item Instances.',
            displayName: 'Assembly Transactions',
            wikiUrl: 'reporting/Assembly-Transactions',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/assembly_transactions/results`,
        name: 'AssemblyTransactionReportResultsView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/AssemblyTransactionReportView/AssemblyTransactionReportResultsView.vue'),
        meta: {
            color: EntityType.ITEM_INSTANCE,
            entityRelationships: [EntityType.ASSEMBLY, EntityType.ITEM_INSTANCE],
            description: 'Lists all <b>INSTALL</b> | <b>REMOVAL</b> assembly transactions performed on the selected Item Instances.',
            doNotList: true,
            displayName: 'Assembly Transactions',
            wikiUrl: 'reporting/Assembly-Transactions',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/calibrations`,
        name: 'CalibrationReportView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/CalibrationReportView/CalibrationReportView.vue'),
        meta: {
            color: EntityType.INVENTORY,
            entityRelationships: [EntityType.INVENTORY, EntityType.ACTIVITY],
            description: 'Lists Inventory with upcoming calibrations.',
            displayName: 'Calibrations',
            wikiUrl: 'reporting/Calibrations',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/calibrations/results`,
        name: 'CalibrationReportResultsView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/CalibrationReportView/CalibrationReportResultsView.vue'),
        meta: {
            color: EntityType.INVENTORY,
            entityRelationships: [EntityType.INVENTORY, EntityType.ACTIVITY],
            description: 'Lists Inventory with upcoming calibrations.',
            doNotList: true,
            displayName: 'Calibrations',
            wikiUrl: 'reporting/Calibrations',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/limited_life`,
        name: 'LimitedLifeReportView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/LimitedLifeReportView/LimitedLifeReportView.vue'),
        meta: {
            color: EntityType.ASSEMBLY,
            entityRelationships: [EntityType.ASSEMBLY, EntityType.ITEM_INSTANCE],
            description: 'Limited life report for an entire assembly or a single piece of hardware.',
            displayName: 'Limited Life',
            wikiUrl: 'reporting/Limited-Life',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/limited_life/results`,
        name: 'LimitedLifeReportResultsView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/LimitedLifeReportView/LimitedLifeReportResultsView.vue'),
        meta: {
            color: EntityType.ASSEMBLY,
            entityRelationships: [EntityType.ASSEMBLY, EntityType.ITEM_INSTANCE],
            description: 'Limited life report results for an entire assembly or a single piece of hardware.',
            doNotList: true,
            displayName: 'Limited Life Results',
            wikiUrl: 'reporting/Limited-Life',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/assembly_document_change_sets`,
        name: 'AssemblyDocumentChangeSetsReportView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/AssemblyDocumentChangeSetsReportView/AssemblyDocumentChangeSetsReportView.vue'),
        meta: {
            color: EntityType.CHANGE_SET,
            entityRelationships: [EntityType.ASSEMBLY, EntityType.CHANGE_SET, EntityType.DOCUMENT],
            description: 'Lists all Document Change Sets (line items) relating to any Hardware that is part of the selected assembly.<br/><br/><strong class="success--text">Open / Close Tree Paperwork export available.</strong>',
            displayName: 'Assembly Document <br/>Open / Close Change Sets',
            wikiUrl: 'reporting/Assembly-Document-Open-and-Close-Change-Sets',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/assembly_document_change_sets/results`,
        name: 'AssemblyDocumentChangeSetsReportResultsView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/AssemblyDocumentChangeSetsReportView/AssemblyDocumentChangeSetsReportResultsView.vue'),
        meta: {
            color: EntityType.CHANGE_SET,
            entityRelationships: [EntityType.ASSEMBLY, EntityType.CHANGE_SET, EntityType.DOCUMENT],
            description: 'Lists all Document Change Sets (line items) relating to any Hardware that is part of the selected assembly.<br/><br/><strong class="success--text">Open / Close Tree Paperwork export available.</strong>',
            doNotList: true,
            displayName: 'Assembly Document <br/>Open / Close Change Sets',
            wikiUrl: 'reporting/Assembly-Document-Open-and-Close-Change-Sets',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    // This report has not been ran because most users run Assembly Document Change Sets
    // delisting as of 10/30/23 documented in following issue:
    // https://gitlab.com/mri-technologies/cosmic/nasa/og/-/issues/2497#note_1626968490
    {
        path: `/reporting/assembly_documents`,
        name: 'AssemblyDocumentReportView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/AssemblyDocumentReportView/AssemblyDocumentReportView.vue'),
        meta: {
            color: EntityType.DOCUMENT,
            entityRelationships: [EntityType.ASSEMBLY, EntityType.DOCUMENT],
            description: 'Lists all Documents relating to any Hardware that is part of the selected assembly. This is a "document header level" report.<br/><br/><strong class="success--text">Open / Close Tree Paperwork export available.</strong>',
            doNotList: true,
            displayName: 'Assembly Documents',
            wikiUrl: 'reporting/Assembly-Documents',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/assembly_documents/results`,
        name: 'AssemblyDocumentReportResultsView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/AssemblyDocumentReportView/AssemblyDocumentReportResultsView.vue'),
        meta: {
            color: EntityType.DOCUMENT,
            entityRelationships: [EntityType.ASSEMBLY, EntityType.DOCUMENT],
            description: 'Lists all Documents relating to any Hardware that is part of the selected assembly. This is a "document header level" report.<br/><br/><strong class="success--text">Open / Close Tree Paperwork export available.</strong>',
            doNotList: true,
            displayName: 'Assembly Documents',
            wikiUrl: 'reporting/Assembly-Documents',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/assembly_template_indentured_parts_list`,
        name: 'AssemblyTemplateIPLReportView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/AssemblyTemplateIPLReportView/AssemblyTemplateIPLReportView.vue'),
        meta: {
            color: EntityType.ASSEMBLY,
            description: 'View a single multi-level hardware Assembly Template tree.',
            entityRelationships: [EntityType.INVENTORY, EntityType.ASSEMBLY],
            displayName: 'Assembly Template Indentured Parts List (IPL)',
            wikiUrl: 'reporting/Assembly-Template-Indentured-Parts-List',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/assembly_template_indentured_parts_list/results`,
        name: 'AssemblyTemplateIPLReportResultsView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/AssemblyTemplateIPLReportView/AssemblyTemplateIPLReportResultsView.vue'),
        meta: {
            color: EntityType.ASSEMBLY,
            description: 'View a single multi-level hardware Assembly Template tree.',
            doNotList: true,
            entityRelationships: [EntityType.INVENTORY, EntityType.ASSEMBLY],
            displayName: 'Assembly Template Indentured Parts List (IPL)',
            wikiUrl: 'reporting/Assembly-Template-Indentured-Parts-List',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/hazardous_hardware`,
        name: 'HazardousHardwareReportView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/HazardousHardwareReportView/HazardousHardwareReportView.vue'),
        meta: {
            color: EntityType.INVENTORY,
            entityRelationships: [EntityType.INVENTORY],
            description: 'Lists hazardous Inventory by bin number.',
            displayName: 'Hazardous Hardware',
            wikiUrl: 'reporting/Hazardous-Hardware',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/hazardous_hardware/results`,
        name: 'HazardousHardwareReportResultsView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/HazardousHardwareReportView/HazardousHardwareReportResultsView.vue'),
        meta: {
            color: EntityType.INVENTORY,
            entityRelationships: [EntityType.INVENTORY],
            description: 'Lists hazardous Inventory by bin number.',
            doNotList: true,
            displayName: 'Hazardous Hardware',
            wikiUrl: 'reporting/Hazardous-Hardware',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/hardware_build_up_list`,
        name: 'HardwareBuildUpListView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/HardwareBuildUpListView/HardwareBuildUpListView.vue'),
        meta: {
            color: EntityType.INVENTORY,
            entityRelationships: [EntityType.ASSEMBLY, EntityType.INVENTORY, EntityType.EVENT],
            description: "Lists out a flat list of items for the selected Event's Hardware List or selected Assemblies.",
            displayName: 'Hardware Build Up List',
            wikiUrl: 'reporting/Hardware-Build-Up-List',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/hardware_build_up_list/results`,
        name: 'HardwareBuildUpListResultsView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/HardwareBuildUpListView/HardwareBuildUpListResultsView.vue'),
        meta: {
            color: EntityType.INVENTORY,
            entityRelationships: [EntityType.ASSEMBLY, EntityType.INVENTORY, EntityType.EVENT],
            description: "Lists out a flat list of items for the selected Event's Hardware List or selected Assemblies.",
            doNotList: true,
            displayName: 'Hardware Build Up List',
            wikiUrl: 'reporting/Hardware-Build-Up-List',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/hardware_dates`,
        name: 'HardwareDatesReportView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/HardwareDatesReportView/HardwareDatesReportView.vue'),
        meta: {
            color: EntityType.ITEM_INSTANCE,
            entityRelationships: [EntityType.ITEM_INSTANCE],
            description: "Lists all selected Item Instance's dates.",
            displayName: 'Hardware Dates',
            wikiUrl: 'reporting/Hardware-Dates',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/hardware_dates/results`,
        name: 'HardwareDatesReportResultsView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/HardwareDatesReportView/HardwareDatesReportResultsView.vue'),
        meta: {
            color: EntityType.ITEM_INSTANCE,
            entityRelationships: [EntityType.ITEM_INSTANCE],
            description: "Lists all selected Item Instance's dates.",
            doNotList: true,
            displayName: 'Hardware Dates',
            wikiUrl: 'reporting/Hardware-Dates',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/hardware_list_status`,
        name: 'HardwareListStatusReportView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/HardwareListStatusReportView/HardwareListStatusReportView.vue'),
        meta: {
            color: EntityType.INVENTORY,
            entityRelationships: [EntityType.ASSEMBLY, EntityType.INVENTORY, EntityType.EVENT, EntityType.DOCUMENT],
            description: "A flat list of Documents for the selected Event's Hardware List.",
            displayName: 'Hardware List Status',
            wikiUrl: 'reporting/Hardware-List-Status',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/hardware_list_status/results`,
        name: 'HardwareListStatusReportViewResults',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/HardwareListStatusReportView/HardwareListStatusReportView.vue'),
        meta: {
            color: EntityType.INVENTORY,
            entityRelationships: [EntityType.ASSEMBLY, EntityType.INVENTORY, EntityType.EVENT, EntityType.DOCUMENT],
            doNotList: true,
            description: "A flat list of Documents for the selected Event's Hardware List.",
            displayName: 'Hardware List Status',
            wikiUrl: 'reporting/Hardware-List-Status',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/hardware_list_where_used`,
        name: 'HardwareListWhereUsedReportView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/HardwareListWhereUsedReportView/HardwareListWhereUsedReportView.vue'),
        meta: {
            color: EntityType.INVENTORY,
            entityRelationships: [EntityType.HARDWARE_LIST, EntityType.ITEM_INSTANCE],
            description: 'Input Item Instance to find which Hardware Lists it is associated with.',
            displayName: 'Hardware List where used',
            wikiUrl: 'reporting/Hardware-List-Where-Used',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/hardware_list_where_used/results`,
        name: 'HardwareListWhereUsedReportResultsView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/HardwareListWhereUsedReportView/HardwareListWhereUsedReportResultsView.vue'),
        meta: {
            color: EntityType.INVENTORY,
            entityRelationships: [EntityType.HARDWARE_LIST, EntityType.ITEM_INSTANCE],
            description: 'Input Item Instance to find which Hardware Lists it is associated with.',
            doNotList: true,
            displayName: 'Hardware List Where used',
            wikiUrl: 'reporting/Hardware-List-Where-Used',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/historical_records`,
        name: 'HistoricalRecordsReportView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/HistoricalRecordsReportView/HistoricalRecordsReportView.vue'),
        meta: {
            color: EntityType.DOCUMENT,
            description: 'Historical Records listing by Item Instance and related Hardware Lists.<br/><br/><strong class="success--text">772 - Historical Record export available.</strong>',
            entityRelationships: [EntityType.INVENTORY, EntityType.DOCUMENT, EntityType.HARDWARE_LIST],
            displayName: 'Historical Records',
            wikiUrl: 'reporting/Historical-Records',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/historical_records/results`,
        name: 'HistoricalRecordsReportResultsView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/HistoricalRecordsReportView/HistoricalRecordsReportResultsView.vue'),
        meta: {
            color: EntityType.DOCUMENT,
            description: 'Historical Records listing by Item Instance and related Hardware Lists.<br/><br/><strong class="success--text">772 - Historical Record export available.</strong>',
            doNotList: true,
            entityRelationships: [EntityType.INVENTORY, EntityType.DOCUMENT, EntityType.HARDWARE_LIST],
            displayName: 'Historical Records',
            wikiUrl: 'reporting/Historical-Records',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/1489_summary`,
        name: 'FourteenEightyNineSummaryReportView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/FourteenEightyNineSummaryReportView/FourteenEightyNineSummaryReportView.vue'),
        meta: {
            color: EntityType.ORGANIZATION,
            description: markdownToHtml(`Current Inventory report grouped by Object Class including cost. 

- This report **ONLY** includes "**Managed**" Locations.`),
            entityRelationships: [EntityType.INVENTORY, EntityType.LOCATION],
            displayName: '1489 Summary',
            wikiUrl: 'reporting/1489-Summary',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/changelog`,
        name: 'ChangeLogReportView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/ChangeLogReportView/ChangeLogReportView.vue'),
        meta: {
            color: EntityType.ITEM_INSTANCE,
            description: 'Lists the change history for selected entity.',
            entityRelationships: [EntityType.ITEM_INSTANCE, EntityType.ITEM_MASTER, EntityType.ITEM_DRAWING],
            displayName: 'Changelogs',
            wikiUrl: 'reporting/Changelog',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/changelog/results`,
        name: 'ChangeLogReportResultsView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/ChangeLogReportView/ChangeLogReportResultsView.vue'),
        meta: {
            color: EntityType.ITEM_INSTANCE,
            description: 'Lists the change history for selected entity.',
            doNotList: true,
            entityRelationships: [EntityType.ITEM_DRAWING, EntityType.ITEM_MASTER, EntityType.ITEM_INSTANCE],
            displayName: 'Changelog',
            wikiUrl: 'reporting/Changelog',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/document_listing`,
        name: 'DocumentListingReportView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/DocumentListingReportView/DocumentListingReportView.vue'),
        meta: {
            color: EntityType.DOCUMENT,
            description: 'Lists Document details for the selected criteria.',
            entityRelationships: [EntityType.DOCUMENT],
            displayName: 'Document Listing',
            wikiUrl: 'reporting/Document-Listing',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/document_listing/results`,
        name: 'DocumentListingReportResultsView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/DocumentListingReportView/DocumentListingReportResultsView.vue'),
        meta: {
            color: EntityType.DOCUMENT,
            description: 'Lists Document details for the selected criteria.',
            doNotList: true,
            entityRelationships: [EntityType.DOCUMENT],
            displayName: 'Document Listing',
            wikiUrl: 'reporting/Document-Listing',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/due_in_out`,
        name: 'DueInOutReport',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/DueInOutReportView/DueInOutReportView.vue'),
        meta: {
            color: 'shipments',
            description: 'Context based look at Inventory coming into and leaving the context.',
            entityRelationships: [EntityType.INVENTORY],
            displayName: 'Due in | Due Out',
            wikiUrl: 'reporting/Due-in-Due-Out',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/inventory_due_back`,
        name: 'InventoryDueBack',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/InventoryDueBackReportView/InventoryDueBackReportView.vue'),
        meta: {
            color: 'shipments',
            description: 'Lists all inventory that has been issued out from a Managed Location with an Expected Return Date.',
            entityRelationships: [EntityType.INVENTORY],
            displayName: 'Inventory due back',
            wikiUrl: 'reporting/Inventory-Due-Back',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/inventory_due_back/results`,
        name: 'InventoryDueBackResults',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/InventoryDueBackReportView/InventoryDueBackReportResultsView.vue'),
        meta: {
            color: 'shipments',
            description: 'Lists all inventory that has been issued out from a Managed Location with an Expected Return Date.',
            doNotList: true,
            entityRelationships: [EntityType.INVENTORY],
            displayName: 'Inventory due back',
            wikiUrl: 'reporting/Inventory-Due-Back',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/readiness`,
        name: 'ReadinessReport',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/ReadinessReport/ReadinessReport.vue'),
        meta: {
            color: EntityType.EVENT,
            description: 'Complete Hardware readiness review reports for Hardware Lists or single Assemblies ( TRR / URR Report).',
            entityRelationships: [EntityType.ASSEMBLY, EntityType.EVENT],
            displayName: 'Readiness Report',
            wikiUrl: 'reporting/Readiness-Report',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/readiness/results`,
        name: 'ReadinessReportResults',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/ReadinessReport/ReadinessReportResults.vue'),
        meta: {
            color: EntityType.EVENT,
            description: 'Complete Hardware readiness review reports for Hardware Lists or single Assemblies ( TRR / URR Report).',
            doNotList: true,
            entityRelationships: [EntityType.ASSEMBLY, EntityType.EVENT],
            displayName: 'Readiness Report',
            wikiUrl: 'reporting/Readiness-Report',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/failures`,
        name: 'FailuresReportView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/FailuresReportView/FailuresReportView.vue'),
        meta: {
            color: EntityType.CHANGE_SET,
            description: 'Lists open and (optionally) closed failures by drawing number.',
            entityRelationships: [EntityType.CHANGE_SET, EntityType.ITEM_INSTANCE],
            displayName: 'Failures',
            wikiUrl: 'reporting/Failures',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/failures/results`,
        name: 'FailuresReportResultsView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/FailuresReportView/FailuresReportResultsView.vue'),
        meta: {
            color: EntityType.CHANGE_SET,
            description: 'Lists open and (optionally) closed failures by drawing number.',
            doNotList: true,
            entityRelationships: [EntityType.CHANGE_SET, EntityType.ITEM_INSTANCE],
            displayName: 'Failures',
            wikiUrl: 'reporting/Failures',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/hardware_activity`,
        name: 'HardwareActivityReportView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/HardwareActivityReportView/HardwareActivityReportView.vue'),
        meta: {
            color: EntityType.ITEM_INSTANCE,
            description: 'Lists all activites related to the selected Item Instance.',
            entityRelationships: [EntityType.ITEM_INSTANCE, EntityType.ACTIVITY],
            displayName: 'Hardware Activity',
            wikiUrl: 'reporting/Hardware-Activity',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/hardware_activity/results`,
        name: 'HardwareActivityReportResultsView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/HardwareActivityReportView/HardwareActivityReportResultsView.vue'),
        meta: {
            color: EntityType.ITEM_INSTANCE,
            description: 'Lists all activites related to the selected Item Instance.',
            doNotList: true,
            entityRelationships: [EntityType.ITEM_INSTANCE, EntityType.ACTIVITY],
            displayName: 'Hardware Activity',
            wikiUrl: 'reporting/Hardware-Activity',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/indentured_parts_list`,
        name: 'IndenturedPartsListView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/IndenturedPartsListView/IndenturedPartsListView.vue'),
        meta: {
            color: EntityType.ASSEMBLY,
            description: 'View a single multi-level hardware Assembly tree.',
            entityRelationships: [EntityType.INVENTORY, EntityType.ASSEMBLY],
            displayName: 'Indentured Parts List (IPL)',
            wikiUrl: 'reporting/Indentured-Parts-List-(IPL)',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/indentured_parts_list/results`,
        name: 'IndenturedPartsListResultsView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/IndenturedPartsListView/IndenturedPartsListResultsView.vue'),
        meta: {
            color: EntityType.ASSEMBLY,
            description: 'View a single multi-level hardware Assembly tree.',
            doNotList: true,
            entityRelationships: [EntityType.INVENTORY, EntityType.ASSEMBLY],
            displayName: 'Indentured Parts List (IPL)',
            wikiUrl: 'reporting/Indentured-Parts-List-(IPL)',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/interval_life`,
        name: 'IntervalLifeReportView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/IntervalLifeReportView/IntervalLifeReportView.vue'),
        meta: {
            color: EntityType.ITEM_INSTANCE,
            description: 'Limited Life report based on generic Interval Life.',
            entityRelationships: [EntityType.ITEM_INSTANCE],
            displayName: 'Interval Life',
            wikiUrl: 'reporting/Interval-Life',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/interval_life/results`,
        name: 'IntervalLifeReportResultsView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/IntervalLifeReportView/IntervalBasedLifeReportResultsView.vue'),
        meta: {
            color: EntityType.ITEM_INSTANCE,
            description: 'Limited Life report based on generic Interval Life.',
            doNotList: true,
            entityRelationships: [EntityType.ITEM_INSTANCE],
            displayName: 'Interval Life Results',
            wikiUrl: 'reporting/Interval-Life',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/inventory`,
        name: 'InventoryReportView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/InventoryReportView/InventoryReportView.vue'),
        meta: {
            color: EntityType.INVENTORY,
            description: 'Lists all Inventory matching the selected criteria.',
            entityRelationships: [EntityType.INVENTORY, EntityType.LOCATION],
            displayName: 'Inventory',
            wikiUrl: 'reporting/Inventory',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/inventory/results`,
        name: 'InventoryReportResultsView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/InventoryReportView/InventoryReportResultsView.vue'),
        meta: {
            color: EntityType.INVENTORY,
            description: 'Lists all Inventory matching the selected criteria.',
            doNotList: true,
            entityRelationships: [EntityType.INVENTORY, EntityType.LOCATION],
            displayName: 'InventoryReportResultsView',
            wikiUrl: 'reporting/Inventory',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/inventory_quota`,
        name: 'InventoryQuotaReportView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/InventoryQuotaReportView/InventoryQuotaReportView.vue'),
        meta: {
            color: EntityType.INVENTORY,
            description: 'Lists all Inventory Quotas matching the selected criteria',
            entityRelationships: [EntityType.INVENTORY, EntityType.LOCATION],
            displayName: 'Inventory Quota',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/inventory_quota/results`,
        name: 'InventoryQuotaReportResultsView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/InventoryQuotaReportView/InventoryQuotaReportResultsView.vue'),
        meta: {
            color: EntityType.INVENTORY,
            description: 'Lists all Inventory Quotas matching the selected criteria',
            doNotList: true,
            entityRelationships: [EntityType.INVENTORY, EntityType.LOCATION],
            displayName: 'Inventory Quota',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/inventory_transactions`,
        name: 'InventoryTransactionsReportView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/InventoryTransactionsReportView/InventoryTransactionsReportView.vue'),
        meta: {
            color: EntityType.INVENTORY,
            description: 'Lists all <strong>COST CORRECTION</strong> | <strong>INVENTORY AUDIT_CORRECTION</strong> | <strong>INVENTORY AUDIT_VERIFICATION</strong> | <strong>INVENTORY CHECK</strong> | <strong>ISSUE</strong> | <strong>ISSUE ASSEMBLED</strong> | <strong>MERGE CORRECTION</strong> | <strong>RECEIPT</strong> | <strong>RECEIPT ASSEMBLED</strong> | <strong>TRANSFER ASSEMBLED_FROM</strong> | <strong>TRANSFER ASSEMBLED_TO</strong> | <strong>TRANSFER ISSUED_ASSEMBLED</strong> | <strong>TRANSFER TO</strong> | <strong>TRANSFER FROM</strong> | <strong>TRANSFER ISSUED</strong> | <strong>SERIALIZE</strong>  Inventory transactions performed on the selected Item Instances.',
            entityRelationships: [EntityType.INVENTORY, EntityType.LOCATION],
            displayName: 'Inventory Transactions',
            wikiUrl: 'reporting/Inventory-Transactions',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/inventory_transactions/results`,
        name: 'InventoryTransactionsReportResultsView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/InventoryTransactionsReportView/InventoryTransactionsReportResultsView.vue'),
        meta: {
            color: EntityType.INVENTORY,
            description: 'Lists all <strong>COST CORRECTION</strong> | <strong>INVENTORY AUDIT_CORRECTION</strong> | <strong>INVENTORY AUDIT_VERIFICATION</strong> | <strong>INVENTORY CHECK</strong> | <strong>ISSUE</strong> | <strong>ISSUE ASSEMBLED</strong> | <strong>MERGE CORRECTION</strong> | <strong>RECEIPT</strong> | <strong>RECEIPT ASSEMBLED</strong> | <strong>TRANSFER ASSEMBLED_FROM</strong> | <strong>TRANSFER ASSEMBLED_TO</strong> | <strong>TRANSFER ISSUED_ASSEMBLED</strong> | <strong>TRANSFER TO</strong> | <strong>TRANSFER FROM</strong> | <strong>TRANSFER ISSUED</strong> | <strong>SERIALIZE</strong>  Inventory transactions performed on the selected Item Instances.',
            doNotList: true,
            entityRelationships: [EntityType.INVENTORY, EntityType.LOCATION],
            displayName: 'Inventory Transactions',
            wikiUrl: 'reporting/Inventory-Transactions',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/item_master`,
        name: 'ItemMasterReportView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/ItemMasterReportView/ItemMasterReportView.vue'),
        meta: {
            color: EntityType.ITEM_MASTER,
            description: 'Lists Item Masters matching the selected criteria.',
            entityRelationships: [EntityType.ITEM_MASTER],
            displayName: 'Item Masters',
            wikiUrl: 'reporting/Item-Masters',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/item_master/results`,
        name: 'ItemMasterReportResultsView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/ItemMasterReportView/ItemMasterReportResultsView.vue'),
        meta: {
            color: EntityType.ITEM_MASTER,
            description: 'Lists Item Masters matching the selected criteria.',
            doNotList: true,
            entityRelationships: [EntityType.ITEM_MASTER],
            displayName: 'Item Masters',
            wikiUrl: 'reporting/Item-Masters',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/limited_life_thresholds`,
        name: 'LimitedLifeThresholdsReportView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/LimitedLifeThresholdsReportView/LimitedLifeThresholdsReportView.vue'),
        meta: {
            color: EntityType.ITEM_INSTANCE,
            description: 'Lists all Item Instances that fall within the thresholds set.',
            entityRelationships: [EntityType.ITEM_INSTANCE],
            displayName: 'Limited Life Thresholds',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/limited_life_thresholds/results`,
        name: 'LimitedLifeThresholdsReportResultsView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/LimitedLifeThresholdsReportView/LimitedLifeThresholdsReportResultsView.vue'),
        meta: {
            color: EntityType.ITEM_INSTANCE,
            description: 'Lists all Item Instances that fall within the thresholds set.',
            doNotList: true,
            entityRelationships: [EntityType.ITEM_INSTANCE],
            displayName: 'Limited Life Thresholds',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/nine_eleven_tags`,
        name: 'NineElevenTagsReportView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/NineElevenTagsReportView/NineElevenTagsReportView.vue'),
        meta: {
            color: EntityType.INVENTORY,
            description: 'Generates a 911 Tag for each selected Hardware. Hardware information will be filled in on each tag based on your selections, with user editable overrides available.',
            entityRelationships: [EntityType.INVENTORY],
            displayName: '911 Tags',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/no_activity`,
        name: 'NoActivityReportView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/NoActivityReportView/NoActivityReportView.vue'),
        meta: {
            color: EntityType.INVENTORY,
            description: "Lists all Inventory that don't have transactions.",
            entityRelationships: [EntityType.INVENTORY],
            displayName: 'No Activity',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/object_class`,
        name: 'ObjectClassReportView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/ObjectClassReportView/ObjectClassReportView.vue'),
        meta: {
            color: EntityType.INVENTORY,
            entityRelationships: [EntityType.INVENTORY],
            description: 'Lists all inventories by Object Class.',
            displayName: 'Object Class',
            wikiUrl: 'reporting/Object-Class',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/object_class/results`,
        name: 'ObjectClassReportResultsView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/ObjectClassReportView/ObjectClassReportResultsView.vue'),
        meta: {
            color: EntityType.INVENTORY,
            entityRelationships: [EntityType.INVENTORY],
            description: 'Lists all inventories by Object Class.',
            doNotList: true,
            displayName: 'Object Class Results',
            wikiUrl: 'reporting/Object-Class',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/physical_inventory_audit_summary`,
        name: 'PhysicalInventoryAuditSummaryReportView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/PhysicalInventoryAuditSummaryReportView/PhysicalInventoryAuditSummaryReportView.vue'),
        meta: {
            color: EntityType.PHYSICAL_INVENTORY_AUDIT,
            description: 'Lists a summary of the selected completed Physical Inventory Audit.',
            entityRelationships: [EntityType.INVENTORY, EntityType.PHYSICAL_INVENTORY_AUDIT],
            displayName: 'Physical Inventory Audit Summary',
            wikiUrl: 'reporting/Physical-Inventory-Audit-Summary-Report',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/physical_inventory_audit_summary/results`,
        name: 'PhysicalInventoryAuditSummaryReportResultsView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/PhysicalInventoryAuditSummaryReportView/PhysicalInventoryAuditSummaryReportResultsView.vue'),
        meta: {
            color: EntityType.PHYSICAL_INVENTORY_AUDIT,
            description: 'Lists a summary of the selected completed Physical Inventory Audit.',
            doNotList: true,
            entityRelationships: [EntityType.INVENTORY, EntityType.PHYSICAL_INVENTORY_AUDIT],
            displayName: 'Physical Inventory Audit Summary',
            wikiUrl: 'reporting/Physical-Inventory-Audit-Summary-Report',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/paperwork`,
        name: 'PaperworkReportView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/PaperworkReportView/PaperworkReportView.vue'),
        meta: {
            color: EntityType.DOCUMENT,
            description: 'Lists all Document line items that are open for the selected criteria.',
            entityRelationships: [EntityType.DOCUMENT, EntityType.CHANGE_SET, EntityType.ITEM_INSTANCE],
            displayName: 'Paperwork Report',
            wikiUrl: 'reporting/Paperwork-Report',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/paperwork/results`,
        name: 'PaperworkReportResultsView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/PaperworkReportView/PaperworkReportResultsView.vue'),
        meta: {
            color: EntityType.DOCUMENT,
            description: 'Lists all Document line items that are open for the selected criteria.',
            doNotList: true,
            entityRelationships: [EntityType.DOCUMENT, EntityType.CHANGE_SET, EntityType.ITEM_INSTANCE],
            displayName: 'Paperwork Report',
            wikiUrl: 'reporting/Paperwork-Report',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/operation_life`,
        name: 'OperationalLifeReportView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/OperationalLifeReportView/OperationalLifeReportView.vue'),
        meta: {
            color: EntityType.ITEM_INSTANCE,
            description: 'Limited Life report based on Operational Life.',
            entityRelationships: [EntityType.ITEM_INSTANCE],
            displayName: 'Operational Life',
            wikiUrl: 'reporting/Operational-Life',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/shelf_life`,
        name: 'ShelfLifeReportView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/ShelfLifeReportView/ShelfLifeReportView.vue'),
        meta: {
            color: EntityType.ITEM_INSTANCE,
            description: 'Limited Life report based on Shelf Life.',
            entityRelationships: [EntityType.ITEM_INSTANCE],
            displayName: 'Shelf Life',
            wikiUrl: 'reporting/Shelf-Life',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/software_version`,
        name: 'SoftwareVersionReportView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/SoftwareVersionReportView/SoftwareVersionReportView.vue'),
        meta: {
            color: EntityType.ITEM_INSTANCE,
            description: 'Displays all hardware that has a software version.',
            entityRelationships: [EntityType.ITEM_INSTANCE],
            displayName: 'Software version',
            wikiUrl: 'reporting/Software-Version',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/software_version/results`,
        name: 'SoftwareVersionReportResultsView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/SoftwareVersionReportView/SoftwareVersionReportResultsView.vue'),
        meta: {
            color: EntityType.ITEM_INSTANCE,
            description: 'Displays all hardware that has a software version.',
            doNotList: true,
            entityRelationships: [EntityType.ITEM_INSTANCE],
            displayName: 'Software version',
            wikiUrl: 'reporting/Software-Version',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/biannual_1324`,
        name: 'Biannual1324',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/Biannual1324/Biannual1324.vue'),
        meta: {
            color: EntityType.LOCATION,
            description: 'Lists the counts for all the <b>ITEMS</b> by building.',
            entityRelationships: [EntityType.LOCATION, EntityType.INVENTORY],
            displayName: 'Biannual 1324',
            wikiUrl: 'reporting/Biannual-1324',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/biannual_1324/results`,
        name: 'Biannual1324Results',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/Biannual1324/Biannual1324Results.vue'),
        meta: {
            color: EntityType.LOCATION,
            description: 'Lists the counts for all the <b>ITEMS</b> by building.',
            doNotList: true,
            entityRelationships: [EntityType.LOCATION, EntityType.INVENTORY],
            displayName: 'Biannual 1324',
            wikiUrl: 'reporting/Biannual-1324',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/transaction_count_by_project_code`,
        name: 'TransactionCountByProjectCode',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/TransactionCountByProjectCode/TransactionCountByProjectCode.vue'),
        meta: {
            color: EntityType.LOCATION,
            description: 'Lists the sums for all <b>ISSUE</b> and <b>TRANSFER</b> transactions by project code.',
            entityRelationships: [EntityType.LOCATION, EntityType.INVENTORY],
            displayName: 'Transaction Count By Project Code',
            wikiUrl: 'reporting/Trans-Count-By-Proj-Code',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/transaction_count_by_project_code/results`,
        name: 'TransactionCountByProjectCodeResults',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/TransactionCountByProjectCode/TransactionCountByProjectCodeResults.vue'),
        meta: {
            color: EntityType.LOCATION,
            description: 'Lists the sums for all <b>ISSUE</b> and <b>TRANSFER</b> transactions by project code.',
            doNotList: true,
            entityRelationships: [EntityType.LOCATION, EntityType.INVENTORY],
            displayName: 'Transaction Count By Project Code',
            wikiUrl: 'reporting/Trans-Count-By-Proj-Code',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/usage_life`,
        name: 'UsageLifeReportView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/UsageLifeReportView/UsageLifeReportView.vue'),
        meta: {
            color: EntityType.ITEM_INSTANCE,
            description: 'Limited Life report based on Usage Life.',
            entityRelationships: [EntityType.ITEM_INSTANCE],
            displayName: 'Usage Life',
            wikiUrl: 'reporting/Usage-Life',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/user_admin_listing`,
        name: 'UserAdminListingReportView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/UserAdminListingReportView/UserAdminListingReportView.vue'),
        meta: {
            color: EntityType.USER,
            description: 'Lists all COSMIC User Admins.',
            entityRelationships: [EntityType.USER],
            displayName: 'User Admin Listing',
            wikiUrl: 'reporting/User-Admin-Listing',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/cycle_life`,
        name: 'CycleLifeReportView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/CycleLifeReportView/CycleLifeReportView.vue'),
        meta: {
            color: EntityType.ITEM_INSTANCE,
            description: 'Limited Life report based on Cycle counts.',
            entityRelationships: [EntityType.ITEM_INSTANCE],
            displayName: 'Cycle Life',
            wikiUrl: 'reporting/Cycle-Life',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/cycle_life/results`,
        name: 'CycleLifeReportResultsView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/CycleLifeReportView/CycleLifeReportResultsView.vue'),
        meta: {
            color: EntityType.ITEM_INSTANCE,
            description: 'Limited Life report based on Cycle counts.',
            doNotList: true,
            entityRelationships: [EntityType.ITEM_INSTANCE],
            displayName: 'Cycle Life',
            wikiUrl: 'reporting/Cycle-Life',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/flight_life`,
        name: 'FlightLifeReportView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/FlightLifeReportView/FlightLifeReportView.vue'),
        meta: {
            color: EntityType.ITEM_INSTANCE,
            description: 'Limited Life report based on generic Flight counts.',
            entityRelationships: [EntityType.ITEM_INSTANCE],
            displayName: 'Flight Life',
            wikiUrl: 'reporting/Flight-Life',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/flight_life/results`,
        name: 'FlightLifeReportResultsView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/FlightLifeReportView/FlightLifeReportResultsView.vue'),
        meta: {
            color: EntityType.ITEM_INSTANCE,
            description: 'Limited Life report based on Flight counts.',
            doNotList: true,
            entityRelationships: [EntityType.ITEM_INSTANCE],
            displayName: 'Flight Life ',
            wikiUrl: 'reporting/Flight-Life',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/eva_life`,
        name: 'EvaLifeReportView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/EvaLifeReportView/EvaLifeReportView.vue'),
        meta: {
            color: EntityType.ITEM_INSTANCE,
            description: 'Limited Life report based on EVA counts.',
            entityRelationships: [EntityType.ITEM_INSTANCE],
            displayName: 'EVA Life',
            wikiUrl: 'reporting/EVA-Life',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/eva_life/results`,
        name: 'EvaLifeReportResultsView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/EvaLifeReportView/EvaLifeReportResultsView.vue'),
        meta: {
            color: EntityType.ITEM_INSTANCE,
            description: 'Limited Life report based on Cycle counts.',
            doNotList: true,
            entityRelationships: [EntityType.ITEM_INSTANCE],
            displayName: 'EVA Life',
            wikiUrl: 'reporting/EVA-Life',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/inventory_by_bin`,
        name: 'InventoryByBinReportView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/InventoryByBinReportView/InventoryByBinReportView.vue'),
        meta: {
            color: EntityType.INVENTORY,
            entityRelationships: [EntityType.INVENTORY, EntityType.LOCATION],
            description: 'Lists Inventory by bin number.',
            displayName: 'Inventory by Bin',
            wikiUrl: 'reporting/Inventory-by-Bin',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/inventory_by_bin/results`,
        name: 'InventoryByBinReportResultsView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/InventoryByBinReportView/InventoryByBinReportResultsView.vue'),
        meta: {
            color: EntityType.INVENTORY,
            doNotList: true,
            entityRelationships: [EntityType.INVENTORY, EntityType.LOCATION],
            description: 'Lists Inventory by bin number.',
            displayName: 'Inventory by Bin',
            wikiUrl: 'reporting/Inventory-by-Bin',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/hardware_by_drawing_number`,
        name: 'HardwareByDrawingNumberReportView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/HardwareByDrawingNumberReportView/HardwareByDrawingNumberReportView.vue'),
        meta: {
            color: EntityType.INVENTORY,
            entityRelationships: [EntityType.INVENTORY],
            description: 'Lists hardware by drawing number.',
            displayName: 'Hardware by Drawing number',
            wikiUrl: 'reporting/Hardware-by-Drawing-Number',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/hardware_by_drawing_number/results`,
        name: 'HardwareByDrawingNumberReportResultsView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/HardwareByDrawingNumberReportView/HardwareByDrawingNumberReportResultsView.vue'),
        meta: {
            color: EntityType.INVENTORY,
            doNotList: true,
            entityRelationships: [EntityType.INVENTORY],
            description: 'Lists hardware by drawing number.',
            displayName: 'Hardware by Drawing number',
            wikiUrl: 'reporting/Hardware-by-Drawing-Number',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/inventory_by_org`,
        name: 'InventoryByOrgLocationReportView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/InventoryByOrgLocationReportView/InventoryByOrgLocationReportView.vue'),
        meta: {
            color: EntityType.INVENTORY,
            entityRelationships: [EntityType.INVENTORY, EntityType.ORGANIZATION],
            description: 'Lists Inventory that has been issued to an Organization.',
            displayName: 'Inventory by Org',
            wikiUrl: 'reporting/Inventory-by-Org-Location',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/inventory_by_org/results`,
        name: 'InventoryByOrgLocationReportResultsView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/InventoryByOrgLocationReportView/InventoryByOrgLocationReportResultsView.vue'),
        meta: {
            color: EntityType.INVENTORY,
            entityRelationships: [EntityType.INVENTORY, EntityType.ORGANIZATION],
            description: 'Lists Inventory that has been issued to an Organization.',
            doNotList: true,
            displayName: 'Inventory by Org',
            wikiUrl: 'reporting/Inventory-by-Org-Location',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/total_pressurized_time`,
        name: 'TotalPressurizedTimeReportView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/TotalPressurizedTimeReportView/TotalPressurizedTimeReportView.vue'),
        meta: {
            description: 'Lists Total Pressurized Time for selected hardware.',
            displayName: 'Total Pressurized Time',
            entityRelationships: [EntityType.ITEM_INSTANCE, EntityType.ACTIVITY],
            wikiUrl: 'reporting/Total-Pressurized-Time',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/total_pressurized_time/results`,
        name: 'TotalPressurizedTimeReportResultsView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/TotalPressurizedTimeReportView/TotalPressurizedTimeReportResultsView.vue'),
        meta: {
            description: 'Lists Total Pressurized Time for selected hardware.',
            doNotList: true,
            displayName: 'Total Pressurized Time Results',
            entityRelationships: [EntityType.ITEM_INSTANCE, EntityType.ACTIVITY],
            wikiUrl: 'reporting/Total-Pressurized-Time',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/where_used`,
        name: 'WhereUsedReportView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/WhereUsedReportView/WhereUsedReportView.vue'),
        meta: {
            color: EntityType.INVENTORY,
            entityRelationships: [EntityType.ASSEMBLY_TEMPLATE, EntityType.WORKING_ASSEMBLY, EntityType.INVENTORY],
            description: 'Input Drawing Numbers to view associated Assemblies / Assembly Templates.',
            displayName: 'Where used',
            wikiUrl: 'reporting/Where-Used',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/where_used/results`,
        name: 'WhereUsedReportResultsView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/WhereUsedReportView/WhereUsedReportResultsView.vue'),
        meta: {
            color: EntityType.INVENTORY,
            entityRelationships: [EntityType.ASSEMBLY_TEMPLATE, EntityType.WORKING_ASSEMBLY, EntityType.INVENTORY],
            description: 'Input Drawing Numbers to view associated Assemblies / Assembly Templates.',
            doNotList: true,
            displayName: 'Where used',
            wikiUrl: 'reporting/Where-Used',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/user_change_set_approvals`,
        name: 'UserChangeSetAuthoritiesReport',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/UserChangeSetAuthoritiesReportView/UserChangeSetAuthoritiesReportView.vue'),
        meta: {
            color: EntityType.CHANGE_SET,
            description: 'All items that you can approve will be listed here.',
            entityRelationships: [EntityType.CHANGE_SET],
            displayName: 'My approval queue',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    }
];
export const DataCorrectionRoutes = [
    {
        path: `/reporting/archived_hardware`,
        name: 'ArchivedHardwareReportView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/ArchivedHardwareReportView/ArchivedHardwareReportView.vue'),
        meta: {
            color: EntityType.ITEM_INSTANCE,
            entityRelationships: [EntityType.ITEM_INSTANCE],
            description: 'Lists and allows unarchival for archived hardware.',
            displayName: 'Archived Hardware',
            wikiUrl: 'reporting',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/data_correction_limited_life`,
        name: 'LimitedLifeDataCorrectionReportView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/LimitedLifeDataCorrectionReportView/LimitedLifeDataCorrectionReportView.vue'),
        meta: {
            color: EntityType.ITEM_INSTANCE,
            entityRelationships: [EntityType.ITEM_INSTANCE],
            description: 'Lists and fixes Limited Life data discrepancies.',
            displayName: 'Limited Life data correction',
            wikiUrl: 'reporting/TESTINGTESTINGTESTINGTESTING',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/merge_item_instances`,
        name: 'MergeItemInstancesView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/MergeItemInstancesView/MergeItemInstancesView.vue'),
        meta: {
            color: EntityType.ITEM_INSTANCE,
            entityRelationships: [EntityType.ITEM_INSTANCE],
            description: 'Merge Item Instances with user-directed remediations.',
            displayName: 'Merge Item Instances',
            wikiUrl: 'reporting/Limited-Life-Data-Correction',
            userRoles: [],
            permissions: [UserPermission.MERGE_ITEM_INSTANCES],
            supportContexts: []
        }
    },
    {
        path: `/reporting/bulk_edit_documents`,
        name: 'BulkEditDocumentsView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/BulkEditDocumentsView/BulkEditDocumentsView.vue'),
        meta: {
            color: EntityType.DOCUMENT,
            entityRelationships: [EntityType.DOCUMENT],
            description: 'Allows editing of multiple Documents at once',
            displayName: 'Bulk Edit Documents',
            wikiUrl: 'reporting/Bulk-Edit-Documents',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    }
];
// put a flag on these so that they can be filtered out later
DataCorrectionRoutes.forEach((route) => (route.meta.dataCorrection = true));
const ALL_ROUTES = [
    {
        path: `/`,
        name: 'HomeView',
        component: HomeView,
        meta: {
            displayName: 'COSMIC Home',
            description: 'Home page for COSMIC',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    ...ActivitiesSharedRoutes,
    ...AssemblyTemplateSharedRoutes,
    ...AdminSharedRoutes,
    ...ChangeSetSharedRoutes,
    ...ContactSharedRoutes,
    ...DataCorrectionRoutes,
    ...DocumentRoutes,
    ...EventRoutes,
    ...HardwareRoutes,
    ...HardwareListSharedRoutes,
    ...HardwareListTemplateSharedRoutes,
    ...LocationSharedRoutes,
    ...OrganizationSharedRoutes,
    ...ReportingRoutes,
    ...UserSharedRoutes,
    ...NonnominalSharedRoutes
];
export default new RouterLogUtility(VueRouter, ALL_ROUTES);

var _a, _b;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
import { AssemblyTemplateResponseWithChildren, AssemblyTemplateType, HardwareListType, Side } from '~/db_types/swagger_types';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { AlertType, SideDisplay } from '~/nasa_ui/types';
import { clearAssemblyTemplateCacheById, getAssemblyTemplate, getAutoHardwareLists, isNullOrUndefined, putAssemblyTemplate, putPartReference } from '~/nasa_ui/utils';
import { PartReferenceTableHeaders } from '~/nasa_ui/utils/helpers/tableDefinitions';
import { transformAutoHardwareListResponseWithCounts, transformPartReferenceResponses } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let ModalAssemblyTemplateEdit = class ModalAssemblyTemplateEdit extends BaseModal {
    allAutoHardwareLists = null;
    HardwareListType = HardwareListType;
    parentData = null;
    PartReferenceTableHeaders = PartReferenceTableHeaders;
    selectedEntity = null;
    selectedPartReference = null;
    selectedPartReferenceEdited = null;
    selectedPartReferenceRequest = null;
    selectedHardwareList = null;
    showPartReferenceSelector = false;
    Side = Side;
    SideDisplay = SideDisplay;
    transformedPartReferences = [];
    // this is populated when submitting the edit form, provided the user has
    // elected to mirror an assembly tree and the selected inventory actually has
    // a working assembly.
    selectedInventoryWorkingAssemblyUuid = null;
    formData = {
        attributes: {},
        parentId: null,
        autoHardwareListId: null,
        documentId: null,
        installedOn: Side.NONE,
        maxQuantity: 1,
        minQuantity: 1,
        name: null,
        pbsItemId: null,
        sequence: null,
        subType: null
    };
    assemblyTemplateId;
    get computedAssemblyTemplateParts() {
        if (!this.transformedPartReferences) {
            return [];
        }
        return this.transformedPartReferences.map((partReference) => {
            const request = { attributes: {} };
            if (partReference.id) {
                request.partReferenceId = partReference.id;
            }
            else {
                request.createPartReference = {
                    ...partReference
                };
            }
            return request;
        });
    }
    get editAssemblyTemplateVariables() {
        if (!this.isFormValid) {
            return null;
        }
        const parts = [...this.computedAssemblyTemplateParts].map((part) => {
            if (part.createPartReference) {
                Object.keys(part.createPartReference).forEach((key) => {
                    if (part.createPartReference && key.startsWith('_')) {
                        delete part.createPartReference[key];
                    }
                });
            }
            return part;
        });
        const body = {
            attributes: this.formData.attributes ?? {},
            autoHardwareListId: this.formData.autoHardwareListId ?? null,
            digitalLibraryRecordId: this.formData.digitalLibraryRecordId ?? null,
            documentId: this.formData.documentId ?? null,
            installedOn: this.formData.installedOn ?? Side.NONE,
            maxQuantity: this.formData.maxQuantity ?? 0,
            minQuantity: this.formData.minQuantity ?? 0,
            name: this.formData.name ?? null,
            needDate: this.formData.needDate ?? null,
            parentId: this.parentData?.id ?? null,
            parts,
            pbsItemId: this.formData.pbsItemId ?? null,
            sequence: this.formData.sequence || 0,
            subType: this.formData.subType,
            waiverDate: this.formData.waiverDate ?? null,
            waiverNumber: this.formData.waiverNumber ?? null
        };
        return body;
    }
    get installedOnMessage() {
        switch (this.formData.installedOn) {
            case Side.LEFT:
                return 'Left';
            case Side.RIGHT:
                return 'Right';
            case Side.NONE:
                return 'Not applicable';
        }
    }
    get isDuplicateBySequenceByParent() {
        if (isNullOrUndefined(this.formData.sequence) ||
            !this.parentData?.children?.length ||
            this.formData.sequence === this.selectedEntity?.sequence) {
            return false;
        }
        return this.parentData.children.some((assyTemp) => assyTemp.sequence === this.formData.sequence && assyTemp.depth === 1);
    }
    get isEventAssemblyTemplate() {
        return this.formData.subType === AssemblyTemplateType.EVENT_ASSEMBLY;
    }
    get isGenericAssemblyTemplate() {
        return this.formData.subType === AssemblyTemplateType.ASSEMBLY_TEMPLATE;
    }
    get isPbsAssemblyTemplate() {
        return this.formData.subType === AssemblyTemplateType.PRODUCT_BREAKDOWN_STRUCTURE;
    }
    get isFormValid() {
        return this.isQuantityValid && this.isSequenceValid;
    }
    get isQuantityValid() {
        return this.isNotMoreThanMaxQuantity(this.formData.minQuantity || 0, this.formData.maxQuantity || 0);
    }
    get isRoot() {
        return Boolean(!this.selectedEntity?.parentId && this.selectedEntity?.sequence === 0);
    }
    get isSequenceValid() {
        const sequenceIsZeroOrMore = Boolean(this.formData.sequence >= 0);
        return Boolean(sequenceIsZeroOrMore && !this.isDuplicateBySequenceByParent);
    }
    get minIsLessThanMax() {
        return this.formData.minQuantity <= this.formData.maxQuantity;
    }
    get parentAssemblyTemplateId() {
        return this.selectedEntity?.parentId;
    }
    get partReferenceTableItems() {
        return this.transformedPartReferences;
    }
    get selectOptionsItemListings() {
        if (!this.allAutoHardwareLists) {
            return null;
        }
        return transformAutoHardwareListResponseWithCounts(this.allAutoHardwareLists).map((ahl) => {
            return {
                details: ahl,
                displayText: `${ahl._name} - ${ahl._description}`,
                value: ahl.id
            };
        });
    }
    get sequenceNumberErrorMessage() {
        if (isNullOrUndefined(this.formData.sequence)) {
            return ['Sequence number is required'];
        }
        else if (this.isDuplicateBySequenceByParent) {
            return 'Sequence number exists.';
        }
        else if (!this.sequenceIsWithinSmallIntRange) {
            return 'Sequence number is out of range.';
        }
        else if (!this.sequenceNumberIsInteger) {
            return ['Sequence number must be integer'];
        }
        return null;
    }
    get sequenceIsWithinSmallIntRange() {
        // 32,767 is the maximum whole number value a SMALLINT data type can store.
        return (this.formData?.sequence || 0) < 32767;
    }
    get sequenceNumberIsInteger() {
        return Number.isInteger(Number(this.formData.sequence));
    }
    get sequenceNumberMessage() {
        if (this.isSequenceValid) {
            return 'Sequence accepted.';
        }
    }
    get shouldDisableEditButton() {
        return !this.isFormValid || this.showPartReferenceSelector;
    }
    async editAssemblyTemplate(assemblyTemplateId, editAssemblyTemplateVariables) {
        if (!editAssemblyTemplateVariables) {
            this.$notification.add({
                type: AlertType.ERROR,
                text: 'Could not edit the Assembly Template.'
            });
            return;
        }
        try {
            this.isSaving = true;
            await putAssemblyTemplate(assemblyTemplateId, editAssemblyTemplateVariables);
            if (this.selectedEntity?.parentId) {
                clearAssemblyTemplateCacheById(this.selectedEntity.parentId);
            }
            const msg = `${this.formData.name ? this.formData.name : '<Shall remain nameless>'} edited.`;
            this.emitEntityEdited(msg).emitRefreshParentEvent().emitRefreshSelfEvent().closeDialog();
        }
        catch (err) {
            this.isSaving = false;
            this.$errorUtility({
                err,
                backupErrorText: 'Could not edit the Assembly Template'
            });
        }
    }
    async fetchAssemblyTemplate(assemblyTemplateId) {
        if (!assemblyTemplateId) {
            return;
        }
        try {
            const resp = await getAssemblyTemplate(assemblyTemplateId);
            this.selectedEntity = resp.data ?? null;
        }
        catch (err) {
            this.$errorUtility({
                err,
                backupErrorText: 'Could not fetch the Assembly Template'
            });
        }
    }
    async fetchAssemblyTemplateParent(parentId) {
        if (!parentId) {
            return;
        }
        try {
            const resp = await getAssemblyTemplate(parentId);
            if (!resp) {
                return;
            }
            this.parentData = resp.data;
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({ err });
            }
            else {
                console.error(`Unexpected error: ${err}`);
            }
        }
    }
    isNotMoreThanMaxQuantity(minQuantity, maxQuantity) {
        return minQuantity <= maxQuantity;
    }
    isSequenceZeroOrMore(quantity) {
        return quantity >= 0;
    }
    async onClickOfAddPartReference() {
        // determine which selected part reference to add. if it is an edited part reference, do a put request.
        // transform the part reference and push it to transformedPartReferences.
        let transformedPartReference;
        if (this.selectedPartReference) {
            transformedPartReference = {
                ...transformPartReferenceResponses([this.selectedPartReference])[0],
                _tempId: crypto.randomUUID()
            };
        }
        if (this.selectedPartReferenceRequest) {
            transformedPartReference = {
                ...transformPartReferenceResponses([this.selectedPartReferenceRequest])[0],
                _tempId: crypto.randomUUID()
            };
        }
        if (this.selectedPartReferenceEdited) {
            const updatedPartReference = await this.updatePartReference(this.selectedPartReferenceEdited);
            if (!updatedPartReference) {
                this.$errorUtility({ err: new Error('Failed to update part reference.') });
                return;
            }
            transformedPartReference = {
                ...transformPartReferenceResponses([updatedPartReference])[0],
                _tempId: crypto.randomUUID()
            };
        }
        if (!transformedPartReference) {
            return;
        }
        this.transformedPartReferences.push(transformedPartReference);
        this.showPartReferenceSelector = false;
    }
    async onClickOfEditAssemblyTemplate() {
        try {
            if (this.isFormValid && this.editAssemblyTemplateVariables) {
                await this.editAssemblyTemplate(this.assemblyTemplateId, this.editAssemblyTemplateVariables);
            }
        }
        catch (err) {
            this.$errorUtility({ err });
        }
    }
    onNewPartReferenceSelected(partReferenceRequest) {
        this.selectedPartReferenceRequest = partReferenceRequest;
    }
    onPartReferenceEdited(partReference) {
        this.selectedPartReferenceEdited = partReference;
    }
    onPartReferenceSelected(partReference) {
        this.selectedPartReference = partReference;
    }
    onRemovalOfPartReference(partReference) {
        this.transformedPartReferences = this.transformedPartReferences.filter((item) => item._tempId !== partReference._tempId);
    }
    populateFormData(entity) {
        const existingValues = {
            attributes: entity.attributes ?? {},
            autoHardwareListId: entity.autoHardwareListId ?? null,
            digitalLibraryRecordId: entity.digitalLibraryRecordId ?? null,
            documentId: entity.documentId ?? null,
            installedOn: entity.installedOn ?? null,
            maxQuantity: entity.maxQuantity ?? 0,
            minQuantity: entity.minQuantity ?? 0,
            name: entity.name ?? null,
            needDate: entity.needDate ?? null,
            parentId: entity.parentId ?? null,
            parts: entity.parts ?? null,
            pbsItemId: entity.pbsItemId ?? null,
            sequence: entity.sequence ?? 0,
            subType: entity.subType ?? null,
            waiverDate: entity.waiverDate ?? null,
            waiverNumber: entity.waiverNumber ?? null
        };
        this.formData = existingValues;
    }
    async updatePartReference(partReference) {
        const request = {
            attributes: partReference.attributes || {},
            asBuiltNumber: partReference.asBuiltNumber || null,
            birthDate: partReference.birthDate || null,
            calibrationNumber: partReference.calibrationNumber || null,
            contractEndingItemNumber: partReference.contractEndingItemNumber || null,
            drawingDescription: partReference.drawingDescription || null,
            drawingNumber: partReference.drawingNumber || null,
            isTool: partReference.isTool ?? false,
            itemClass: partReference.itemClass || null,
            lastCalibrationDate: partReference.lastCalibrationDate || null,
            location: partReference.location || null,
            lotNumber: partReference.lotNumber || null,
            notes: partReference.notes || null,
            serialNumber: partReference.serialNumber || null,
            shelfLifeExpirationDate: partReference.shelfLifeExpirationDate || null,
            side: partReference.side || Side.NONE,
            size: partReference.size || null,
            usageLifeExpirationDate: partReference.usageLifeExpirationDate || null
        };
        try {
            const resp = await putPartReference(partReference.id, request);
            return resp.data;
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({ err });
            }
            else {
                console.error(`Unexpected error: ${err}`);
            }
        }
    }
    emitRefreshParentEvent() {
        return this;
    }
    emitRefreshSelfEvent() {
        return this;
    }
    async onAssemblyTemplateChange(val) {
        if (!val) {
            return;
        }
        await this.fetchAssemblyTemplate(val);
        if (this.selectedEntity?.parentId) {
            this.fetchAssemblyTemplateParent(this.selectedEntity.parentId);
        }
        const resp = await getAutoHardwareLists({
            take: -1
        });
        if (resp.data.results) {
            this.allAutoHardwareLists = resp.data.results || [];
        }
    }
    onSelectedEntityChange(selectedEntity) {
        if (selectedEntity) {
            const val = { ...selectedEntity };
            this.populateFormData(selectedEntity);
            this.transformedPartReferences =
                val.parts?.map((part) => {
                    return {
                        ...transformPartReferenceResponses([part.partReference])[0],
                        _tempId: crypto.randomUUID()
                    };
                }) || [];
        }
    }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], ModalAssemblyTemplateEdit.prototype, "assemblyTemplateId", void 0);
__decorate([
    Emit('refresh-parent'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalAssemblyTemplateEdit.prototype, "emitRefreshParentEvent", null);
__decorate([
    Emit('refresh-self'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalAssemblyTemplateEdit.prototype, "emitRefreshSelfEvent", null);
__decorate([
    Watch('assemblyTemplateId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object]),
    __metadata("design:returntype", Promise)
], ModalAssemblyTemplateEdit.prototype, "onAssemblyTemplateChange", null);
__decorate([
    Watch('selectedEntity'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof AssemblyTemplateResponseWithChildren !== "undefined" && AssemblyTemplateResponseWithChildren) === "function" ? _b : Object]),
    __metadata("design:returntype", void 0)
], ModalAssemblyTemplateEdit.prototype, "onSelectedEntityChange", null);
ModalAssemblyTemplateEdit = __decorate([
    Component
], ModalAssemblyTemplateEdit);
export default ModalAssemblyTemplateEdit;

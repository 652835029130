import { formatDistanceToNow } from 'date-fns';
import { cloneDeep } from 'lodash';
import { ChangeSetStatus, InventoryMethod } from '~/db_types';
import { computeSingleLineDisplayPartString } from '~/nasa_ui/components/SingleLineDisplayPart/SingleLineDisplayPart';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { NOT_APPLICABLE } from '~/nasa_ui/constants/display';
import { EntityType } from '~/nasa_ui/types';
import { PressurizationRangeDisplay } from '~/nasa_ui/types/enums/activity';
import { AuthorityCodeDisplay } from '~/nasa_ui/types/enums/authority-codes';
import { ChangeSetStatusDisplay } from '~/nasa_ui/types/enums/change-set';
import { CosmicDocumentStatusDisplay, DocumentTypesWithoutLineItems } from '~/nasa_ui/types/enums/document';
import { EquipmentGroupDisplay } from '~/nasa_ui/types/enums/equipment-group';
import { InventoryMethodDisplay, InventoryStatusDisplay, InventoryUnitTypeDisplay } from '~/nasa_ui/types/enums/hardware';
import { dateDisplay } from '../dates/dateDisplay';
import { dateTimeDisplay, dateTimeDisplayOptions } from '../dates/dateTimeDisplay';
import { intervalDisplay, limitInterval } from '../dates/intervals';
import { buildChangeSetStatusIcon, buildIconWithLabelAsString, buildNotOkIcon, buildOkIcon } from '../ui/buildIconAsString';
import { CosmicIcons } from '../ui/CosmicIcons';
import { asBuiltNumberDisplay, asDesignNumberDisplay, binDisplay, booleanIconDisplay, buildingDisplay, contactDisplay, currencyDisplay, itemNumberDisplay, locationDisplay, lotNumberDisplay, numberDisplay, quantityDisplay, roomDisplay, serialNumberDisplay, sideDisplay, sizeDisplay, stockDisplay } from './displays';
import { isNullOrUndefined } from './isNullOrUndefined';
import { markdownToHtml } from './markdownToHtml';
/**
 * Adds _attributes prop to the entity for any activityType
 *
 * @param entity
 */
export const addActivityAttributesDisplay = (entity) => {
    const _attributes = [];
    const _attributesForPdf = [];
    const _item = { ...entity };
    _attributes.push('<ul class="list-style-none pl-0 py-1">');
    if (_item.pressurizedTime) {
        _attributes.push(`<li class="pt-1">Press. Time: <b>${intervalDisplay(_item.pressurizedTime)}</b></li>`);
        _attributesForPdf.push(`Press. Time: ${intervalDisplay(_item.pressurizedTime)}`);
    }
    if (_item.pressurizationRange) {
        _attributes.push(`<li class="pt-1">Range: <b>${PressurizationRangeDisplay.get(_item.pressurizationRange)}</b></li>`);
        _attributesForPdf.push(`Range: ${PressurizationRangeDisplay.get(_item.pressurizationRange)}`);
    }
    if (_item.hasOwnProperty('isManned')) {
        _attributes.push(`<li class="pt-1">Manned?: <b>${_item.isManned ? 'Yes' : 'No'}</b></li>`);
        _attributesForPdf.push(`Manned?: ${_item.isManned ? 'Yes' : 'No'}`);
    }
    if (_item.hasOwnProperty('commentText') && _item.commentText) {
        _attributes.push(`<li class="pt-1">
      Comment: <p class="ml-2">${_item.commentText}</p></li>
    `);
        _attributesForPdf.push(`Comment: ${_item.commentText}`);
    }
    if (_item.attributes?.result) {
        const inspectionResult = _item.attributes?.result;
        const isPass = inspectionResult === 'PASS';
        _attributes.push(`<li class="pt-1">Result: ${isPass ? buildOkIcon('Inspection Passed') : buildNotOkIcon('Inspection Failed')}</li>`);
        _attributesForPdf.push(`Result: ${isPass ? 'Passed' : 'Failed'}`);
    }
    if (_item.attributes?.inspectionText) {
        const inspectionText = _item.attributes?.inspectionText;
        _attributes.push(`<li class="pt-1">
      Insp Text: <p class="ml-2">${inspectionText}</p></li>
    `);
        _attributesForPdf.push(`Insp Text: ${inspectionText}`);
    }
    // Do you just have a ul?
    if (_attributes.length === 1) {
        _attributes.push('<li>-</li>');
    }
    _attributes.push('</ul>');
    _item._attributes = _attributes.join(' ');
    _item._attributesForPdf = _attributesForPdf?.length ? _attributesForPdf.join('\n') : DEFAULT_DASH;
    return _item;
};
export const addAddendumNumberDisplay = (entity) => {
    const _item = { ...entity };
    _item._addendumNumber = _item.addendumNumber ?? DEFAULT_DASH;
    return _item;
};
export const addAddendumDateDisplay = (entity) => {
    const _item = { ...entity };
    _item._addendumDate = dateDisplay(_item.addendumDate) ?? DEFAULT_DASH;
    _item._addendumDateTicks = _item.addendumDate ? new Date(_item.addendumDate).getTime() : 0;
    return _item;
};
export const addAsBuiltNumberDisplay = (entity) => {
    const _item = { ...entity };
    if ('asBuiltNumber' in _item) {
        _item._asBuiltNumber = asBuiltNumberDisplay(_item.asBuiltNumber);
        return _item;
    }
    if (_item.itemInstance && 'asBuiltNumber' in _item.itemInstance) {
        _item._asBuiltNumber = asBuiltNumberDisplay(_item.itemInstance.asBuiltNumber);
        return _item;
    }
    if (_item.inventory && 'asBuiltNumber' in _item.inventory) {
        _item._asBuiltNumber = asBuiltNumberDisplay(_item.inventory.asBuiltNumber);
        return _item;
    }
    if (_item.itemInstanceCrossContext && 'asBuiltNumber' in _item.itemInstanceCrossContext) {
        _item._asBuiltNumber = asBuiltNumberDisplay(_item.itemInstanceCrossContext.asBuiltNumber);
        return _item;
    }
    if (_item.itemMaster && 'asBuiltNumber' in _item.itemMaster) {
        _item._asBuiltNumber = asBuiltNumberDisplay(_item.itemMaster.asBuiltNumber);
        return _item;
    }
    if (!_item._asBuiltNumber) {
        _item._asBuiltNumber = DEFAULT_DASH;
    }
    return _item;
};
export const addAsDesignNumberDisplay = (entity) => {
    const _item = { ...entity };
    if ('asDesignNumber' in _item) {
        _item._asDesignNumber = asDesignNumberDisplay(_item.asDesignNumber);
    }
    if (_item.itemInstance && 'asDesignNumber' in _item.itemInstance) {
        _item._asDesignNumber = asDesignNumberDisplay(_item.itemInstance.asDesignNumber);
    }
    return _item;
};
export const addAuthorityCodeDisplay = (entity) => {
    const _item = { ...entity };
    _item._authorityCode = _item.authorityCode ? AuthorityCodeDisplay.get(_item.authorityCode) : DEFAULT_DASH;
    return _item;
};
export const addAuthorityNumberDisplay = (entity) => {
    const _item = { ...entity };
    _item._authorityNumber = _item.authorityNumber || DEFAULT_DASH;
    return _item;
};
export const addBinDisplay = (entity) => {
    const _item = { ...entity };
    if ('bin' in _item) {
        _item._bin = binDisplay(_item.bin);
    }
    else if (_item.location) {
        _item._bin = binDisplay(_item.location.bin);
    }
    return _item;
};
export const addBuildingDisplay = (entity) => {
    const _item = { ...entity };
    if ('building' in _item) {
        _item._building = buildingDisplay(_item.building);
    }
    return _item;
};
export const addBuildingStockBinDisplay = (entity) => {
    const _item = { ...entity };
    if (_item.location?.building) {
        _item._buildingStockBin = locationDisplay(_item.location, false);
    }
    else if (_item.locationCrossContext?.building) {
        _item._buildingStockBin = locationDisplay(_item.locationCrossContext, false);
    }
    else {
        _item._buildingStockBin = DEFAULT_DASH;
    }
    return _item;
};
export const addCrossContextLocationDisplay = (entity) => {
    const _item = { ...entity };
    const building = buildingDisplay(_item.location?.building || _item.locationCrossContext?.building);
    const stock = stockDisplay(_item.location?.stock || _item.locationCrossContext?.stock);
    const bin = binDisplay(_item.location?.bin || _item.locationCrossContext?.bin);
    const supportOrganizationCode = _item.supportOrganizationCode;
    _item._crossContextLocation = `<span class="no-wrap"><b>${supportOrganizationCode}</b>: ${building} / ${stock} / ${bin}</span>`;
    return _item;
};
export const addCommentFromMarkdownDisplay = (entity) => {
    const _item = { ...entity };
    if (_item && 'comment' in _item) {
        _item._comment = markdownToHtml(_item.comment);
    }
    // if there is no comment, add `-` for display
    if (_item._comment === '') {
        _item._comment = DEFAULT_DASH;
    }
    return _item;
};
export const addComputedLastInspectionDate = (entity) => {
    const _item = { ...entity };
    const totalSinceLastService = _item?.limitedLifeRemaining?.pressurizedTime?.totalSinceLastService;
    _item._computedLastInspectionDate = totalSinceLastService ? totalSinceLastService : DEFAULT_DASH;
    return _item;
};
export const addComputedStatusDisplay = (entity) => {
    const computedStatus = entity.computedStatus ||
        entity.cosmicDocumentDr?.computedStatus ||
        entity.cosmicDocumentEzt?.computedStatus ||
        entity.cosmicDocumentTps?.computedStatus ||
        entity.cosmicDocumentWorkflow?.computedStatus ||
        entity.cosmicDocumentFracas?.computedStatus ||
        entity.cosmicDocumentRca?.computedStatus ||
        entity.cosmicDocumentRid?.computedStatus;
    const statusDisplay = computedStatus ? CosmicDocumentStatusDisplay.get(computedStatus) || computedStatus : '';
    const upperStatus = statusDisplay.toUpperCase();
    const statusIconClass = CosmicIcons[`${upperStatus}`] || CosmicIcons['pipeline_null'];
    const newEntity = Object.assign({}, entity, {
        _computedStatus: '',
        _computedStatusIcon: ''
    });
    newEntity._computedStatus = statusDisplay;
    newEntity._computedStatusIcon = `<i class="status-icon ${statusIconClass}" title="${statusDisplay}"></i>`;
    return newEntity;
};
export const addComputedRevisionNumber = (entity) => {
    const _item = { ...entity };
    let _computedRevisionNumber = DEFAULT_DASH;
    if (isNullOrUndefined(_item.computedRevisionNumber)) {
        _computedRevisionNumber = DEFAULT_DASH;
    }
    else if (typeof _item.computedRevisionNumber === 'number') {
        _computedRevisionNumber = numberDisplay(_item.computedRevisionNumber);
    }
    else if (typeof _item.computedRevisionNumber === 'string') {
        _computedRevisionNumber = _item.computedRevisionNumber || DEFAULT_DASH;
    }
    _item._computedRevisionNumber = _computedRevisionNumber;
    return _item;
};
export const addChangeSetStatusCountDisplay = (entity) => {
    const _item = { ...entity };
    const formatLineItemCounts = (lineItem) => {
        const color = lineItem?.status === ChangeSetStatus.APPROVED ? 'success success--text' : 'primary primary--text';
        return `<span class="v-chip v-chip--small v-chip--outline ${color} theme--light ">
              <span class="v-chip__content">${lineItem?.status}: ${lineItem?.count}</span>
            </span>`;
    };
    const lineItemCounts = _item.lineItemCounts || _item.document?.lineItemCounts;
    if (lineItemCounts && lineItemCounts.length > 0) {
        _item._lineItemCounts = lineItemCounts.map(formatLineItemCounts).join('<br/>');
    }
    else {
        const subType = _item.subType || _item.document?.subType;
        _item._lineItemCounts = subType in DocumentTypesWithoutLineItems ? NOT_APPLICABLE : DEFAULT_DASH;
    }
    return _item;
};
export const addContactDisplays = (entity) => {
    const _item = { ...entity };
    const topLevelContactKey = Object.keys(_item).filter((key) => !key.startsWith('_') && key.endsWith('Contact'));
    topLevelContactKey.forEach((key) => {
        const val = _item[key];
        _item[`_${key}`] = contactDisplay(val);
        _item[`_${key}MailToLink`] =
            (val?.email?.length || 0) > 0
                ? `<a class="user-email monospace_font" href="mailto:${val.email[0]}">
          ${contactDisplay(val)}
        </a>`
                : DEFAULT_DASH;
    });
    return _item;
};
export const addCrewTimeDisplay = (entity) => {
    const _item = { ...entity };
    _item._crewTime = intervalDisplay(_item.crewTime);
    return _item;
};
export const addCyclesDisplay = (entity) => {
    const _item = { ...entity };
    _item._cycles = _item.cycles || DEFAULT_DASH;
    return _item;
};
export const addDateDisplays = (entity) => {
    const _item = { ...entity };
    const addDisplayAttributes = (entity, val, key) => {
        if (key.endsWith('DateTime')) {
            entity[`_${key.replace('Time', '')}`] = dateDisplay(val);
            entity[`_${key}`] = dateTimeDisplay(val);
            entity[`_${key}WithBreak`] = dateTimeDisplayOptions({
                breakDateTimeApart: true,
                date: val,
                displayValue: DEFAULT_DASH,
                isUtc: true
            });
        }
        else {
            entity[`_${key}`] = dateDisplay(val);
            entity[`_${key}Time`] = dateTimeDisplay(val);
            entity[`_${key}TimeWithBreak`] = dateTimeDisplayOptions({
                breakDateTimeApart: true,
                date: val,
                displayValue: DEFAULT_DASH,
                isUtc: true
            });
        }
        entity[`_${key}DistanceInWordsFromToday`] = formatDistanceToNow(new Date(val), {
            addSuffix: true,
            includeSeconds: true
        });
        entity[`_${key}Ticks`] = val ? new Date(val).getTime() : 0;
    };
    const topLevelDateKey = Object.keys(_item)
        .filter((key) => !key.startsWith('_'))
        .filter((key) => key.endsWith('Date') || key.endsWith('DateTime'));
    topLevelDateKey.forEach((key) => {
        const val = _item[key];
        addDisplayAttributes(_item, val, key);
    });
    if (_item.status) {
        const statusInfo = _item.status;
        const statusDateKeys = Object.keys(statusInfo)
            .filter((key) => !key.startsWith('_'))
            .filter((key) => key.endsWith('Date') || key.endsWith('DateTime'));
        statusDateKeys.forEach((key) => {
            const val = statusInfo[key];
            // maps to entity.status
            addDisplayAttributes(_item, val, key);
        });
    }
    return _item;
};
export const addDescriptionDisplay = (entity) => {
    const _item = { ...entity };
    _item._description =
        _item?.description ||
            _item?.externalDescription ||
            _item?.itemDrawing?.description ||
            _item?.itemDrawingDescription ||
            _item?.drawingDescription ||
            _item?.itemInstance?.itemDrawingDescription ||
            _item?.itemMaster?.itemDrawingDescription ||
            _item?.inventory?.itemDrawingDescription ||
            _item?.itemInstanceCrossContext?.description ||
            DEFAULT_DASH;
    return _item;
};
export const addDrawingNumberDisplay = (entity) => {
    const _item = { ...entity };
    if (_item.inventory && 'drawingNumber' in _item.inventory) {
        _item._drawingNumber = _item.inventory.drawingNumber;
    }
    else if (_item.itemInstance && 'drawingNumber' in _item.itemInstance) {
        _item._drawingNumber = _item.itemInstance.drawingNumber;
    }
    else if (_item.itemMaster && 'drawingNumber' in _item.itemMaster) {
        _item._drawingNumber = _item.itemMaster.drawingNumber;
    }
    else if (_item.itemDrawing && 'drawingNumber' in _item.itemDrawing) {
        _item._drawingNumber = _item.itemDrawing.drawingNumber;
    }
    else {
        _item._drawingNumber = _item.drawingNumber || DEFAULT_DASH;
    }
    return _item;
};
export const addEquipmentGroupDisplay = (entity) => {
    const _item = { ...entity };
    _item._itemMasterType =
        _item.itemMaster?.subType || _item.itemMasterSubType
            ? EquipmentGroupDisplay.get(_item.itemMaster?.subType || _item.itemMasterSubType) || DEFAULT_DASH
            : DEFAULT_DASH;
    return _item;
};
export const addFilterOutSelected = (node) => {
    return !(node && node.__SELECTED);
};
export const addFullName = (entity) => {
    const _item = { ...entity };
    if (_item.firstName && _item.lastName) {
        _item._fullName = `${_item.firstName} ${_item.lastName}`;
        return _item;
    }
    if (_item.firstName && !_item.lastName) {
        _item._fullName = _item.firstName;
        return _item;
    }
    if (!_item.firstName && _item.lastName) {
        _item._fullName = _item.lastName;
        return _item;
    }
    // moved this to the fourth condition because I want to use cosmic's name if
    // there happens to be one (eg, for testing purposes)
    if (_item.auid?.toUpperCase() === 'System') {
        _item._fullName = 'System';
        return _item;
    }
    if (_item.auid) {
        _item._fullName = _item.auid;
        return _item;
    }
    if (_item.email && _item.email.length) {
        _item._fullName = _item.email[0];
        return _item;
    }
    _item._fullName = DEFAULT_DASH;
    return _item;
};
export const addHardwareItemAssemblyTemplateDisplay = (entity) => {
    const _item = { ...entity };
    _item._assemblyTemplateId = _item?.assemblyTemplateId;
    _item._assemblyTemplate = _item?.assemblyTemplate;
    return _item;
};
export const addHardwareListNumberDisplay = (entity) => {
    const _item = { ...entity };
    _item._hardwareListNumber = _item.hardwareList?.name || _item.hardwareList?.number || DEFAULT_DASH;
    return _item;
};
export const addItemNumberDisplay = (entity) => {
    const _item = { ...entity };
    if (!_item) {
        _item._itemNumber = DEFAULT_DASH;
        return _item;
    }
    if (itemNumberDisplay(_item) !== DEFAULT_DASH) {
        _item._itemNumber = itemNumberDisplay(_item);
        return _item;
    }
    if (_item.itemInstanceCrossContext?.drawingNumber) {
        _item._itemNumber = itemNumberDisplay(_item.itemInstanceCrossContext);
        return _item;
    }
    // For Ford!
    _item._itemNumber = _item.inventory
        ? itemNumberDisplay(_item.inventory)
        : _item.itemInstance
            ? itemNumberDisplay(_item.itemInstance)
            : _item.itemMaster
                ? itemNumberDisplay(_item.itemMaster)
                : _item.itemDrawing
                    ? itemNumberDisplay(_item.itemDrawing)
                    : DEFAULT_DASH;
    return _item;
};
export const addInitializationStatusDisplay = (entity) => {
    const _item = { ...entity };
    const slug = 'pipeline_';
    let initializionStatus = 'Unknown';
    // Item drawings have a rollup called 'initializationStatuses'
    if (_item.initializationStatuses) {
        // Computed distinct initialization statuses of all item masters for this item drawing.
        // Empty means there are no masters,
        // null means at least one item master doesn't have an initialization change set yet.
        if (_item.initializationStatuses.every((status) => status === ChangeSetStatus.APPROVED)) {
            initializionStatus = ChangeSetStatus.APPROVED;
        }
        if (_item.initializationStatuses.some((status) => status === ChangeSetStatus.OPEN)) {
            initializionStatus = ChangeSetStatus.OPEN;
        }
        if (_item.initializationStatuses.some((status) => status === ChangeSetStatus.REJECTED)) {
            initializionStatus = ChangeSetStatus.REJECTED;
        }
    }
    else {
        // Legacy
        initializionStatus =
            _item.initializationStatus ||
                _item.itemDrawing?.initializationStatus ||
                _item.itemMaster?.initializationStatus ||
                _item.itemInstance?.initializationStatus ||
                _item.cosmicDocumentDr?.initializationStatus ||
                _item.cosmicDocumentEzt?.initializationStatus ||
                _item.cosmicDocumentTps?.initializationStatus ||
                _item.cosmicDocumentWorkflow?.initializationStatus ||
                _item.cosmicDocumentFracas?.initializationStatus ||
                _item.cosmicDocumentRca?.initializationStatus ||
                _item.cosmicDocumentRid?.initializationStatus;
    }
    const statusDisplay = initializionStatus !== 'Unknown'
        ? ChangeSetStatusDisplay.get(initializionStatus)
        : initializionStatus;
    const loweredStatus = statusDisplay && statusDisplay.toLowerCase();
    const statusIconClass = CosmicIcons[`${slug}${loweredStatus}`] || CosmicIcons['pipeline_null'];
    _item._initializationStatusIcon = `<i class="status-icon ${loweredStatus} ${statusIconClass}" title="${statusDisplay}"></i>`;
    _item._initializationStatus = statusDisplay;
    _item._initializationStatusIconOnly = statusIconClass;
    _item._initializationStatusIconColor =
        loweredStatus === 'approved'
            ? 'var(--v-success-base)'
            : loweredStatus === 'rejected'
                ? 'var(--v-error-base)'
                : 'var(--cosmic-text)';
    _item._initializationStatusIconTitle = statusDisplay;
    return _item;
};
export const addInventoryPlusMethodDisplay = (entity) => {
    const _item = { ...entity };
    // Qty + methodTypeDisplay
    const isMethodUnit = _item.itemMaster?.inventoryMethod === InventoryMethod.UNIT;
    const quantity = quantityDisplay(_item.quantity || _item.inventoryQuantity || 0);
    const methodDisplay = _item.itemMaster?.inventoryMethod
        ? InventoryMethodDisplay.get(_item.itemMaster?.inventoryMethod)
        : '';
    const unitTypeDisplay = isMethodUnit && _item.itemMaster?.inventoryUnitType
        ? InventoryUnitTypeDisplay.get(_item.itemMaster?.inventoryUnitType)
        : '';
    _item._inventoryQuantity = `${quantity} ${isMethodUnit ? unitTypeDisplay : methodDisplay}`;
    return _item;
};
export const addInventoryQuantityDisplay = (entity) => {
    const _item = { ...entity };
    const quantity = _item.inventoryQuantity || _item.quantity || _item.itemInstance?.inventoryQuantity;
    _item._inventoryQuantity = quantity ? quantity.toLocaleString() : '0';
    return _item;
};
export const addInventoryStatusDisplay = (entity) => {
    const _item = { ...entity };
    if ('status' in _item) {
        _item._status = _item.status
            ? `<span class="no-wrap">${InventoryStatusDisplay.get(_item.status)}</span>`
            : DEFAULT_DASH;
    }
    return _item;
};
export const addIsManagedLocationDisplay = (entity) => {
    const _clone = cloneDeep(entity);
    const hasContract = (_clone.contract !== null && _clone.contract !== undefined) ||
        (_clone.contractNumber !== null && _clone.contractNumber !== undefined);
    const icon = CosmicIcons[`pipeline_${hasContract ? 'approved' : 'rejected'}`];
    const color = hasContract ? 'success--text' : 'error--text';
    _clone._isManagedLocationDisplay = `
    <div class="d-flex align-center justify-center font-weight-bold" style="flex: 0 0 auto !important">
      <i class="${color} ${icon} mr-2 ${_clone.contractNumber ? 'text-xs-right' : ''}"></i>
      <span class="font-weight-bold text-xs-left ${_clone.contractNumber ? '' : 'hide'}">${_clone.contractNumber}</span>
    </div>
  `;
    return _clone;
};
export const addIsToolIcon = (entity) => {
    const _item = { ...entity };
    const isTool = _item.isTool;
    const isToolItemMasterLevel = _item.itemMaster?.isTool;
    const isToolExternal = _item.externalIsTool;
    const combinedIsTool = [isTool, isToolItemMasterLevel, isToolExternal];
    const isAllUndefinedOrNull = combinedIsTool.every(isNullOrUndefined);
    if (isAllUndefinedOrNull) {
        _item._isToolIcon = DEFAULT_DASH;
        return _item;
    }
    const anyTrueIsTool = combinedIsTool.some((isTool) => isTool === true);
    _item._isToolIcon = booleanIconDisplay(anyTrueIsTool, 'Whether or not this Hardware is designated a tool.');
    return _item;
};
export const addFailureModeDisplay = (entity) => {
    const _item = { ...entity };
    if (_item && _item.payload && 'failureMode' in _item.payload) {
        _item._failureMode = _item?.payload?.failureMode || DEFAULT_DASH;
    }
    return _item;
};
export const addLastServiceDate = (entity) => {
    const _item = { ...entity };
    const lastServiceDate = _item?.computedLastServiceDate || _item?.limitedLifeRemaining?.pressurizedTime?.lastServiceDate;
    _item._lastServiceDate = lastServiceDate ? dateDisplay(lastServiceDate) : DEFAULT_DASH;
    return _item;
};
export const addLocationAddressDisplay = (entity) => {
    const _clone = cloneDeep(entity);
    _clone._address = entity.address
        ? `
      <div class="my-1">
        <span>${entity.address || ''}</span><br/>
        <span>${entity.city || ''}${entity.city && entity.state ? ',' : ''} ${entity.state || ''}</span><br/>
        <span>${entity.zip || ''}</span>
      </div>
    `
        : DEFAULT_DASH;
    _clone._contract = entity.contractNumber || DEFAULT_DASH;
    return _clone;
};
export const addLocationDisplay = (entity) => {
    const _item = { ...entity };
    if (_item.building) {
        _item._location = locationDisplay(_item);
    }
    else if (_item.location?.building) {
        _item._location = locationDisplay(_item.location);
    }
    else if (_item.inventory?.location) {
        _item._location = locationDisplay(_item.inventory.location);
    }
    else if (_item.locationCrossContext?.building) {
        _item._location = locationDisplay(_item.locationCrossContext);
    }
    else if (_item.externalLocation) {
        _item._location = _item.externalLocation;
    }
    else {
        _item._location = DEFAULT_DASH;
    }
    return _item;
};
export const addLotNumberDisplay = (entity) => {
    const _item = { ...entity };
    if ('lotNumber' in _item) {
        _item._lotNumber = lotNumberDisplay(_item.lotNumber);
        return _item;
    }
    if (_item.itemInstance && 'lotNumber' in _item.itemInstance) {
        _item._lotNumber = lotNumberDisplay(_item.itemInstance.lotNumber);
        return _item;
    }
    if (_item.inventory && 'lotNumber' in _item.inventory) {
        _item._lotNumber = lotNumberDisplay(_item.inventory.lotNumber);
        return _item;
    }
    if (_item.itemInstanceCrossContext && 'lotNumber' in _item.itemInstanceCrossContext) {
        _item._lotNumber = lotNumberDisplay(_item.itemInstanceCrossContext.lotNumber);
        return _item;
    }
    if (_item?.inventory?.lotNumber) {
        _item._lotNumber = lotNumberDisplay(_item.inventory.lotNumber);
    }
    if (_item?.externalLotNumber) {
        _item._lotNumber = lotNumberDisplay(_item.externalLotNumber);
    }
    if (!_item._lotNumber) {
        _item._lotNumber = DEFAULT_DASH;
    }
    return _item;
};
export const addMannedPressurizedTime = (entity) => {
    const _item = { ...entity };
    const computedMannedPressurizedTime = _item?.computedMannedPressurizedTime;
    _item._mannedPressurizedTime = computedMannedPressurizedTime
        ? intervalDisplay(limitInterval(computedMannedPressurizedTime, 'hours'))
        : DEFAULT_DASH;
    return _item;
};
export const addMaxNodeDepthDisplay = (entity) => {
    const _item = { ...entity };
    const _allNodeDepths = _item?.children?.nodes.map((node) => node?.nodeDepth ?? 0);
    const _maxNodeDepth = _allNodeDepths?.length ? Math.max(..._allNodeDepths) : 0;
    _item._maxNodeDepth = numberDisplay(_maxNodeDepth);
    return _item;
};
export const addNameDisplay = (entity) => {
    const _item = { ...entity };
    const _name = _item?.name || _item?._assemblyTemplate?.name;
    _item._name = _name ? _name : DEFAULT_DASH;
    return _item;
};
export const addNotesDisplay = (entity) => {
    const _item = { ...entity };
    _item._notes = _item.notes && _item.notes.length ? _item.notes.join(', ') : DEFAULT_DASH;
    return _item;
};
export const addOperationTimeDisplay = (entity) => {
    const _item = { ...entity };
    _item._operationTime = intervalDisplay(_item.operationTime) || DEFAULT_DASH;
    return _item;
};
export const addOrganizationDisplay = (entity) => {
    const _clone = cloneDeep(entity);
    const hasOrg = _clone.organization || _clone.organizationCode;
    if (!hasOrg) {
        _clone._organizationDisplay = DEFAULT_DASH;
    }
    else {
        _clone._organizationDisplay = _clone.organization
            ? `${_clone.organization.name} / ${_clone.organization.code}`
            : _clone.organizationCode;
    }
    return _clone;
};
export const addOverrideSoftwareVersionDisplay = (entity) => {
    const _item = { ...entity };
    _item._overrideSoftwareVersionDisplay = _item?.overrideSoftwareVersion || DEFAULT_DASH;
    return _item;
};
export const addPartReferenceSortDisplay = (entity) => {
    const _item = { ...entity };
    _item._partReference = entity.partReference ? computeSingleLineDisplayPartString(entity.partReference) : '-';
    return _item;
};
export const addPbsItemIdDisplay = (entity) => {
    const _item = { ...entity };
    const pbsItemId = _item?.pbsItemId ?? DEFAULT_DASH;
    _item._pbsItemId = pbsItemId;
    return _item;
};
export const addPressurizedTimeDisplay = (entity) => {
    const _item = { ...entity };
    const isManned = _item.isManned;
    if (isManned !== undefined && isManned !== null) {
        const display = intervalDisplay(limitInterval(_item.pressurizedTime, 'hours')) || DEFAULT_DASH;
        _item._pressurizedTime = isManned
            ? buildIconWithLabelAsString({
                iconClass: CosmicIcons['USER'],
                label: display,
                title: `${display} on Manned Pressurized Time`,
                color: 'success--text',
                labelAlignment: 'right',
                labelCssClass: 'text-1x'
            })
            : display;
    }
    else {
        _item._pressurizedTime = intervalDisplay(_item.pressurizedTime);
    }
    return _item;
};
export const addPressurizationRangeDisplay = (entity) => {
    const _item = { ...entity };
    _item._pressurizationRange = _item.pressurizationRange
        ? PressurizationRangeDisplay.get(_item.pressurizationRange)
        : DEFAULT_DASH;
    return _item;
};
export const addPreventativeMaintenanceDisplay = (entity) => {
    const _item = { ...entity };
    _item._preventativeMaintenance = booleanIconDisplay(_item.isPreventativeMaintenance);
    return _item;
};
export const addProjectCodeDisplay = (entity) => {
    const _item = { ...entity };
    const projectCode = _item?.projectCode;
    _item._projectCode = projectCode ? _item.projectCode : DEFAULT_DASH;
    return _item;
};
// changed to operate on a singular key "projectCode" rather than "projectCodes"
// because that's how the results of the II search come back. this transform is
// only being used on II search results at the moment.
export const addProjectCodesDisplay = (entity) => {
    const _item = { ...entity };
    if (_item && 'projectCode' in _item) {
        if (_item.projectCode?.length === 1) {
            _item._projectCodes = `${_item.projectCode[0]}`;
        }
        else if (_item.projectCode?.length) {
            const projectCodes = _item.projectCode?.map((code) => `<li>${code}</li>`).join('');
            _item._projectCodes = `<ul class="projectCodes">${projectCodes}</ul>`;
        }
        else {
            _item._projectCodes = DEFAULT_DASH;
        }
    }
    else {
        _item._projectCodes = DEFAULT_DASH;
    }
    return _item;
};
export const addQuantitiesDisplay = (entity) => {
    const _item = { ...entity };
    // _quantities
    const availableQuantity = quantityDisplay(_item.availableQuantity ||
        _item.itemDrawing?.availableQuantity ||
        _item.itemMaster?.availableQuantity ||
        _item.itemInstance?.availableQuantity ||
        0);
    const installedQuantity = quantityDisplay(_item.installedQuantity ||
        _item.itemDrawing?.installedQuantity ||
        _item.itemMaster?.installedQuantity ||
        _item.itemInstance?.installedQuantity ||
        0);
    const inventoryQuantity = quantityDisplay(_item.inventoryQuantity ||
        _item.itemDrawing?.inventoryQuantity ||
        _item.itemMaster?.inventoryQuantity ||
        _item.itemInstance?.inventoryQuantity ||
        0);
    const _quantities = `${availableQuantity} / ${installedQuantity} / ${inventoryQuantity}`;
    _item._quantities = _quantities;
    return _item;
};
export const addQuantityDisplay = (entity) => {
    const _item = { ...entity };
    _item._quantity = isNullOrUndefined(_item.quantity) ? DEFAULT_DASH : _item.quantity.toString();
    return _item;
};
export const addRoomDisplay = (entity) => {
    const _item = { ...entity };
    _item._room = roomDisplay(_item.room);
    return _item;
};
export const addServicePressurizedTime = (entity) => {
    const _item = { ...entity };
    const service = _item?.limitedLifeRemaining?.pressurizedTime?.service;
    _item._servicePressurizedTimeRemaining = service ? intervalDisplay(limitInterval(service, 'hours')) : DEFAULT_DASH;
    return _item;
};
export const addMannedPressurizedTimeSinceLastService = (entity) => {
    const _item = { ...entity };
    const mpt = _item?.computedMannedPressurizedTimeSinceLastServ;
    _item._mannedPressurizedTimeSinceLastService = mpt
        ? intervalDisplay(limitInterval(mpt, 'hours')) || DEFAULT_DASH
        : DEFAULT_DASH;
    return _item;
};
// todo: this function is looking at a date then treating it like an interval. not even sure we need it. -bw
export const addSinceLastInspection = (entity) => {
    const _item = { ...entity };
    const sinceLastInspection = _item?._computedLastInspectionDate;
    if (sinceLastInspection === DEFAULT_DASH) {
        _item._sinceLastInspection = sinceLastInspection;
        return _item;
    }
    if (sinceLastInspection && sinceLastInspection !== DEFAULT_DASH) {
        _item._sinceLastInspection = intervalDisplay(sinceLastInspection);
        return _item;
    }
    return _item;
};
export const addSoftwareVersionDisplay = (entity) => {
    const _item = { ...entity };
    _item._softwareVersionDisplay = _item?.softwareVersion || DEFAULT_DASH;
    return _item;
};
export const addSerialNumberDisplay = (entity) => {
    const _item = { ...entity };
    if ('serialNumber' in _item) {
        _item._serialNumber = serialNumberDisplay(_item.serialNumber);
        return _item;
    }
    if (_item.itemInstance && 'serialNumber' in _item.itemInstance) {
        _item._serialNumber = serialNumberDisplay(_item.itemInstance.serialNumber);
        return _item;
    }
    if (_item.inventory && 'serialNumber' in _item.inventory) {
        _item._serialNumber = serialNumberDisplay(_item.inventory.serialNumber);
        return _item;
    }
    if (_item.itemInstanceCrossContext && 'serialNumber' in _item.itemInstanceCrossContext) {
        _item._serialNumber = serialNumberDisplay(_item.itemInstanceCrossContext.serialNumber);
        return _item;
    }
    if (_item?.inventory?.serialNumber) {
        _item._serialNumber = serialNumberDisplay(_item.inventory.serialNumber);
    }
    if (_item?.externalSerialNumber) {
        _item._serialNumber = serialNumberDisplay(_item.externalSerialNumber);
    }
    if (!_item._serialNumber) {
        _item._serialNumber = DEFAULT_DASH;
    }
    return _item;
};
export const addSequenceNumberDisplay = (entity) => {
    const _item = { ...entity };
    _item._sequence = _item.sequence ? _item.sequence.toString() : DEFAULT_DASH;
    return _item;
};
export const addSideDisplay = (entity) => {
    const _item = { ...entity };
    if ('side' in _item) {
        _item._side = sideDisplay(_item.side);
        return _item;
    }
    else if (_item.itemMaster && 'side' in _item.itemMaster) {
        _item._side = sideDisplay(_item.itemMaster.side);
        return _item;
    }
    else if (_item.itemInstance && 'side' in _item.itemInstance) {
        _item._side = sideDisplay(_item.itemInstance.side);
        return _item;
    }
    else {
        _item._side = DEFAULT_DASH;
    }
    return _item;
};
export const addSizeDisplay = (entity) => {
    const _item = { ...entity };
    if ('size' in _item) {
        _item._size = sizeDisplay(_item.size);
        return _item;
    }
    else if (_item.itemInstance && 'size' in _item.itemInstance) {
        _item._size = sizeDisplay(_item.itemInstance.size);
        return _item;
    }
    else if (_item.inventory && 'size' in _item.inventory) {
        _item._size = sizeDisplay(_item.inventory.size);
        return _item;
    }
    if (_item.itemInstanceCrossContext && 'size' in _item.itemInstanceCrossContext) {
        _item._size = sizeDisplay(_item.itemInstanceCrossContext.size);
        return _item;
    }
    if (!_item._size) {
        _item._size = DEFAULT_DASH;
        return _item;
    }
    return _item;
};
export const addStaticTimeDisplay = (entity) => {
    const _item = { ...entity };
    _item._staticTime = intervalDisplay(_item.staticTime) || DEFAULT_DASH;
    return _item;
};
export const addStatusIconDisplay = (entity) => {
    const _item = { ...entity };
    if ('status' in _item) {
        _item._status = buildChangeSetStatusIcon(_item.status?.status || _item.status);
        _item._statusIcon = buildChangeSetStatusIcon(_item.status?.status || _item.status);
    }
    return _item;
};
export const addStockDisplay = (entity) => {
    const _item = { ...entity };
    if ('stock' in _item) {
        _item._stock = stockDisplay(_item.stock);
    }
    return _item;
};
export const addSubTypeDisplay = (displayMap) => {
    return (entity) => {
        const _item = { ...entity };
        if (_item && 'subType' in _item) {
            if (displayMap.has(_item.subType)) {
                _item._subType = displayMap.get(_item.subType);
            }
            else {
                _item._subType = DEFAULT_DASH;
            }
        }
        else if (_item.inventory && 'subType' in _item.inventory) {
            if (displayMap.has(_item.inventory.subType)) {
                _item._subType = displayMap.get(_item.inventory.subType);
            }
            else {
                _item._subType = DEFAULT_DASH;
            }
        }
        else if (_item.externalClass) {
            if (displayMap.has(_item.externalClass)) {
                _item._subType = displayMap.get(_item.externalClass);
            }
            else {
                _item._subType = _item.externalClass;
            }
        }
        else {
            _item._subType = DEFAULT_DASH;
        }
        return _item;
    };
};
export const addTagChips = (entity) => {
    const _item = { ...entity };
    if (_item.tags) {
        _item._tagsAsChips = _item.tags.map((tag) => {
            return {
                text: tag,
                value: tag,
                close: false,
                color: EntityType.COMMENT
            };
        });
    }
    else {
        _item._tagsAsChips = '';
    }
    return _item;
};
export const addTitleDisplay = (entity) => {
    const _item = { ...entity };
    _item._title = _item.title ?? DEFAULT_DASH;
    return _item;
};
export const addUnitCost = (entity) => {
    const _item = { ...entity };
    if ('unitCost' in _item) {
        _item._unitCost = currencyDisplay(_item.unitCost);
    }
    else if ('inventory' in _item) {
        _item._unitCost = currencyDisplay(_item.inventory?.unitCost);
    }
    else if ('inventories' in _item) {
        const inventory = _item.inventories.nodes[0];
        _item._unitCost = currencyDisplay(inventory?.unitCost);
    }
    return _item;
};
export const addUserDisplays = (entity) => {
    const _item = { ...entity };
    const addDisplayAttributes = (entity, val, key) => {
        entity[`_${key}`] = contactDisplay(val);
        entity[`_${key}MailToLink`] =
            (val?.email?.length || 0) > 0
                ? `<a class="user-email" href="mailto:${val.email[0]}">
          ${contactDisplay(val)}
        </a>`
                : DEFAULT_DASH;
    };
    const topLevelUserKey = Object.keys(_item).filter((key) => !key.startsWith('_') && key.endsWith('User'));
    topLevelUserKey.forEach((key) => {
        const val = _item[key];
        addDisplayAttributes(_item, val, key);
    });
    if (_item.status) {
        const statusInfo = _item.status;
        const statusUserKeys = Object.keys(statusInfo).filter((key) => !key.startsWith('_') && key.endsWith('User'));
        statusUserKeys.forEach((key) => {
            const val = statusInfo[key];
            addDisplayAttributes(_item, val, key);
        });
    }
    return _item;
};
export const dateTimeWithBreak = (date) => {
    if (!date) {
        return DEFAULT_DASH;
    }
    return dateTimeDisplayOptions({
        date,
        breakDateTimeApart: true,
        displayValue: '-',
        isUtc: true
    });
};
export const distanceInWordsFromToday = (date) => {
    if (!date) {
        return DEFAULT_DASH;
    }
    return formatDistanceToNow(new Date(date), {
        addSuffix: true,
        includeSeconds: true
    });
};
export const getDateTicks = (date) => {
    return date ? new Date(date).getTime() : 0;
};
export const moveExternalPropertiesToHardwareListAssembly = (entity) => {
    const _item = { ...entity };
    if (_item.isExternal) {
        _item.asBuiltNumber = _item.externalAsBuiltNumber;
        _item.drawingNumber = _item.externalDrawingNumber;
        _item.serialNumber = _item.externalSerialNumber;
        _item.lotNumber = _item.externalLotNumber;
        _item.description = _item.externalDescription;
        _item.shelfLifeExpirationDate =
            _item.inventory?.itemInstance?.limitedLifeRemaining?.shelf?.valueDueDate || _item.externalShelfLifeDate;
        // Not sure why this is here...
        _item.shelfLifeExp =
            _item.inventory?.itemInstance?.limitedLifeRemaining?.shelf?.valueDueDate || _item.externalShelfLifeDate;
        _item.usageLifeExpirationDate =
            _item.inventory?.itemInstance?.limitedLifeRemaining?.usage?.valueDueDate || _item.externalUsageLifeDate;
    }
    _item._isExternal = booleanIconDisplay(_item.isExternal);
    _item._shelfLifeExpirationDate = dateDisplay(_item.shelfLifeExpirationDate);
    _item._usageLifeExpirationDate = dateDisplay(_item.usageLifeExpirationDate);
    _item.itemNumber = itemNumberDisplay(_item);
    return _item;
};
